import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './Monitoring.module.scss';
import { Breadcrumb, Form, PageHeader, Select, Table, DatePicker } from 'antd';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

import columns from './columns';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';

const Journal = () => {
    const { journalStore, configStore } = useStores();
    const { lang } = configStore;
    const content = getLanguageContent(lang);

    useEffect(() => {
        journalStore.fetchList().catch(err => console.error(err.message));
    }, []);

    const handleChange = (type: number) => {
        journalStore.setType(type);
        journalStore.fetchList().catch(err => console.error(err.message));
    };

    const handleBeginDateChange = (date: any) => {
        journalStore.setBeginDate(date);
        journalStore.fetchList().catch(err => console.error(err.message));
    };

    const handleEndDateChange = (date: any) => {
        journalStore.setEndDate(date);
        journalStore.fetchList().catch(err => console.error(err.message));
    };

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.journal.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <div className={styles.tableWrapper}>
                <Form layout="inline" className="components-table-demo-control-bar">
                    <Select
                        className={styles.antSelect}
                        placeholder={content.journal.level}
                        value={journalStore.type}
                        onChange={handleChange}
                    >
                        <Select.Option value={1}>Тип 1</Select.Option>
                        <Select.Option value={2}>Тип 2</Select.Option>
                    </Select>

                    <div className={styles.space} />

                    <Form.Item style={{ margin: 0 }}>
                        <div className={styles.datePicker}>
                            <DatePicker
                                value={journalStore.beginDate}
                                placeholder={content.appManagement.inputs.startDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleBeginDateChange}
                            />
                            <DatePicker
                                value={journalStore.endDate}
                                placeholder={content.appManagement.inputs.endDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleEndDateChange}
                            />
                        </div>
                    </Form.Item>
                </Form>

                <Table
                    columns={columns(
                        configStore.lang,
                        content.journal.table.header,
                        journalStore.type,
                    )}
                    dataSource={journalStore.list}
                    pagination={{ showSizeChanger: false, pageSize: journalStore.pageSize }}
                    size={'small'}
                    rowClassName={styles.doubleRow}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
};

export default observer(Journal);
