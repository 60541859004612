import React from 'react';
import './App.css';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import '../../styles/common.scss';
import Login from '../AuthFormContainer/components/Login/Login';
import { useStores } from '../../store';
import { observer } from 'mobx-react';
import Registration from '../AuthFormContainer/components/Registration/Registration';
import PasswordReset from '../AuthFormContainer/components/PasswordReset/PasswordReset';
import Notification from '../../components/Notification';
import 'antd/dist/antd.css';
import MainPage from '../../pages/MainPage/MainPage';

function App() {
    const { configStore, authStore, notificationStore } = useStores();
    const { appConfig, configLoaded } = configStore;
    const apiToken = sessionStorage.getItem('access_token');

    return (
        <BrowserRouter basename={appConfig.baseUrl}>
            <Spin spinning={!configLoaded}>
                <Switch>
                    {(apiToken || authStore.username) && (
                        <Route path={'/'}>
                            <MainPage />
                        </Route>
                    )}
                    <Route path={'/login'}>
                        <AuthFormContainer>
                            <Login />
                        </AuthFormContainer>
                    </Route>
                    <Route path={'/registration'}>
                        <AuthFormContainer>
                            <Registration />
                        </AuthFormContainer>
                    </Route>
                    <Route path={'/password/reset/:uuid'}>
                        <PasswordReset />
                    </Route>
                    <Route path={'/password/reset'}>
                        <PasswordReset />
                    </Route>

                    {!apiToken ? <Redirect to="/login" /> : <Redirect to="/" />}
                </Switch>
            </Spin>
            <Notification notificationStore={notificationStore} />
        </BrowserRouter>
    );
}

export default observer(App);
