import { Menu } from 'antd';
import { ReactNode } from 'react';

import AppSubmenuItems from '../AppSubmenuItems/AppSubmenuItems';
import styles from './AppSubmenu.module.scss';
import cs from '../../utils/composeStyles';

type AppSubmenuType = (
    data: sidebarMenuItemType,
    settingsIsCollapsed: boolean,
    arrayOfCallbacks: Array<() => void>,
) => ReactNode;

const AppSubmenu: AppSubmenuType = (data, settingsIsCollapsed, arrayOfCallbacks) => {
    const { title, icon, submenuItems, id } = data;

    const rotateArrowStyle = settingsIsCollapsed ? '' : styles.arrowWrapper_rotate;

    return (
        <Menu.Item
            key={id}
            title={null}
            className="sidebarSubMenu"
            style={{ overflow: 'visible', height: 'auto', display: 'block', paddingTop: 3 }}
            icon={<img className="sidebarSubMenu__icon" src={icon} alt="Not Found" />}
        >
            <span className="sidebarSubMenu__menuItemTitle">{title}</span>

            <div className={cs(styles.arrowWrapper, rotateArrowStyle)}>
                <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.5 3.75L6.47884 6.75L2.5 3.75"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <AppSubmenuItems
                isCollapsed={settingsIsCollapsed}
                mainTitle={title}
                items={submenuItems}
                arrayOfCallbacks={arrayOfCallbacks}
            />
        </Menu.Item>
    );
};

export default AppSubmenu;
