import { makeAutoObservable } from 'mobx';

type sidebarCollapsibleObjectType = {
    sidebarIsCollapsible: boolean;
    GET_SIDEBAR_IS_COLLAPSED: boolean;
    SET_TOGGLE_SIDEBAR_COLLAPSED: () => void;
    SET_ACTIVE_SIDEBAR_COLLAPSED: () => void;
    SET_INACTIVE_SIDEBAR_COLLAPSED: () => void;
};

const sidebarCollapsibleObject: sidebarCollapsibleObjectType = {
    sidebarIsCollapsible: false,

    SET_TOGGLE_SIDEBAR_COLLAPSED() {
        this.sidebarIsCollapsible = !this.sidebarIsCollapsible;
    },

    SET_ACTIVE_SIDEBAR_COLLAPSED() {
        this.sidebarIsCollapsible = true;
    },

    SET_INACTIVE_SIDEBAR_COLLAPSED() {
        this.sidebarIsCollapsible = false;
    },

    get GET_SIDEBAR_IS_COLLAPSED() {
        return this.sidebarIsCollapsible;
    },
};

const sidebarCollapsibleStore = makeAutoObservable(sidebarCollapsibleObject);

export default sidebarCollapsibleStore;
