import axios, { AxiosResponse } from 'axios';
import ConfigurationStore from '../store/configurationStore';
import urljoin from 'url-join';

import { logOut } from '../utils/auth';
import { ReferencesItem } from './models/references';
import { AxiosMethod, fileDownload, requestTemplate } from './helper';

interface ReferencesServiceInterface {
    getList: () => Promise<AxiosResponse<any[]>>;
    getItemList: (id: number) => Promise<AxiosResponse<any[]>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class ReferencesService implements ReferencesServiceInterface {
    appManUrl = '';
    apiUrl = '';

    constructor(configStore: ConfigurationStore) {
        this.appManUrl = configStore.appConfig.appManUrl;
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    private axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url, '/'), config);
    };

    getList = async (): Promise<AxiosResponse<any[]>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/cls/full_descr`));
    };

    getListFile = async (date: any) => {
        await fileDownload(
            urljoin(this.apiUrl, '/cls/to_csv'),
            'references.csv',
            sessionStorage.getItem('access_token'),
            {
                clsrid: date.clsrid,
                clsrname: date.clsrname,
            },
        );
    };

    getItemList = async (id: number): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/cls/get/list/${id}`));
    };

    updateItem = async (
        clsid: number,
        data: ReferencesItem,
    ): Promise<AxiosResponse<ReferencesItem>> => {
        return await requestTemplate<any, ReferencesItem>(
            urljoin(this.apiUrl, `/cls/update/${clsid}`),
            AxiosMethod.put,
            data,
            sessionStorage.getItem('access_token'),
        );
    };

    createItem = async (
        clsr: number,
        data: ReferencesItem,
    ): Promise<AxiosResponse<ReferencesItem>> => {
        return await requestTemplate<any, ReferencesItem>(
            urljoin(this.apiUrl, `/cls/create_clsrow/${clsr}`),
            AxiosMethod.post,
            data,
            sessionStorage.getItem('access_token'),
        );
    };

    closeItem = async (clsid: number, stopdate: string): Promise<AxiosResponse<ReferencesItem>> => {
        return await requestTemplate<any, ReferencesItem>(
            urljoin(this.apiUrl, `/cls/cls/stopid/`),
            AxiosMethod.put,
            null,
            sessionStorage.getItem('access_token'),
            { clsid, stopdate },
        );
    };

    lockItem = async (clsid: number): Promise<AxiosResponse<ReferencesItem>> => {
        return await requestTemplate<any, ReferencesItem>(
            urljoin(this.apiUrl, `/cls/cls/switch_hidden/`),
            AxiosMethod.put,
            null,
            sessionStorage.getItem('access_token'),
            { clsid },
        );
    };
}

export default ReferencesService;
