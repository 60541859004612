import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ApplicationManagementService } from '../service/applicationManagementService';
import { ApplicationManagementItem } from '../service/models/applicationManagement';
import {
    NewRequestData,
    NewServiceData,
    EditRequestData,
} from '../service/models/applicationManagement';
import { ErrorStore } from './errorStore';

export class ApplicationManagementStore {
    loading = false;
    selectedRow: any;
    error: any;

    beginDate: any;
    endDate: any;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly applicationManagementService: ApplicationManagementService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.applicationManagementService = applicationManagementService;
    }

    rcList: Array<ApplicationManagementItem> = [];

    setBeginDate = (date: any) => {
        this.beginDate = date;
    };

    setEndDate = (date: any) => {
        this.endDate = date;
    };

    selectRow = (id: number | null) => {
        this.selectedRow = id;
    };

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async () => {
        try {
            return await this.fetchWrapper(
                () => this.applicationManagementService.getList(),
                (result: any) => {
                    this.rcList = result.data.map((rq: ApplicationManagementItem) =>
                        Object.assign(rq, { key: rq.id }),
                    );
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchListFile = async () => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.getListFile({
                    begin: this.beginDate,
                    end: this.endDate,
                }),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchCSVFile = async () => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.getCSVListFile({
                    begin: this.beginDate,
                    end: this.endDate,
                }),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchDocument = async (id: number, name: string) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.getDocument(id, name),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUploadDocument = async (file: any, rq_id: number) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.uploadDocument(file as any, rq_id as number),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchDeleteDocument = async (rq_id: number, file_id: number) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.deleteDocument(rq_id, file_id),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchNewRequest = async (newRequest: NewRequestData) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.createRequest(newRequest),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdateRequest = async (id: number, newRequest: EditRequestData) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.updateRequest(id, newRequest),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchCopyRequest = async (rg_id: number) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.copyRequest(rg_id),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchFt = async (id: number) => {
        try {
            return await this.fetchWrapper(() => this.applicationManagementService.getFt(id));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchStatus = async (id: number) => {
        try {
            return await this.fetchWrapper(() => this.applicationManagementService.getStatus(id));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchRq = async (id: number) => {
        try {
            return await this.fetchWrapper(() => this.applicationManagementService.getRq(id));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchRqStatus = async (id: number, status: any) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.rqActions(id, status),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchNewService = async (ft: string, newService: NewServiceData) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.createService(ft, newService),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdateService = async (id: number, ft: string, newService: NewServiceData) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.updateService(id, ft, newService),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchDeleteService = async (ft: string, id: number) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.deleteService(ft, id),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchRqExportFile = async (rq_id: number, filename?: string) => {
        try {
            return await this.fetchWrapper(() =>
                this.applicationManagementService.getRqExportFile(rq_id, filename),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
