import { makeAutoObservable } from 'mobx';

const mainPageOverlayObject = {
    mainPageOverlayIsActive: false,

    SET_MAIN_PAGE_OVERLAY_ACTIVE() {
        this.mainPageOverlayIsActive = true;
    },

    SET_MAIN_PAGE_OVERLAY_INACTIVE() {
        this.mainPageOverlayIsActive = false;
    },

    get GET_MAIN_PAGE_OVERLAY() {
        return this.mainPageOverlayIsActive;
    },
};

const mainPageOverlayStore = makeAutoObservable(mainPageOverlayObject);

export default mainPageOverlayStore;
