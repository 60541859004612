export default {
    title: 'Помощь и Поддержка',
    fields: {
        information: 'Информация',
        phone: 'Телефон',
        email: 'E-mail',
        material: 'Справочные материалы',
        addFile: 'Добавить файл',
        save: 'Сохранить',
        description: 'Описание',
    },
    placeholders: {
        information_ru: 'Информация на русском языке',
        information_en: 'Информация на английском языке',
        phone: 'Номер телефона',
        email: 'e-mail',
        material: 'Справочные материалы',
        addFile: 'Добавить файл',
        description: 'Описание',
        description_ru: 'Описание на Русском',
        description_en: 'Описание на Английском',
    },
    attach: {
        title: 'Загрузка файла',
        buttons: {
            save: 'Сохранить',
            cancel: 'Отмена',
            addFile: 'Добавить файл',
        },
    },
    files: {
        delete: 'Вы действительно хотите удалить файл',
    },
    modal: {
        buttons: {
            yes: 'Да',
            no: 'Нет',
        },
    },
};
