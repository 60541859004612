import { Breadcrumb, Form, PageHeader, Radio, Table } from 'antd';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Users.module.scss';
import { useStores } from '../../store';
import cs from '../../utils/composeStyles';

import getLanguageContent from '../../content';

import columns from './columns';

import { FileExcelOutlined, FormOutlined } from '@ant-design/icons';

import EditUser from './EditUser/EditUser';

const Users = () => {
    const { usersStore, configStore, dictionaryStore, searchStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [modal, setModal] = useState(false);
    const [newUser, setNew] = useState(false);

    const getUsersList = async () => {
        try {
            await usersStore.fetchList();
        } catch (err: any) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getUsersList().catch(err => console.error(err.message));
        dictionaryStore.getRulesDictionary().catch(err => console.error(err.message));
        dictionaryStore.getAuthDictionaries().catch(err => console.error(err.message));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getUsersList().catch(err => console.error(err.message));
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const onSelectChange = (record: any, selected: boolean) => {
        if (selected) {
            usersStore.selectRow(record.id);
            usersStore.setUser(record);
        } else onSelectNone();
    };

    const onSelectNone = () => {
        usersStore.selectRow(null);
    };

    const grantAdminHandler = (userId: number, isAdmin: boolean) => {
        usersStore
            .fetchGrantAdmin(userId, isAdmin)
            .then(response => {
                if (response.status === 200) {
                    usersStore.fetchList().catch(err => console.error(err.message));
                }
            })
            .catch(err => console.error(err.message));
        onSelectNone();
    };

    const activateUserHandler = (userId: number, isActive: boolean) => {
        usersStore
            .fetchActivateUser(userId, isActive)
            .then(response => {
                if (response.status === 200) {
                    usersStore.fetchList().catch(err => console.error(err.message));
                }
            })
            .catch(err => console.error(err.message));
        onSelectNone();
    };

    const handlerEditUserClick = () => {
        setModal(true);
    };

    const handlerNewUserClick = () => {
        setNew(true);
        setModal(true);
    };

    const onModalClose = () => {
        setNew(false);
        setModal(!modal);
        usersStore.selectRow(null);
        getUsersList().catch(err => console.error(err.message));
    };

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.users.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <div className={styles.tableWrapper}>
                <Form layout="inline" className="components-table-demo-control-bar">
                    <Form.Item>
                        <Radio.Group buttonStyle="solid" className={styles.buttons}>
                            <Radio.Button
                                onClick={handlerNewUserClick}
                                className={cs(styles.createBtn, 'btn-blue')}
                            >
                                {
                                    <span>
                                        <FileExcelOutlined className={styles.icon} />
                                        {content.users.buttons.create}
                                    </span>
                                }
                            </Radio.Button>
                            <Radio.Button
                                onClick={handlerEditUserClick}
                                className={cs(styles.editBtn, 'btn-blue')}
                                disabled={!usersStore.selectedRow}
                            >
                                {
                                    <span>
                                        <FormOutlined className={styles.icon} />
                                        {content.users.buttons.edit}
                                    </span>
                                }
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <div className={styles.space} />
                </Form>

                <Table
                    rowSelection={{
                        selectedRowKeys: [usersStore.selectedRow],
                        onSelect: onSelectChange,
                        onSelectAll: onSelectNone,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                usersStore.selectRow(record.id);
                                usersStore.setUser(record);
                            },
                        };
                    }}
                    dataSource={usersStore.usersList
                        .filter((item: any) => {
                            const searchText = searchStore.getCurrentSearch('Users');

                            if (!!searchText) {
                                const search = searchText.toLowerCase();
                                return (
                                    ('' + item.id).search(searchText) !== -1 ||
                                    (item.username &&
                                        item.username.toLowerCase().search(search) !== -1) ||
                                    (item.full_name_en &&
                                        item.full_name_en.toLowerCase().search(search) !== -1) ||
                                    (item.full_name_ru &&
                                        item.full_name_ru.toLowerCase().search(search) !== -1) ||
                                    (item.company_str &&
                                        item.company_str.toLowerCase().search(search) !== -1) ||
                                    (item.position &&
                                        item.position.toLowerCase().search(search) !== -1)
                                );
                            } else return true;
                        })
                        .map((item: any) => {
                            const companyStr = item.company_str.split('#\n');
                            return Object.assign(
                                {
                                    company_str_ru:
                                        companyStr.length > 1 ? companyStr[1] : companyStr[0],
                                    company_str_en:
                                        companyStr.length > 1 ? companyStr[0] : companyStr[0],
                                },
                                item,
                            );
                        })}
                    columns={columns(
                        configStore.lang,
                        content.users.table,
                        dictionaryStore.organizations,
                        grantAdminHandler,
                        activateUserHandler,
                    )}
                    rowClassName={(user: any) =>
                        user.is_active ? styles.doubleRow : cs(styles.doubleRow, styles.redRow)
                    }
                    size={'small'}
                    pagination={{ showSizeChanger: false, pageSize: 10 }}
                    showSorterTooltip={false}
                />
            </div>

            {modal && (
                <EditUser
                    open={modal}
                    newUser={newUser}
                    user_id={!newUser ? usersStore.selectedRow : null}
                    initialValues={!newUser ? usersStore.user : {}}
                    onClose={onModalClose}
                    editable={true}
                />
            )}
        </div>
    );
};

export default observer(Users);
