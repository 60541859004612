import React from 'react';
import Icon from '@ant-design/icons';

const rknIcon = props => {
    const svg = () => (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 10.8638L13.8 13.8638L10.2 11.7638L9.7 11.4638L8 10.5638L6.3 11.4638L5.8 11.7638L2.2 13.8638L0.5 10.8638L4.1 8.86377L4.6 8.56377L6.3 7.56377V0.86377H9.7V7.56377L11.4 8.56377L11.9 8.86377L15.5 10.8638Z"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );

    return <Icon component={svg} {...props} />;
};

const exitIcon = props => {
    const svg = () => (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 11.9V15H1V1H11V5M15 8L13 6M15 8L13 10M15 8H7"
                stroke="#363740"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );

    return <Icon component={svg} {...props} />;
};

export default {
    rknIcon,
    exitIcon,
};
