import React, { useState } from 'react';

import { Modal, Form, Button, Col } from 'antd';
import getLanguageContent from '../../../content';
import { useStores } from '../../../store';
import styles from './EditUser.module.scss';
import { CloseIcon } from '../../../components/Icons/Icons';
import { Password } from '../../../components/Inputs/Inputs';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

const EditPassword = (props: any) => {
    const { usersStore, configStore, authStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const handleClose = () => {
        setModal(false);
        form.resetFields();
    };

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            setLoading(true);

            usersStore
                .fetchUpdatePassword(usersStore.user.id, values)
                .then(response => {
                    if (response.status === 200) {
                        handleClose();
                    }
                })
                .finally(() => setLoading(false));
        });
    };

    const validation = (rule: any, value: string, callback: any) => {
        if (!dictionaryStore.rules.password.test(value)) {
            callback(content.auth.validate.invalidPassword);
        } else {
            callback();
        }
    };

    return (
        <>
            <Button className="btn btn--w100 btn-blue" onClick={() => setModal(true)}>
                {content.users.editPassword.button}
            </Button>
            <Modal visible={modal} footer={null} closable={false} onCancel={handleClose}>
                <div className={styles.header}>
                    <h3 className={styles.title}>{content.users.editPassword.title}</h3>
                    <Button
                        type="text"
                        icon={<CloseIcon />}
                        className={styles.closeBtn}
                        onClick={handleClose}
                    />
                </div>

                <Form
                    style={{ marginLeft: 40, marginRight: 40, paddingTop: 36, paddingBottom: 36 }}
                    form={form}
                >
                    <Form.Item
                        name={'password'}
                        className="form__item"
                        key={'password'}
                        rules={(() => {
                            const rulesArr = [];
                            rulesArr.push({
                                required: true,
                                message: content.auth.validate.required,
                            });
                            rulesArr.push({
                                validator: (
                                    rule: RuleObject,
                                    value: StoreValue,
                                    callback: (error?: string) => void,
                                ) =>
                                    value
                                        ? validation && validation(rule, value, callback)
                                        : Promise.resolve(),
                            });

                            return rulesArr;
                        })()}
                    >
                        <Password placeholder={content.users.editPassword.newPass} />
                    </Form.Item>
                </Form>

                <div className={styles.modalFooter}>
                    <Button
                        className="btn btn--w100 btn-blue"
                        onClick={onSubmitForm}
                        disabled={loading}
                    >
                        {content.newRequest.buttons.save}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default EditPassword;
