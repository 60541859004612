import { Button, Drawer } from 'antd';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Support.module.scss';
import getLanguageContent from '../../content';
import { useStores } from '../../store';

import Support from './Support';
import { CloseIcon } from '../../components/Icons/Icons';

const SupportPage = (props: any) => {
    const { visible, handleVisible } = props;
    const { configStore, supportStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [downloaded, setDownloaded] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        supportStore
            .fetchData()
            .then(() => {
                supportStore
                    .fetchFiles()
                    .then((result: any) => {
                        setFiles(result.data);
                    })
                    .catch(err => console.error(err.message));
            })
            .catch(err => console.error(err.message))
            .finally(() => setDownloaded(true));
    }, []);

    return (
        <Drawer
            visible={visible}
            placement={'left'}
            width={722}
            onClose={handleVisible}
            closable={false}
        >
            <div className={styles.modalHeader}>
                <h3 className={styles.title}>{content.support.title}</h3>
                <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.closeBtn}
                    onClick={handleVisible}
                />
            </div>
            <Support modal downloaded={downloaded} files={files} setFiles={setFiles} />
        </Drawer>
    );
};

export default observer(SupportPage);
