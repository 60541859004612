import React, { FC, ReactNode } from 'react';
import styles from './AuthFormContainer.module.scss';
import { LogoIcon, FISU, U2023 } from '../../components/Icons/Icons';
import AuthLinks from './components/AuthLinks/AuthLinks';
import AuthFooter from './components/AuthFooter/AuthFooter';
import { Spin } from 'antd';
import { useStores } from '../../store';
import { observer } from 'mobx-react';

type AuthFormContainerPropsTypes = {
    children: ReactNode;
};

const AuthFormContainer: FC<AuthFormContainerPropsTypes> = ({ children }) => {
    const { authStore } = useStores();
    return (
        <div className={styles.layout}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <U2023 className={styles.header__left} />
                    <FISU className={styles.header__right} />
                </div>
                <div className={styles.content__logo}>
                    <p>SPECTRUM</p>
                    <p>order portal</p>
                </div>
                <AuthLinks />
                <div className={styles.formContainer}>
                    <Spin spinning={authStore.loading}>{children}</Spin>
                </div>
            </div>
            <AuthFooter />
        </div>
    );
};

export default observer(AuthFormContainer);
