export const authContentRU = {
    login: 'Авторизация',
    registration: 'Регистрация',
    step: 'ШАГ',
    registrationFields: {
        first_name_ru: 'Имя*',
        last_name_ru: 'Фамилия*',
        username: 'Имя пользователя (логин)*',
        email: 'Эл. почта* ',
        phone_num: 'Телефон*',
        company_str: 'Организация*',
        position: 'Должность*',
        usergroup: 'Группа участников*',
        func_area: 'Функциональное направление*',
        manager_email: 'Эл.почта представителя Дирекции',
        password: 'Создайте пароль*',
        passwordConfirm: 'Подтверждение пароля*',
        captcha: 'Введите символы слева*',
    },
    validate: {
        invalidFirstName: 'Не валидное имя',
        invalidLastName: 'Не валидная фамилия',
        invalidEmail: 'Не валидный E-mail',
        invalidPhone: 'Не валидный номер телефона',
        invalidCompany: 'Не валидная организация',
        invalidPosition: 'Не валидная должность',
        invalidUsername: 'Не валидный логин',
        invalidPassword: 'Не валидный пароль',
        mismatchPassword: 'Пароли не совпадают',
        invalidCaptcha: 'Введены неверные символы',
        required: 'Поле обязательно для заполнения',
    },
    loginFields: {
        username: 'Логин',
        password: 'Пароль',
    },
    buttons: {
        next: 'ДАЛЕЕ',
        back: 'НАЗАД',
        registration: 'ЗАРЕГИСТРИРОВАТЬСЯ',
        login: 'ВОЙТИ',
        changePass: 'СМЕНИТЬ ПАРОЛЬ',
        restorePassword: 'Забыли пароль?',
        sendEmail: 'Запросить смену пароля',
        backToLogin: 'Вернуться к странице входа в систему',
    },
    footer: {
        event: 'Всемирные студенческие игры ФИСУ 2023',
        helper: 'Помощь и поддержка',
    },
    notifications: {
        notActivate:
            'Ваша учётная запись не активна. Пожалуйста, обратитесь к администратору - support@ekat2023.com',
        notCorrect: 'Неверное имя пользователя или пароль',
        registrationTitle: 'Регистрация',
        registrationSuccess: (username: string) => `Пользователь ${username} зарегистрирован. \n
        На указанный e-mail отправлено сообщение о регистрации, Вам необходимо открыть принятое сообщение и подтвердить свой e-mail`,
        registrationError: (message: string) => `Ошибка регистрации: ${message}`,
    },
    modal: {
        email: {
            success: 'Ссылка для смены пароля отправлена на указанный адрес электронной почты',
            error: 'Ошибка при отправке запроса',
        },
        password: {
            success: 'Пароль успешно изменён',
            error: 'Ошибка при смене пароля',
        },
        buttons: {
            yes: 'Ok',
            no: 'Нет',
        },
    },
};
