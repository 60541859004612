export default {
    title: 'User Accounts',
    buttons: {
        create: 'New',
        copy: 'Copy',
        edit: 'Open',
        delete: 'Delete',
        cancel: 'Revoke',
        actions: 'Actions',
        export: 'Export to',
    },
    table: {
        header: {
            id: 'ID',
            username: 'Login',
            name: 'Name',
            role: 'Role',
            status: 'Status',
            company: 'Organization',
            position: 'Position',
            actions: 'Actions',
            email: 'e-mail confirmed',
            active: 'Active',
        },
        buttons: {
            activate: 'Activate',
            deactivate: 'Deactivate',
        },
        variables: {
            admin: 'Administrator',
            consumer: 'Consumer',
            active: 'Active',
            inactive: 'Inactive',
        },
        modal: {
            admin: (user: string, role: string) =>
                `Do you confirm change role for user ${user} to ${
                    role ? 'Administrator' : 'Consumer'
                }?`,
            activate: (user: string, activate: boolean) =>
                `Do you confirm ${activate ? 'activation' : 'deactivation'} user ${user}?`,
            buttons: {
                yes: 'Yes',
                no: 'No',
            },
        },
    },
    editUser: {
        titleEdit: 'Edit user',
        titleNew: 'New user',
        id: 'User ID',
        username: 'Username',
        full_name_ru: 'Full name on Russian',
        full_name_en: 'Full name on English',
        is_admin: 'Administrator',
        is_active: 'Active',
        func_area: 'Functional Area',
        email: 'e-mail',
        email_checked: 'e-mail confirmed',
        email_unchecked: 'e-mail not confirmed',
        company_str: 'Company name',
        company: 'Company',
        usergroup: 'User group',
        position: 'Position',
        phone_num: 'Phone number',
    },
    editPassword: {
        title: 'Change password',
        button: 'Change pass',
        newPass: 'New password',
    },
};
