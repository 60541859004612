import React from 'react';

import { Modal, Form } from 'antd';

import { TextArea } from '../../../components/Inputs/Inputs';
import styles from '../Request/Request.module.scss';
import { useStores } from '../../../store';
import getLanguageContent from '../../../content';
import { NewRequestData } from '../../../service/models/applicationManagement';

const RejectDialog = (props: any) => {
    const { applicationManagementStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const [form] = Form.useForm();

    const { open, rq_id, status, onClose } = props;

    const handleSubmit = () => {
        form.validateFields().then((values: NewRequestData) => {
            applicationManagementStore.fetchUpdateRequest(rq_id, { ...values }).then(response => {
                if (response.status === 200) {
                    applicationManagementStore
                        .fetchRqStatus(rq_id as number, {
                            rq_id,
                            available_only: false,
                            to_status: status,
                        })
                        .then(response => {
                            if (response.status === 200) {
                                applicationManagementStore
                                    .fetchList()
                                    .then(response => {
                                        if (response.status === 200) {
                                            handleClose(true);
                                        }
                                    })
                                    .catch(err => console.error(err.message));
                            }
                        });
                }
            });
        });
    };

    const handleClose = (saved: boolean) => {
        onClose(saved);
        form.resetFields();
    };

    return (
        <Modal
            visible={open}
            cancelText={content.appManagement.attach.buttons.cancel}
            okText={content.appManagement.attach.buttons.save}
            onOk={handleSubmit}
            onCancel={() => handleClose(false)}
        >
            <Form style={{ marginTop: 30 }} form={form}>
                <Form.Item
                    name={'ReasonOfReject'}
                    className="form__item"
                    key={'ReasonOfReject'}
                    rules={[
                        {
                            required: true,
                            message: content.newRequest.validate.required,
                        },
                    ]}
                >
                    <TextArea
                        className={styles.antInput}
                        placeholder={content.newRequest.inputs.reason}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RejectDialog;
