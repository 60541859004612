export default {
    title: 'Мониторинг',
    inputs: {
        filter: 'Просмотр',
        startDate: 'Старт даты',
        endDate: 'Конец даты',
    },
    table: {
        header: {
            ctime: 'Дата',
            source: 'Источник',
            level: 'Уровень',
        },
    },
    level: 'Уровень лога',
};
