export default {
    titles: {
        new: 'Новая заявка',
        edit: 'Заявка',
        copy: 'Копия заявки',
    },
    buttons: {
        save: 'Сохранить',
        delete: 'Удалить',
        open: 'Открыть',
        send: 'Отправить в РКН',
        export: 'Экспорт',
        add: 'Добавить тип РЭС',
    },
    selects: {
        event: 'Событие*',
        period: 'Период использования*',
        organization: 'Владелец РЭС*',
        contact: 'Контактное лицо',
    },
    inputs: {
        description: 'Описание заявки',
        reason: 'Комментарий согласующего органа',
    },
    table: {
        header: {
            service: 'Тип РЭС',
            object: 'Место использования',
            model: 'Модель РЭС',
            class: 'Класс излучения',
            recFrequency: 'Диапазон рабочих частот приема, МГц',
            transFrequency: 'Диапазон рабочих частот передачи, МГц',
            actions: 'Действия',
        },
        more: 'ещё',
    },
    modal: {
        service: 'Тип сервиса',
        object: 'Место использования',
        freceive: 'Предпочтительная частота (прием), МГц',
        ftransmit: 'Предпочтительная частота (передача), МГц',
        nfreceive: 'Номиналы радиочастот, ПРМ (МГц)',
        nftransmit: 'Номиналы радиочастот, ПРД (МГц)',
        model: 'Модель РЭС',
        comment: 'Комментарий',
        brand: 'Изготовитель РЭС',
        eclass: 'Класс излучения',
        quant: 'Количество идентичных устройств',
        fquant: 'Количество запрашиваемых частот',
        frange: 'Ширина диапазона частот настройки',
        franger: 'Диапазон рабочих частот (прием), МГц',
        franget: 'Диапазон рабочих частот (передача), МГц',
        frangerfrom: 'Начало',
        frangerto: 'Окончание',
        frangetfrom: 'Начало',
        frangetto: 'Окончание',
        chsize: 'Ширина полосы радиоканала, кГц',
        duplex: 'Дуплексный разнос, МГц',
        maxpower: 'Максимальная выходная мощность передатчика, Вт',
        usetype: 'Способ применения',
        typetun: 'Фиксированные частоты РЭС',
        standart: 'Стандарт (для технологии семейства стандартов IEЕЕ 802.11)',
        chstep: 'Шаг сетки частот, кГц',
        satname: 'Наименование космического аппарата',
        georbit: 'Точка стояния, град',
        lat: 'Географические координаты места размещения ЗССС (долгота)',
        long: 'Географические координаты места размещения ЗССС (широта)',
        outpower: 'Мощность излучаемой несущей, дБВт',
        antd: 'Диаметр (размер) антенны, м',
        antgaint: 'Коэффициент усиления антенны (передача), дБ',
        antgainr: 'Коэффициент усиления антенны (прием), дБ',
        antheight: 'Высота подвеса фазового центра антенны над поверхностью Земли,м',
        outpower_a: 'Мощность излучения (точка А), Вт',
        outpower_b: 'Мощность излучения (точка В), Вт',
        again_a: 'Коэффициент усиления антенны (точка А), дБ',
        again_b: 'Коэффициент усиления антенны (точка B), дБ',
        ssid: 'SSID точки доступа',
        macaddress: 'MAC-адрес точки доступа',
        from: 'от',
        to: 'до',
        mHz: 'мГц',
    },
    validate: {
        required: 'Поле обязательно для заполнения',
        frequence: 'Некорректная частота',
        eclass: 'Некорректный класс излучения',
        quantity: 'Некорректное количество',
        coordinate: 'Некорректные координаты',
        chstep: 'Некорректный шаг сетки частот',
        float: 'Некорректное значение',
    },
};
