import { FormFieldsInterface } from '../../../../components/FormComponent/FormComponent';
import { DictionaryItem } from '../../../../service/models/dictionaryItem';
import { parseDictionaryItem } from '../../../../utils/parseJson';
import { parseDictionaryItemName } from '../../../../pages/ApplicationManagement/utils/parseDictionaryItem';
import { ContentType, Languages } from '../../../../content';

export const getRegistrationFirstStep = (
    content: ContentType,
    organizationDictionary: DictionaryItem[],
    rules: any,
    tooltip: any,
    lang: Languages,
): Array<FormFieldsInterface> => {
    return [
        {
            key: `first_name_${lang}`,
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.first_name_ru,
                validation: (rule, value, callback) => {
                    if (!rules[`first_name_${lang}`].test(value)) {
                        callback(content.auth.validate.invalidFirstName);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip[`first_name_${lang}`],
                required: true,
            },
        },
        {
            key: `last_name_${lang}`,
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.last_name_ru,
                validation: (rule, value, callback) => {
                    if (!rules[`last_name_${lang}`].test(value)) {
                        callback(content.auth.validate.invalidLastName);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip[`last_name_${lang}`],
                required: true,
            },
        },
        {
            key: 'username',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.username,
                validation: (rule, value, callback) => {
                    if (!rules[`username`].test(value)) {
                        callback(content.auth.validate.invalidUsername);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.username,
                required: true,
            },
        },
        {
            key: 'email',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.email,
                validation: (rule, value, callback) => {
                    if (!rules[`email`].test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.email,
                required: true,
            },
        },
        {
            key: 'phone_num',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                validation: (rule, value, callback) => {
                    if (!rules[`phone_num`].test(value)) {
                        callback(content.auth.validate.invalidPhone);
                    } else {
                        callback();
                    }
                },
                placeholder: content.auth.registrationFields.phone_num,
                tooltip: tooltip.phone_num,
                required: true,
            },
        },
        {
            key: 'company',
            classNameField: 'spectrumField',
            meta: {
                type: 'selectable-input',
                placeholder: content.auth.registrationFields.company_str,
                filterByOptionName: 'label',
                validation: (rule, value, callback) => {
                    if (!rules[`company`].test(value)) {
                        callback(content.auth.validate.invalidCompany);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.company,
            },
            proposedData: parseDictionaryItemName(organizationDictionary, lang),
        },
    ];
};

export const getRegistrationSecondStep = (
    content: ContentType,
    userGroupDictionary: DictionaryItem[],
    functionalAreaDictionary: DictionaryItem[],
    rules: any,
    tooltip: any,
    lang: Languages,
    usergroup: null | number,
    onChange: any,
): Array<FormFieldsInterface> => {
    return [
        {
            key: 'position',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.position,
                validation: (rule, value, callback) => {
                    if (!rules[`position`].test(value)) {
                        callback(content.auth.validate.invalidPosition);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.position,
                required: true,
            },
        },
        {
            key: 'usergroup',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.auth.registrationFields.usergroup,
                required: true,
                filterByOptionName: 'label',
                onChange: onChange,
            },
            proposedData: parseDictionaryItem(userGroupDictionary, lang),
        },
        {
            key: 'func_area',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.auth.registrationFields.func_area,
                required: usergroup === 2012,
                disabled: usergroup !== 2012,
                filterByOptionName: 'label',
            },
            proposedData: parseDictionaryItem(functionalAreaDictionary, lang),
        },
        {
            key: 'manager_email',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.manager_email,
                validation: (rule, value, callback) => {
                    if (!!value && usergroup === 2012 && !rules[`email`].test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.email,
                required: false,
                disabled: usergroup !== 2012,
            },
        },
    ];
};

export const getRegistrationThirdStep = (
    content: ContentType,
    userGroupDictionary: DictionaryItem[],
    functionalAreaDictionary: DictionaryItem[],
    rules: any,
    tooltip: any,
    lang: Languages,
): Array<FormFieldsInterface> => {
    return [
        {
            key: 'password',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.registrationFields.password,
                validation: (rule, value, callback) => {
                    if (!rules[`password`].test(value)) {
                        callback(content.auth.validate.invalidPassword);
                    } else {
                        callback();
                    }
                },
                tooltip: tooltip.password,
                required: true,
            },
        },
        {
            key: 'passwordConfirm',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.registrationFields.passwordConfirm,
                required: true,
                validation: (rule, value, callback, values) => {
                    if (values.password !== value) {
                        callback(content.auth.validate.mismatchPassword);
                    } else {
                        callback();
                    }
                },
            },
        },
    ];
};
