import React from 'react';
// import { AppConfig } from "./configurationStore";
import AuthStore from './authStore';
import ConfigurationStore from './configurationStore';
import { NotificationStore } from './notificationStore';
import { DictionaryService } from '../service/dictionaryService';
import { ApplicationManagementService } from '../service/applicationManagementService';
import { DictionaryStore } from './dictionaryStore';
import { ApplicationManagementStore } from './applicationManagementStore';
import { UsersStore } from './usersStore';
import { SupportStore } from './supportStore';
import { ReportsStore } from './reportsStore';
import { MonitoringStore } from './monitoringStore';
import { JournalStore } from './journalStore';
import { ReferencesStore } from './referencesStore';
import { NotificationsTemplateStore } from './notificationsTemplateStore';
import { AuthService } from '../service/authService';
import { UsersService } from '../service/usersService';
import { SupportService } from '../service/supportService';
import { ReportsService } from '../service/reportsService';
import { MonitoringService } from '../service/monitoringService';
import { JournalService } from '../service/journalService';
import { ReferencesService } from '../service/referencesService';
import { NotificationsTemplatesService } from '../service/notificationsTemplatesService';
import { ErrorStore } from './errorStore';

import mainPageOverlayStore from './mainPageOverlayStore';
import sidebarCollapsibleStore from './sidebarCollapsibleStore';
import searchStore from './searchStore';

export interface AppContext {
    authStore: AuthStore;
    configStore: ConfigurationStore;
    notificationStore: NotificationStore;
    dictionaryStore: DictionaryStore;
    applicationManagementStore: ApplicationManagementStore;
    usersStore: UsersStore;
    supportStore: SupportStore;
    reportsStore: ReportsStore;
    monitoringStore: MonitoringStore;
    journalStore: JournalStore;
    referencesStore: ReferencesStore;
    notificationsTemplateStore: NotificationsTemplateStore;
    errorStore: ErrorStore;
    sidebarCollapsibleStore: typeof sidebarCollapsibleStore;
    mainPageOverlayStore: typeof mainPageOverlayStore;
    searchStore: typeof searchStore;
}

const configStore = new ConfigurationStore();
const appContext: AppContext = {
    configStore,
    sidebarCollapsibleStore,
    mainPageOverlayStore,
    searchStore,
} as AppContext;

configStore.init((/*config: AppConfig*/) => {
    const dictionaryService = new DictionaryService(configStore);
    const applicationManagementService = new ApplicationManagementService(configStore);
    const authService = new AuthService(configStore);
    const usersService = new UsersService(configStore);
    const supportService = new SupportService(configStore);
    const reportsService = new ReportsService(configStore);
    const monitoringService = new MonitoringService(configStore);
    const journalService = new JournalService(configStore);
    const referencesService = new ReferencesService(configStore);
    const notificationsTemplatesService = new NotificationsTemplatesService(configStore);

    appContext.notificationStore = new NotificationStore(appContext.configStore);
    appContext.errorStore = new ErrorStore();
    appContext.dictionaryStore = new DictionaryStore(
        dictionaryService,
        appContext.notificationStore,
    );
    appContext.applicationManagementStore = new ApplicationManagementStore(
        appContext.notificationStore,
        appContext.errorStore,
        applicationManagementService,
    );
    appContext.usersStore = new UsersStore(
        appContext.notificationStore,
        appContext.errorStore,
        usersService,
    );
    appContext.supportStore = new SupportStore(
        appContext.notificationStore,
        appContext.errorStore,
        appContext.usersStore,
        supportService,
    );
    appContext.reportsStore = new ReportsStore(
        appContext.notificationStore,
        appContext.errorStore,
        reportsService,
    );
    appContext.referencesStore = new ReferencesStore(
        appContext.notificationStore,
        appContext.errorStore,
        appContext.dictionaryStore,
        referencesService,
    );
    appContext.monitoringStore = new MonitoringStore(
        appContext.notificationStore,
        appContext.errorStore,
        monitoringService,
    );
    appContext.journalStore = new JournalStore(
        appContext.notificationStore,
        appContext.errorStore,
        journalService,
    );
    appContext.notificationsTemplateStore = new NotificationsTemplateStore(
        appContext.notificationStore,
        appContext.errorStore,
        appContext.usersStore,
        notificationsTemplatesService,
    );

    appContext.authStore = new AuthStore(
        appContext.notificationStore,
        appContext.configStore,
        appContext.errorStore,
        authService
    );

    appContext.dictionaryStore.getAuthDictionaries();
});

const storesContext: React.Context<AppContext> = React.createContext(appContext);

export const useStores = () => React.useContext(storesContext);
