import React, { useState } from 'react';
import styles from './AuthFooter.module.scss';
import classNames from 'classnames';
import { LanguageIcon } from '../../../../components/Icons/Icons';
import { useStores } from '../../../../store';
import getLanguageContent, { Languages } from '../../../../content';
import { observer } from 'mobx-react';

import Support from '../../../../pages/Support/SupportModal';

const AuthFooter = () => {
    const { configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [support, setSupport] = useState(false);

    const handleChangeLanguage = (val: Languages) => {
        configStore.changeLanguage(val);
    };

    const handleSupportModal = () => {
        setSupport(!support);
    };

    return (
        <div className={styles.footer}>
            <div className={classNames(styles.footer__item, styles.footerGroup)}>
                <div className={styles.footerGroup__element}>
                    <LanguageIcon />
                </div>
                <div className={styles.footerGroup__element}>
                    {configStore.lang === Languages.RU ? (
                        <div
                            className={styles.footerGroup__element_lang}
                            onClick={() => handleChangeLanguage(Languages.EN)}
                        >
                            English
                        </div>
                    ) : (
                        <div
                            className={styles.footerGroup__element_lang}
                            onClick={() => handleChangeLanguage(Languages.RU)}
                        >
                            Русский
                        </div>
                    )}
                </div>
                <div
                    className={styles.footerGroup__element}
                    onClick={handleSupportModal}
                    style={{ cursor: 'pointer' }}
                >
                    {content.auth.footer.helper}
                </div>
            </div>
            <a target="_blank" href={'https://ekat2023.com/'}>
                <div className={classNames(styles.footer__item, styles.footerGroup)}>
                    <div className={styles.footerGroup__element}>{content.auth.footer.event}</div>
                </div>
            </a>
            <Support visible={support} handleVisible={handleSupportModal} />
        </div>
    );
};

export default observer(AuthFooter);
