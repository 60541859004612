import React, { FC } from 'react';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { InternalFieldProps } from 'rc-field-form/lib/Field';
import './FormComponent.scss';
import { Form, FormInstance, Input, Select, Tooltip } from 'antd';
import MaskedInput from 'antd-mask-input';
import { useStores } from '../../store';
import getLanguageContent from '../../content';
import { SelectableInput } from '../Inputs/Inputs';

const { Option } = Select;

export interface FormFieldsInterface {
    title?: string;
    key: string;
    formItemParams?: InternalFieldProps;
    classNameField?: string;
    meta: {
        style?: any;
        type: string;
        span?: number;
        filterByOptionName?: string;
        placeholder?: string;
        mask?: string;
        required?: boolean;
        validation?: (
            rule: RuleObject,
            value: StoreValue,
            callback: (error?: string) => void,
            values?: any,
        ) => Promise<void | any> | void;
        replace?: (value: any) => string;
        pattern?: any;
        tooltip?: any;
        disabled?: any;
        suffix?: any;
        onClick?: any;
        onChange?: any;
    };
    proposedData?: Array<ProposedDataInterface>;
}

export interface FormFieldsUsersInterface {
    title?: string;
    key: string;
    formItemParams?: InternalFieldProps;
    classNameField?: string;
    meta: {
        type: string;
        span?: number;
        filterByOptionName?: string;
        placeholder?: string;
        mask?: string;
        required?: any;
        validation?: (
            rule: RuleObject,
            value: StoreValue,
            callback: (error?: string) => void,
            values?: any,
        ) => Promise<void | any> | void;
        replace?: (value: any) => string;
        pattern?: any;
        tooltip?: any;
        disabled?: any;
        suffix?: any;
        onClick?: any;
    };
    proposedData?: Array<ProposedDataInterface>;
}

interface ProposedDataInterface {
    label: string;
    value: string | number;
    is_hidden?: boolean;
}

type FormComponentPropTypes = {
    formName: string;
    formFields: Array<FormFieldsInterface>;
    formInstance?: FormInstance;
};

const FormComponent: FC<FormComponentPropTypes> = ({ formName, formFields, formInstance }) => {
    const { configStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    return (
        <Form name={formName} form={formInstance} className={'formComponent'}>
            {formFields.map(field => {
                let itemField: any = undefined;
                if (field.meta.type === 'input') {
                    if (field.meta.mask) {
                        itemField = (
                            <MaskedInput
                                mask={field.meta.mask}
                                className={field.classNameField}
                                formatCharacters={{
                                    W: {
                                        validate(char: string) {
                                            return /\w/.test(char);
                                        },
                                        transform(char: string) {
                                            return char.toUpperCase();
                                        },
                                    },
                                }}
                            />
                        );
                    } else {
                        itemField = (
                            <Input
                                placeholder={field.meta.placeholder ?? field.title}
                                className={field.classNameField}
                                disabled={field.meta.disabled}
                            />
                        );
                    }
                }
                if (field.meta.type === 'password') {
                    itemField = (
                        <Input.Password
                            placeholder={field.meta.placeholder ?? field.title}
                            className={field.classNameField}
                        />
                    );
                }
                if (field.meta.type === 'select') {
                    itemField = (
                        <Select
                            allowClear
                            className={field.classNameField}
                            placeholder={field.meta.placeholder}
                            showSearch
                            disabled={field.meta.disabled}
                            onChange={field.meta.onChange}
                            filterOption={
                                field.meta.filterByOptionName
                                    ? (inputValue, option) =>
                                          option?.children
                                              .toLowerCase()
                                              .includes(inputValue.toLowerCase())
                                    : true
                            }
                        >
                            {field.proposedData?.map(item => (
                                <Option value={item.value} key={`${field.key}_proposedData`}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    );
                }
                if (field.meta.type === 'selectable-input') {
                    itemField = (
                        <SelectableInput field={field} form={formInstance} content={content} />
                    );
                }
                return (
                    <Tooltip
                        title={field.meta.tooltip ? field.meta.tooltip[configStore.lang] : null}
                        trigger={['click', 'focus']}
                    >
                        <Form.Item
                            name={field.key}
                            label={field.title}
                            className="form__item"
                            key={field.key}
                            rules={(() => {
                                const rulesArr = [];
                                if (field.meta.required)
                                    rulesArr.push({
                                        required: field.meta.required,
                                        message: content.auth.validate.required,
                                    });
                                if (field.meta.validation) {
                                    rulesArr.push({
                                        validator: (
                                            rule: RuleObject,
                                            value: StoreValue,
                                            callback: (error?: string) => void,
                                        ) =>
                                            value
                                                ? field.meta.validation &&
                                                  field.meta.validation(
                                                      rule,
                                                      value,
                                                      callback,
                                                      formInstance?.getFieldsValue(),
                                                  )
                                                : Promise.resolve(),
                                    });
                                }
                                return rulesArr;
                            })()}
                            {...field.formItemParams}
                        >
                            {itemField}
                        </Form.Item>
                    </Tooltip>
                );
            })}
        </Form>
    );
};

export default FormComponent;
