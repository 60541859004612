import React from 'react';
import { observer } from 'mobx-react';
import styles from '../../AuthFormContainer.module.scss';
import loginStyles from '../Login/Login.module.scss';
import { FISU, U2023 } from '../../../../components/Icons/Icons';
import { Button, Spin, Form } from 'antd';
import AuthFooter from '../AuthFooter/AuthFooter';
import { useStores } from '../../../../store';
import getLanguageContent from '../../../../content';

import EmailSend from './EmailSend';
import PasswordSend from './PasswordSend';
import { useParams } from 'react-router';

const PasswordReset = () => {
    const { authStore, configStore } = useStores();

    const params = useParams() as any;

    return (
        <div className={styles.layout}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <U2023 className={styles.header__left} />
                    <FISU className={styles.header__right} />
                </div>
                <div className={styles.content__logo}>
                    <p>SPECTRUM</p>
                    <p>order portal</p>
                </div>
                <div className={styles.formContainer}>
                    <Spin spinning={authStore.loading}>
                        <Form>{params.uuid ? <PasswordSend /> : <EmailSend />}</Form>
                    </Spin>
                </div>
            </div>
            <AuthFooter />
        </div>
    );
};

export default observer(PasswordReset);
