export default {
    title: 'Журнал',
    inputs: {
        filter: 'Просмотр',
        startDate: 'Старт даты',
        endDate: 'Конец даты',
    },
    table: {
        header: {
            ctime: 'Дата \\ время',
            username: 'Пользователь',
            fio: 'ФИО',
            email: 'E-mail регистрирующегося',
            login: 'Логин',
            event: 'Событие',
            rq_id: 'ID заявки',
            status: 'Статус',
        },
    },
    level: 'Тип вывода',
};
