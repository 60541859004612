export default {
    title: 'Управление заявками',
    status: 'Статус',
    buttons: {
        create: 'Создать',
        copy: 'Копия',
        edit: 'Открыть',
        delete: 'Удалить',
        cancel: 'Отменить',
        actions: 'Действия',
        export: 'Экспорт',
        selectAll: 'Выделить всё',
    },
    export: {
        xls: 'excel',
        csv: 'csv',
        list: 'list',
        full: 'full',
    },
    inputs: {
        filter: 'Просмотр',
        startDate: 'Старт даты',
        endDate: 'Конец даты',
    },
    table: {
        header: {
            request: '№ Заявки',
            customer: 'Организация',
            period: 'Период',
            status: 'Статус',
            licence: 'Лицензия',
            owner: 'Заявитель',
            created: 'Создано',
            description: 'Описание',
        },
    },
    attach: {
        title: 'Загруженные файлы',
        buttons: {
            save: 'Сохранить',
            cancel: 'Отмена',
            addFile: 'Добавить файл лицензии',
        },
    },
    modal: {
        delete: 'Вы действительно хотите удалить заявку?',
        revoke: 'Вы действительно хотите отозвать заявку?',
        document: 'Вы действительно хотите удалить прикреплённый докумен?',
        service: 'Вы действительно хотите удалить сервис?',
        status: 'Вы действительно хотите изменить статус заявки?',
        buttons: {
            yes: 'Да',
            no: 'Нет',
        },
    },
};
