import React from 'react';
import { HTMLAttributes } from 'react';
import { ReactComponent as authBg } from './svgs/authBg.svg';
import { ReactComponent as close } from './svgs/close.svg';
import { ReactComponent as arrow } from './svgs/arrow.svg';
import { ReactComponent as attach } from './svgs/attach.svg';
import { ReactComponent as pdf } from './svgs/pdf.svg';
import { ReactComponent as exit } from './svgs/exit.svg';
import { ReactComponent as logo } from './svgs/logo.svg';
import { ReactComponent as fisu } from './svgs/logo-FISU.svg';
import { ReactComponent as u2023 } from './svgs/logo-U2023.svg';
import { ReactComponent as language } from './svgs/language.svg';
import Icon from '@ant-design/icons';

export const AuthBgIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={authBg} {...props} />
);
export const ExitIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={exit} {...props} />
);
export const CloseIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={close} {...props} />
);
export const ArrowIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={arrow} {...props} />
);
export const AttachIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={attach} {...props} className={'unfilled'} />
);
export const PDFIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={pdf} {...props} className={'unfilled'} />
);
export const LogoIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={logo} {...props} />
);
export const FISU = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={fisu} {...props} />
);
export const U2023 = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={u2023} {...props} />
);
export const LanguageIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
    <Icon component={language} {...props} />
);
