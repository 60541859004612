import { makeAutoObservable, runInAction } from 'mobx';
import { DictionaryItem, DictionaryListItem } from '../service/models/dictionaryItem';
import { DictionaryService } from '../service/dictionaryService';
import { NotificationStore } from './notificationStore';
import {
    parseRulesDictionary,
    parseRulesTooltipDictionary,
} from '../pages/ApplicationManagement/utils/parseDictionaryItem';

const successStatuses = [200, 201, 202, 203, 204];

export class DictionaryStore {
    dictionariesLoaded = false;
    constructor(
        private readonly dictionaryService: DictionaryService,
        private readonly notificationStore: NotificationStore,
    ) {
        makeAutoObservable(this);
        this.dictionaryService = dictionaryService;
    }

    functionalArea: Array<DictionaryItem> = [];
    userGroup: Array<DictionaryItem> = [];
    events: Array<DictionaryItem> = [];
    periods: Array<DictionaryItem> = [];
    organizations: Array<DictionaryItem> = [];
    services: Array<DictionaryItem> = [];
    objects: Array<DictionaryItem> = [];
    standarts: Array<DictionaryItem> = [];
    typesOfUse: Array<DictionaryItem> = [];
    typeTune: Array<DictionaryItem> = [];
    statuses: Array<DictionaryItem> = [];
    messageTemplates: Array<DictionaryItem> = [];
    city: Array<DictionaryItem> = [];

    dictionaries: any = {};
    rules: any = {};
    tooltips: any = {};

    getAuthDictionaries = async () => {
        const event = await this.dictionaryService.getDictionariesCode();
        const codes: DictionaryListItem = event.data;

        const responses = await Promise.all([
            this.dictionaryService.getDictionary(codes['Functional Directions']),
            this.dictionaryService.getDictionary(codes.Groups),
            this.dictionaryService.getDictionary(codes.Organisation),
            this.dictionaryService.getDictionary(codes.Rules),
        ]);

        const functionalArea = responses[0];
        const userGroup = responses[1];
        const organizations = responses[2];
        const rules = responses[3];

        if (!successStatuses.includes(functionalArea.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Функциональное направление": ${functionalArea.statusText}`,
            );
        } else {
            this.functionalArea = functionalArea.data ? functionalArea.data : [];
        }

        if (!successStatuses.includes(userGroup.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Группа участников": ${userGroup.statusText}`,
            );
        } else {
            this.userGroup = userGroup.data ? userGroup.data : [];
        }

        if (!successStatuses.includes(organizations.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Организация": ${organizations.statusText}`,
            );
        } else {
            this.organizations = organizations.data ? organizations.data : [];
        }

        if (!successStatuses.includes(rules.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Правила валидации": ${rules.statusText}`,
            );
        } else {
            this.rules = parseRulesDictionary(rules.data ? rules.data : []);
            this.tooltips = parseRulesTooltipDictionary(rules.data ? rules.data : []);
        }

        runInAction(() => {
            this.dictionariesLoaded = true;
        });
    };

    getRulesDictionary = async () => {
        const event = await this.dictionaryService.getDictionariesCode();
        const codes: DictionaryListItem = event.data;

        const responses = await Promise.all([this.dictionaryService.getDictionary(codes.Rules)]);

        const rules = responses[0];

        if (!successStatuses.includes(rules.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Правила валидации": ${rules.statusText}`,
            );
        } else {
            this.rules = parseRulesDictionary(rules.data ? rules.data : []);
            this.tooltips = parseRulesTooltipDictionary(rules.data ? rules.data : []);
        }

        runInAction(() => {
            this.dictionariesLoaded = true;
        });
    };

    getAppManagementDictionaries = async () => {
        const event = await this.dictionaryService.getDictionariesCode();
        const codes: DictionaryListItem = event.data;

        const responses = await Promise.all([
            this.dictionaryService.getDictionary(codes.Events),
            this.dictionaryService.getDictionary(codes.Periods),
            this.dictionaryService.getDictionary(codes.Organisation),
            this.dictionaryService.getDictionary(codes.Services),
            this.dictionaryService.getDictionary(codes.Objects),
            this.dictionaryService.getDictionary(codes['Wi-Fi standarts']),
            this.dictionaryService.getDictionary(codes['Type of use']),
            this.dictionaryService.getDictionary(codes['Type of channel tuning']),
            this.dictionaryService.getDictionary(codes.Statuses),
            this.dictionaryService.getDictionary(codes['Message Templates']),
            this.dictionaryService.getDictionary(codes['City']),
        ]);

        const events = responses[0];
        const periods = responses[1];
        const organizations = responses[2];
        const services = responses[3];
        const objects = responses[4];
        const standarts = responses[5];
        const typesOfUse = responses[6];
        const typeTune = responses[7];
        const actions = responses[8];
        const messageTemplates = responses[9];
        const city = responses[10];

        if (!successStatuses.includes(events.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Мероприятие": ${events.statusText}`,
            );
        } else {
            this.events = events.data ? events.data : [];
        }

        if (!successStatuses.includes(periods.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Период": ${periods.statusText}`,
            );
        } else {
            this.periods = periods.data ? periods.data : [];
        }

        if (!successStatuses.includes(organizations.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Организация": ${organizations.statusText}`,
            );
        } else {
            this.organizations = organizations.data ? organizations.data : [];
        }

        if (!successStatuses.includes(services.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Организация": ${services.statusText}`,
            );
        } else {
            this.services = services.data ? services.data : [];
        }

        if (!successStatuses.includes(objects.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Организация": ${objects.statusText}`,
            );
        } else {
            this.objects = objects.data ? objects.data : [];
        }

        if (!successStatuses.includes(standarts.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Организация": ${standarts.statusText}`,
            );
        } else {
            this.standarts = standarts.data ? standarts.data : [];
        }

        if (!successStatuses.includes(typesOfUse.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Типы использования": ${typesOfUse.statusText}`,
            );
        } else {
            this.typesOfUse = typesOfUse.data ? typesOfUse.data : [];
        }

        if (!successStatuses.includes(typeTune.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Фиксированные частоты РЭС": ${typeTune.statusText}`,
            );
        } else {
            this.typeTune = typeTune.data ? typeTune.data : [];
        }

        if (!successStatuses.includes(actions.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Статусы": ${actions.statusText}`,
            );
        } else {
            this.statuses = actions.data ? actions.data : [];
        }

        if (!successStatuses.includes(messageTemplates.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Шаблоны сообщений": ${messageTemplates.statusText}`,
            );
        } else {
            this.messageTemplates = messageTemplates.data ? messageTemplates.data : [];
        }

        if (!successStatuses.includes(messageTemplates.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Шаблоны сообщений": ${messageTemplates.statusText}`,
            );
        } else {
            this.city = city.data ? city.data : [];
        }

        runInAction(() => {
            this.dictionariesLoaded = true;
        });
    };

    getReferencesDictionaries = async () => {
        const event = await this.dictionaryService.getDictionariesCode();
        const codes: any = event.data;

        const responses = await Promise.all(
            Object.keys(codes).map(name => this.dictionaryService.getDictionary(codes[name])),
        );

        responses.forEach((response: any, index: number) => {
            const name = Object.keys(codes).map(name => name)[index];

            if (!successStatuses.includes(response.status)) {
                this.notificationStore.setErrorMessage(
                    `Ошибка загрузки справочника "${name}": ${response.statusText}`,
                );
            } else {
                this.dictionaries[name] = response.data ? response.data : [];
            }
        });

        runInAction(() => {
            this.dictionariesLoaded = true;
        });
    };

    getNotificationsTemplate = async () => {
        const event = await this.dictionaryService.getDictionariesCode();
        const codes: DictionaryListItem = event.data;

        const responses = await Promise.all([
            this.dictionaryService.getDictionary(codes['Message Templates']),
        ]);

        const messageTemplates = responses[0];

        if (!successStatuses.includes(messageTemplates.status)) {
            this.notificationStore.setErrorMessage(
                `Ошибка загрузки справочника "Шаблоны сообщений": ${messageTemplates.statusText}`,
            );
        } else {
            this.messageTemplates = messageTemplates.data;
        }

        runInAction(() => {
            this.dictionariesLoaded = true;
        });
    };
}
