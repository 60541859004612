import { FormFieldsInterface } from '../../../../components/FormComponent/FormComponent';
import { ContentType } from '../../../../content';

export const getLoginFormFields = (content: ContentType): Array<FormFieldsInterface> => {
    return [
        {
            key: 'username',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.loginFields.username,
                required: true,
            },
        },
        {
            key: 'password',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.loginFields.password,
                required: true,
            },
        },
    ];
};
