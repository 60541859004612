import axios, { AxiosResponse } from 'axios';
import ConfigurationStore from '../store/configurationStore';
import urljoin from 'url-join';
import { ApplicationManagementItem, RQLineItem } from './models/applicationManagement';
import {
    NewRequestData,
    NewRequestDataResponse,
    NewServiceData,
    EditRequestData,
} from './models/applicationManagement';
import { AxiosMethod, requestTemplate, fileDownload } from './helper';
import moment from 'moment';

import { logOut } from '../utils/auth';

interface ApplicationManagementServiceInterface {
    getList: () => Promise<AxiosResponse<ApplicationManagementItem[]>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class ApplicationManagementService implements ApplicationManagementServiceInterface {
    appManUrl = '';
    reportUrl = '';
    apiUrl = '';

    constructor(configStore: ConfigurationStore) {
        this.appManUrl = configStore.appConfig.appManUrl;
        this.reportUrl = configStore.appConfig.reportUrl;
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    private axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url, '/'), config);
    };

    getList = async (): Promise<AxiosResponse<ApplicationManagementItem[]>> => {
        return await this.axiosGet(this.appManUrl);
    };

    getListFile = async (date: any) => {
        await fileDownload(
            urljoin(this.appManUrl, '/'),
            'applications.xlsx',
            sessionStorage.getItem('access_token'),
            {
                cdate_from:
                    date && date.begin ? moment(date.begin).format('YYYY-MM-DD[T]00:00:00') : null,
                cdate_to:
                    date && date.end ? moment(date.end).format('YYYY-MM-DD[T]23:59:59') : null,
                to_import: 'xls',
            },
        );
    };

    getCSVListFile = async (date: any) => {
        await fileDownload(
            urljoin(this.reportUrl, '/'),
            'applications.csv',
            sessionStorage.getItem('access_token'),
            {
                cdate_from:
                    date && date.begin ? moment(date.begin).format('YYYY-MM-DD[T]00:00:00') : null,
                cdate_to:
                    date && date.end ? moment(date.end).format('YYYY-MM-DD[T]23:59:59') : null,
                to_import: 'csv',
            },
        );
    };

    getDocument = async (file_id: number, name: string) => {
        await fileDownload(
            urljoin(this.apiUrl, `/rq/rq/get_file_content/?file_id=${file_id}`),
            name,
            sessionStorage.getItem('access_token'),
        );
    };

    uploadDocument = async (file: any, rq_id: number): Promise<AxiosResponse> => {
        return await requestTemplate<any, any>(
            urljoin(this.apiUrl, '/rq/rq/file_upload'),
            AxiosMethod.post,
            file,
            sessionStorage.getItem('access_token'),
            { rq_id },
        );
    };

    deleteDocument = async (rq_id: number, file_id: number): Promise<AxiosResponse> => {
        return await this.axiosGet(urljoin(this.apiUrl, '/rq/rq/file_delete'), { rq_id, file_id });
    };

    createRequest = async (
        newRequest: NewRequestData,
    ): Promise<AxiosResponse<NewRequestDataResponse>> => {
        return await requestTemplate<NewRequestData, NewRequestDataResponse>(
            urljoin(this.apiUrl, '/rq/rq/create'),
            AxiosMethod.post,
            newRequest,
            sessionStorage.getItem('access_token'),
        );
    };

    updateRequest = async (
        id: number,
        newRequest: EditRequestData,
    ): Promise<AxiosResponse<EditRequestData>> => {
        return await requestTemplate<EditRequestData, EditRequestData>(
            urljoin(this.apiUrl, `/rq/rq/update/${Number(id).toString()}`),
            AxiosMethod.put,
            newRequest,
            sessionStorage.getItem('access_token'),
        );
    };

    copyRequest = async (rq_id: number): Promise<AxiosResponse> => {
        return await this.axiosGet(urljoin(this.apiUrl, '/rq/rq/create_by'), { rq_id });
    };

    getRq = async (id: number): Promise<AxiosResponse<RQLineItem>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/rq/rq/${Number(id).toString()}`));
    };

    rqActions = async (id: number, status: any): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(
            urljoin(this.apiUrl, `/rq/rq/status/${Number(id).toString()}`),
            status,
        );
    };

    getFt = async (id: number): Promise<AxiosResponse<ApplicationManagementItem[]>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/cls/get/by_id/${Number(id).toString()}`));
    };

    getStatus = async (id: number): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/cls/get/2088/status/${id}`));
    };

    createService = async (
        ft: string,
        newService: NewServiceData,
    ): Promise<AxiosResponse<NewServiceData>> => {
        return await requestTemplate<NewServiceData, NewServiceData>(
            urljoin(this.apiUrl, `/rq_line/rq_line/${ft}/create`),
            AxiosMethod.post,
            newService,
            sessionStorage.getItem('access_token'),
        );
    };

    updateService = async (
        id: number,
        ft: string,
        newService: NewServiceData,
    ): Promise<AxiosResponse<NewServiceData>> => {
        return await requestTemplate<NewServiceData, NewServiceData>(
            urljoin(this.apiUrl, `/rq_line/rq_line/${ft}/${id}`),
            AxiosMethod.put,
            newService,
            sessionStorage.getItem('access_token'),
        );
    };

    deleteService = async (ft: string, id: number): Promise<AxiosResponse<any>> => {
        return await axios.delete(urljoin(this.apiUrl, `/rq_line/rq_line/${ft}/${id}/`), {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
        });
    };

    getRqExportFile = async (rq_id: number, filename = `application-${rq_id}`) => {
        await fileDownload(
            urljoin(this.apiUrl, `/report/rq/${rq_id}/`),
            `${filename}.xlsx`,
            sessionStorage.getItem('access_token'),
        );
    };
}

export default ApplicationManagementServiceInterface;
