import { makeAutoObservable } from 'mobx';
import { Languages } from '../content';

export interface AppConfig {
    baseUrl: string;
    apiUrl: string;
    dictionariesUrl: string;
    appManUrl: string;
    reportUrl: string;
}

const isDev = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

class ConfigurationStore {
    configLoaded = true;
    lang: Languages = Languages.RU;
    currentPage = localStorage.getItem('app_menu');
    appConfig: AppConfig = {
        baseUrl: '/',
        apiUrl: isDev() ? 'http://spectrum.selectel.rd-science.com/api' : '/api',
        dictionariesUrl: isDev()
            ? 'http://spectrum.selectel.rd-science.com/api/cls/get/list'
            : '/api/cls/get/list',
        appManUrl: isDev()
            ? 'http://spectrum.selectel.rd-science.com/api/rq/rq/list'
            : '/api/rq/rq/list',
        reportUrl: isDev()
            ? 'http://spectrum.selectel.rd-science.com/api/report/data_csv'
            : '/api/report/data_csv/',
    };

    constructor() {
        makeAutoObservable(this);
    }

    changeTitle = () => {
        this.lang === Languages.RU
            ? (document.title = 'Спектрум-портал')
            : (document.title = 'Spectrum-portal');
    };

    changeLanguage = (lang: Languages) => {
        this.lang = lang;
        this.changeTitle();
    };

    toggleLanguage() {
        if (this.lang === Languages.RU) this.lang = Languages.EN;
        else this.lang = Languages.RU;
        this.changeTitle();
    }

    setCurrentPage(page: string) {
        this.currentPage = page;
    }

    get getCurrentLanguage() {
        return this.lang;
    }

    async init(runStoresCallback: (appConfig: AppConfig) => void) {
        runStoresCallback(this.appConfig);
    }
}

export default ConfigurationStore;
