import Workplace from '../../assets/images/Icons/Workplace.svg';
import ApplicationManagement from '../../assets/images/Icons/ApplicationManagement.svg';
import ReportsAndStatistics from '../../assets/images/Icons/ReportsAndStatistics.svg';
import HelpAndSupport from '../../assets/images/Icons/HelpAndSupport.svg';
import Settings from '../../assets/images/Icons/Settings.svg';
import Language from '../../assets/images/Icons/Language.svg';

const sidebar_content_ru: sidebar_content_type = {
    Workplace: {
        id: 'Workplace',
        title: 'Рабочий стол',
        icon: Workplace,
        menuItemType: 'menuItem',
    },
    ApplicationManagement: {
        id: 'ApplicationManagement',
        title: 'Управление Заявками',
        icon: ApplicationManagement,
        menuItemType: 'menuItem',
    },
    ReportsAndStatistics: {
        id: 'ReportsAndStatistics',
        title: 'Отчёты и Cтатистика',
        icon: ReportsAndStatistics,
        menuItemType: 'menuItem',
    },
    HelpAndSupport: {
        id: 'HelpAndSupport',

        title: 'Помощь и Поддержка',
        icon: HelpAndSupport,
        menuItemType: 'menuItem',
    },
    Settings: {
        id: 'Settings',

        title: 'Настройки',
        icon: Settings,
        menuItemType: 'submenu',
        submenuItems: [
            {
                id: 'UserAccounts',
                title: 'Учетные записи',
            },
            {
                id: 'Notifications',
                title: 'Уведомления',
            },
            {
                id: 'Monitoring',
                title: 'Мониторинг',
            },
            {
                id: 'Journal',
                title: 'Журнал',
            },
            {
                id: 'References',
                title: 'Справочники',
            },
        ],
    },

    Language: {
        id: 'Language',
        title: 'English',
        icon: Language,
        menuItemType: 'menuItem',
    },
};

export default sidebar_content_ru;
