import React from 'react';
import moment from 'moment';

export default (lang: string, header: any) => [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: header.ctime,
        dataIndex: 'ctime',
        key: 'ctime',
        render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
    },
    {
        title: header.source,
        dataIndex: 'source',
        key: 'source',
    },
    {
        title: header.level,
        dataIndex: 'level',
        key: 'level',
    },
];
