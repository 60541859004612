import { DictionaryItem } from '../../../service/models/dictionaryItem';
import { Languages } from '../../../content';

export const parseDictionaryItemName = (DI: DictionaryItem[], lang: Languages) => {
    return DI.map(item => ({
        value: item.id,
        label: JSON.parse(item.name as string)[lang],
        is_hidden: item.is_hidden,
    }));
};

export const parseDictionaryItemAttrib = (DI: DictionaryItem[], lang: Languages) => {
    return DI.map(item => ({
        value: item.id,
        label: JSON.parse(item.attrib as string)[lang],
        is_hidden: item.is_hidden,
    }));
};

export const parseItem = (item: string, lang: string) => {
    const name = item ? JSON.parse(item).name : item;
    return name ? name[lang] : name;
};

export const parseItemName = (item: string, lang: string) => {
    const name = item ? JSON.parse(item) : item;
    return name ? name[lang] : name;
};

export const parseItemCode = (item: string) => {
    const attrib = item ? JSON.parse(item) : item;
    return attrib ? attrib.code : attrib;
};

export const parseRulesDictionary = (DI: DictionaryItem[]) => {
    let rules: any = {};

    DI.map(item => {
        try {
            const attrib = JSON.parse(item.attrib as string);
            const rule = new RegExp(attrib.regexp);

            rules[attrib.code as string] = rule;
        } catch (err: any) {
            console.error(err.messasge);
        }
    });

    return rules;
};

export const parseRulesTooltipDictionary = (DI: DictionaryItem[]) => {
    let tooltips: any = {};

    DI.map(item => {
        try {
            const descr = JSON.parse(item.descr as string);
            const attrib = JSON.parse(item.attrib as string);

            tooltips[attrib.code as string] = descr;
        } catch (err: any) {
            console.error(err.messasge);
        }
    });

    return tooltips;
};

export const parseRqStatus = (item: any) => {
    return item ? JSON.parse(item.attrib) : item;
};
