export default {
    title: 'Log',
    inputs: {
        filter: 'View',
        startDate: 'Start date',
        endDate: 'End date',
    },
    table: {
        header: {
            ctime: 'Date',
            username: 'User',
            fio: 'ФИО',
            email: 'Registered e-mail',
            login: 'Login',
            event: 'Event',
            rq_id: 'Request ID',
            status: 'Status',
        },
    },
    level: 'Show type',
};
