import React from 'react';
import FormComponent from '../../../../components/FormComponent/FormComponent';
import { getLoginFormFields } from './LoginFormFIelds';
import { Button, Form } from 'antd';
import styles from './Login.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store';
import { LoginUser } from '../../../../service/models/auth';
import getLanguageContent from '../../../../content';
import { useHistory } from 'react-router';

const Login = () => {
    const { authStore, configStore } = useStores();
    const [form] = Form.useForm();
    const content = getLanguageContent(configStore.lang);
    const history = useHistory();

    const onSubmitForm = () => {
        form.validateFields().then(async (values: LoginUser) => {
            const response = await authStore.fetchLogin(values);

            if (response.status === 200) {
                authStore.setUsername(response.data.user_id);
                authStore.setAdmin(response.data.is_admin);
                history.push('/');
            }
        });
    };

    return (
        <div
            className={styles.formContainer}
            onKeyDown={e => {
                e.key === 'Enter' && onSubmitForm();
            }}
        >
            <FormComponent
                formName="Login"
                formInstance={form}
                formFields={getLoginFormFields(content)}
            />
            <Button
                htmlType="submit"
                className="btn btn--w100 btn-blue"
                style={{ marginTop: 40 }}
                onClick={onSubmitForm}
            >
                {content.auth.buttons.login}
            </Button>
            <a href="/password/reset" className={styles.textRestore}>
                {content.auth.buttons.restorePassword}
            </a>
        </div>
    );
};

export default observer(Login);
