import ConfigurationStore from '../../store/configurationStore';

const useSubmenuSettings = (history: any, configStore: ConfigurationStore) => {
    const onClickAccounts = () => {
        console.log('onClickAccounts');
        localStorage.setItem('app_menu', 'Users');
        configStore.setCurrentPage('Users');
        history.push('/users/');
    };

    const onClickNotifications = () => {
        console.log('onClickNotifications');
        localStorage.setItem('app_menu', 'Notifications');
        configStore.setCurrentPage('Notifications');
        history.push('/notifications/');
    };

    const onClickMonitoring = () => {
        console.log('onClickMonitoring');
        localStorage.setItem('app_menu', 'Monitoring');
        configStore.setCurrentPage('Monitoring');
        history.push('/monitoring/');
    };

    const onClickJournal = () => {
        console.log('onClickJournal');
        localStorage.setItem('app_menu', 'Journal');
        configStore.setCurrentPage('Journal');
        history.push('/journal/');
    };

    const onClickReferences = () => {
        console.log('onClickReferences');
        localStorage.setItem('app_menu', 'References');
        configStore.setCurrentPage('References');
        history.push('/references/');
    };

    return [
        onClickAccounts,
        onClickNotifications,
        onClickMonitoring,
        onClickJournal,
        onClickReferences,
    ];
};

export default useSubmenuSettings;
