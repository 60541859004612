export default {
    title: 'Уведомления',
    fields: {
        templates: 'Шаблоны уведомлений',
        sla: 'Параметры SLA',
    },
    placeholders: {
        type: 'Тип уведомлений',
        information_ru: 'Текст на русском языке',
        information_en: 'Текст на английском языке',
        approvalDays: 'Нормативный срок утверждения заявки, дней',
        approvalNotification: 'Уведомлять о сроке утверждения за, дней',
        notificationInterval: 'Периодичность уведомления о просроченных заявках, дней',
    },
    modal: {
        buttons: {
            yes: 'Да',
            no: 'Нет',
        },
    },
    logoutNotification: 'Внимание! Через 10 минут истекает время авторизации',
    errorTitle: 'Произошла ошибка',
    informationTitle: 'Информация',
};
