export default {
    title: 'Учётные записи',
    buttons: {
        create: 'Создать',
        copy: 'Копия',
        edit: 'Открыть',
        delete: 'Удалить',
        cancel: 'Отменить',
        actions: 'Действия',
        export: 'Экспорт',
    },
    table: {
        header: {
            id: 'ID',
            username: 'Логин',
            name: 'ФИО',
            role: 'Роль',
            status: 'Статус',
            company: 'Организация',
            position: 'Должность',
            actions: 'Действия',
            email: 'e-mail подтверждён',
            active: 'Активен',
        },
        buttons: {
            activate: 'Активировать',
            deactivate: 'Деактивировать',
        },
        variables: {
            admin: 'Администратор',
            consumer: 'Заявитель',
            active: 'Активирован',
            inactive: 'Деактивирован',
        },
        modal: {
            admin: (user: string, role: boolean) =>
                `Вы подтверждаете изменение роли для пользователя ${user} на ${
                    role ? 'Администратор' : 'Заявитель'
                }?`,
            activate: (user: string, activate: boolean) =>
                `Вы подтверждаете ${activate ? 'активацию' : 'деактивацию'} пользователя ${user}?`,
            buttons: {
                yes: 'Да',
                no: 'Нет',
            },
        },
    },
    editUser: {
        titleEdit: 'Редактирование пользователя',
        titleNew: 'Создание нового пользователя',
        id: 'ID пользователя',
        username: 'Имя пользователя',
        full_name_ru: 'ФИО на Русском языке',
        full_name_en: 'ФИО на Английском языке',
        is_admin: 'Администратор',
        is_active: 'Активирован',
        func_area: 'Функциональное направление',
        email: 'e-mail',
        email_checked: 'e-mail подтверждён',
        email_unchecked: 'e-mail не подтверждён',
        company_str: 'Наименование организации',
        company: 'Организация',
        usergroup: 'Пользовательская группа',
        position: 'Должность',
        phone_num: 'Номер телефона',
    },
    editPassword: {
        title: 'Смена пароля',
        button: 'Смена пароля',
        newPass: 'Новый пароль',
    },
};
