import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { MonitoringService } from '../service/monitoringService';
import { ErrorStore } from './errorStore';

export class MonitoringStore {
    loading = false;
    level = '';
    page = 1;
    totalPages = 1;
    pageSize = 15;
    messId: number | boolean = false;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly monitoringService: MonitoringService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.monitoringService = monitoringService;
    }

    list: Array<any> = [];
    beginDate: any;
    endDate: any;

    setLevel = (level: string) => {
        this.level = level;
    };

    setBeginDate = (date: any) => {
        this.beginDate = date;
    };

    setEndDate = (date: any) => {
        this.endDate = date;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setMess = (mess: number | boolean) => {
        this.messId = mess;
    };

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async () => {
        try {
            return await this.fetchWrapper(
                () =>
                    this.monitoringService.getList(
                        this.page - 1,
                        this.pageSize,
                        this.level,
                        this.beginDate,
                        this.endDate,
                    ),
                (result: any) => {
                    this.list = result.data.data.map((row: any) => {
                        row.mess = JSON.parse(row.mess);
                        return row;
                    });
                    this.totalPages = result.data.pages;
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
