export default {
    title: 'Справочники',
    header: {
        enName: 'Наименование (англ.)',
        ruName: 'Наименование (рус.)',
        enDescr: 'Описание (англ.)',
        ruDescr: 'Описание (рус.)',
    },
    validate: {
        invalid: 'Значение не соответствует шаблону',
    },
    buttons: {
        create: 'Создать',
        save: 'Сохранить',
        close: 'Заблокировать',
        lock: 'Заблокировать',
        unlock: 'Разблокировать',
        archive: 'В архив',
        xls: 'xls-файл',
        pdf: 'pdf-файл',
    },
    modal: {
        close: 'Вы действительно хотите архивировать запись?',
        buttons: {
            yes: 'Да',
            no: 'Нет',
        },
    },
};
