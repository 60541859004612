import React, { useState } from 'react';

import { Select, Switch, Input as AntInput, Form, Button, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ArrowIcon } from '../Icons/Icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import cs from '../../utils/composeStyles';

import './style.scss';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

export const Input = (props: any) => {
    const [isActive, setIsActive] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');

    const { suffix } = props;

    if ((props.value || props.value === 0 || props.replace) && !isActive) setIsActive(true);

    function validate(text: string) {
        return props.pattern ? (props.pattern.test(text) ? text : props.value) : text;
    }

    function handleTextChange(text: string) {
        const output = validate(text);

        setValue(output);
        props.onChange(output);

        if (output !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }

    function handleFocusChange(focus: boolean) {
        setIsFocused(focus);
        if (focus && props.replace && !props.value) props.onChange('');
    }

    return (
        <div id="float-label" className={props.disabled ? 'disabled' : ''}>
            <input
                {...props}
                value={!isFocused && props.replace ? props.replace(props.value) : props.value}
                placeholder={''}
                type={props.type ? props.type : 'text'}
                className={isFocused ? 'Focused' : ''}
                onChange={e => handleTextChange(e.target.value)}
                onFocus={() => handleFocusChange(true)}
                onBlur={() => {
                    props.onBlur && props.onBlur();
                    handleFocusChange(false);
                }}
                disabled={props.disabled}
            />

            {suffix ? <div className={'suffix'}>{suffix}</div> : null}

            <label className={isActive ? 'Active' : ''}>{props.placeholder}</label>
        </div>
    );
};

export const TextArea = (props: any) => {
    const [isActive, setIsActive] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');

    if (props.value && !isActive) setIsActive(true);

    function handleTextChange(text: string) {
        setValue(text);
        props.onChange(text);

        if (text !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }

    function handleFocusChange(focus: boolean) {
        setIsFocused(focus);
    }

    return (
        <div id="float-label" className={props.disabled ? 'disabled' : ''}>
            <textarea
                {...props}
                placeholder={''}
                style={{ ...{ height: 112 }, ...props.style }}
                className={isFocused ? 'Focused' : ''}
                onChange={(e: any) => handleTextChange(e.target.value)}
                onFocus={() => handleFocusChange(true)}
                onBlur={() => handleFocusChange(false)}
                disabled={props.disabled}
            />

            <label className={isActive ? 'Active' : ''}>{props.placeholder}</label>
        </div>
    );
};

export const Selection = (props: any) => {
    const { configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [isActive, setIsActive] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState<any[]>(props.value);

    if ((props.value || props.value === 0) && !isActive) setIsActive(true);

    function handleTextChange(value: any) {
        setValue(value);
        props.onChange(value);

        if (value !== '' || value !== null) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }

    function handleFocusChange(focus: boolean) {
        setIsFocused(focus);
    }

    const CustomButton = () => (
        <Button
            className={`selectAllButton btn btn--w100 btn-blue${
                value?.length === props.children?.length ? ':disabled' : ''
            }`}
            onClick={() => {
                if (value?.length !== props.children?.length) {
                    let result: any[] = [];
                    props.children.map((item: any) => {
                        result.push(item.props.value);
                    });

                    setValue(result);
                    props.onSelectAll(result);
                }
            }}
            disabled={value?.length === props.children.length}
        >
            <CheckOutlined />
        </Button>
    );

    return (
        <div
            id="float-label"
            className={props.disabled ? 'disabled' : ''}
            style={{ flexDirection: props.selectAll ? 'row' : 'column' }}
        >
            <Select
                {...props}
                className={cs(
                    isFocused ? 'Focused' : '',
                    props.multiline ? 'multiline' : '',
                    props.selectAll ? 'selectAll' : '',
                )}
                onChange={value => handleTextChange(value)}
                onFocus={() => handleFocusChange(true)}
                onBlur={() => handleFocusChange(false)}
                placeholder={''}
                value={value}
            />
            {props.showArrow && <ArrowIcon className={'arrow'} />}

            <label className={isActive ? 'Active' : ''}>{props.placeholder}</label>
            {props.selectAll && props.onSelectAll && (
                <Tooltip title={content.appManagement.buttons.selectAll}>
                    <CustomButton />
                </Tooltip>
            )}
        </div>
    );
};

export const Switcher = (props: any) => {
    return (
        <div className={'switcher'}>
            <Switch {...props} />
            <span>{props.title}</span>
        </div>
    );
};

export const Password = (props: any) => {
    const [visible, setSVisible] = useState(false);

    return (
        <Input
            {...props}
            type={visible ? 'input' : 'password'}
            suffix={
                <div onClick={() => setSVisible(!visible)}>
                    {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                </div>
            }
        />
    );
};

export const SelectableInput = (props: any) => {
    const { field } = props;
    const { Option } = Select;

    const [value, setValue] = useState('');
    const [search, setSearch] = useState('');

    const inputRef = React.useRef<any>(null);

    return (
        <>
            {!!field.proposedData?.filter((item: any) =>
                item.label.toLowerCase().includes(search?.toLowerCase()),
            ).length ? (
                <>
                    <Select
                        {...props}
                        allowClear
                        autoFocus={!props.value}
                        className={field.classNameField}
                        placeholder={field.meta.placeholder}
                        showSearch
                        searchValue={search}
                        notFoundContent={null}
                        onSearch={searchVal => {
                            if (value !== search || search === '') {
                                setSearch(searchVal);
                                props.form.setFieldsValue({ company_str: searchVal });
                            } else {
                                setValue('');
                            }
                        }}
                        onSelect={(v, o) => {
                            props.form.setFieldsValue({ company_str: o.children });
                        }}
                        filterOption={
                            field.meta.filterByOptionName
                                ? (inputValue, option) =>
                                      option?.children
                                          .toLowerCase()
                                          .includes(inputValue.toLowerCase())
                                : true
                        }
                        onFocus={() => {
                            inputRef.current?.focus({
                                cursor: 'end',
                            });
                        }}
                    >
                        {field.proposedData?.map((item: any) => (
                            <Option value={item.value} key={`${field.key}_proposedData`}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>

                    <Form.Item name={'company_str'} hidden />
                </>
            ) : (
                <Form.Item
                    name={'company_str'}
                    style={{ marginBottom: 0 }}
                    rules={(() => {
                        const rulesArr = [];
                        if (field.meta.required)
                            rulesArr.push({
                                required: field.meta.required,
                                message: props.content.auth.validate.required,
                            });
                        if (field.meta.validation) {
                            rulesArr.push({
                                validator: (
                                    rule: RuleObject,
                                    value: StoreValue,
                                    callback: (error?: string) => void,
                                ) =>
                                    value
                                        ? field.meta.validation &&
                                          field.meta.validation(
                                              rule,
                                              value,
                                              callback,
                                              props.form?.getFieldsValue(),
                                          )
                                        : Promise.resolve(),
                            });
                        }
                        return rulesArr;
                    })()}
                >
                    <AntInput
                        {...props}
                        autoFocus
                        ref={inputRef}
                        placeholder={field.meta.placeholder ?? field.title}
                        className={field.classNameField}
                        value={value}
                        onChange={e => {
                            setSearch(e.target.value);
                            setValue(e.target.value);
                            props.onChange(e);
                            props.form.setFieldsValue({ company_str: e.target.value });
                            props.form.setFieldsValue({ company: undefined });
                        }}
                        onFocus={() => {
                            inputRef.current?.focus({
                                cursor: 'end',
                            });
                        }}
                    />
                </Form.Item>
            )}
        </>
    );
};
