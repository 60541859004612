import React from 'react';
import styles from './Reports.module.scss';

const Paper = (props: any) => {
    const { title, number, good, poor } = props;

    return (
        <div className={styles.paper}>
            <span className={styles.title}>{title}</span>
            <span
                className={styles.number}
                style={{ color: good ? '#59A23F' : poor ? '#FF4557' : 'inherit' }}
            >
                {number}
            </span>
        </div>
    );
};

export default Paper;
