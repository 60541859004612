import React, { useEffect, useState } from 'react';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { Modal, Button, Row, Col, Select, Typography, Form, Tooltip, Tag } from 'antd';
import { Input, TextArea, Selection } from '../../../../components/Inputs/Inputs';

import styles from '../Request.module.scss';
import getLanguageContent from '../../../../content';
import { useStores } from '../../../../store';
import cs from '../../../../utils/composeStyles';

import { getNewServiceFields } from './ServiceFields';

import { parseItemName } from '../../utils/parseDictionaryItem';

const { Title } = Typography;

const Service = (props: any) => {
    const { applicationManagementStore, configStore, dictionaryStore } = useStores();
    const { services, objects, standarts, typesOfUse, typeTune, rules, tooltips } = dictionaryStore;
    const content = getLanguageContent(configStore.lang);

    const { open, rq_id, editable, initialValues, onClose } = props;

    const [form] = Form.useForm();

    const [ft, setFt] = useState('');
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typetun, setTypetun] = useState<null | number>(initialValues.TipeOfTuning);
    const [objectList, setObjectList] = useState<any[]>([]);

    const onChange = (value: number) => {
        setTypetun(value);
        form.validateFields();
    };

    useEffect(() => {
        if (initialValues.ft) {
            setFt(initialValues.ft);
        }
    });

    const getFt = (id: number) => {
        applicationManagementStore.fetchFt(id).then(response => {
            if (response.status === 200) {
                setFt('ft' + JSON.parse(response.data[0].attrib).ft);
            }
        });
    };

    const handleServiceSelect = (value: any) => {
        getFt(value);
    };

    const handleClose = () => {
        onClose();
        setFt('');
        form.resetFields();
    };

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            setLoading(true);

            if (values.PreferredReceiveFrequence && (ft === 'ft2' || ft === 'ft3'))
                values.PreferredReceiveFrequence = [values.PreferredReceiveFrequence];
            if (values.PreferredTransmitFrequence && (ft === 'ft2' || ft === 'ft3'))
                values.PreferredTransmitFrequence = [values.PreferredTransmitFrequence];

            if (!initialValues.ServiceID) {
                applicationManagementStore
                    .fetchNewService(ft, { ...values, ...{ RqID: rq_id } })
                    .then(response => {
                        if (response && response.status === 200) {
                            setChanged(false);
                            handleClose();
                        }
                    })
                    .catch(err => {
                        console.error(err.message);
                    })
                    .finally(() => setLoading(false));
            } else {
                let edited = {} as any;

                for (let key in values) {
                    if (values.hasOwnProperty(key) && form.isFieldTouched(key as string)) {
                        edited[key] = values[key];
                    }
                }

                applicationManagementStore
                    .fetchUpdateService(initialValues.id, ft, edited)
                    .then(response => {
                        if (response.status === 200) {
                            setChanged(false);
                            handleClose();
                        }
                    })
                    .catch(err => {
                        console.error(err.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
    };

    return (
        <Modal
            visible={open}
            width={'1080px'}
            onCancel={handleClose}
            footer={null}
            className={styles.modalWrapper}
        >
            <Form
                style={{ marginLeft: 40, marginRight: 40, paddingTop: 36, paddingBottom: 16 }}
                form={form}
                initialValues={initialValues}
                onFieldsChange={() => {
                    setChanged(true);
                }}
            >
                <Row gutter={[20, 8]}>
                    <Col span={12}>
                        <Form.Item
                            name="ServiceID"
                            className="form__item"
                            key="ServiceID"
                            rules={[
                                {
                                    required: true,
                                    message: content.newRequest.validate.required,
                                },
                            ]}
                        >
                            <Selection
                                className={cs('spectrumField', styles.fullWidthInput)}
                                placeholder={content.newRequest.modal.service}
                                showSearch
                                onChange={handleServiceSelect}
                                disabled={!!initialValues.ServiceID}
                            >
                                {services.map(service => (
                                    <Select.Option value={service.id}>
                                        {parseItemName(service.name, configStore.lang)}
                                    </Select.Option>
                                ))}
                            </Selection>
                        </Form.Item>
                    </Col>
                    {getNewServiceFields(
                        ft,
                        content,
                        services,
                        objects,
                        standarts,
                        typesOfUse,
                        typeTune,
                        rules,
                        tooltips,
                        configStore.lang,
                        typetun,
                        onChange,
                    ).map(field => {
                        let itemField: any = undefined;

                        if (field.meta.type === 'textarea') {
                            itemField = (
                                <TextArea
                                    disabled={!ft || !editable}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                />
                            );
                        } else {
                            itemField = (
                                <Input
                                    disabled={!ft || !editable}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    replace={field.meta.replace}
                                    pattern={field.meta.pattern}
                                />
                            );
                        }
                        if (field.meta.type === 'number') {
                            itemField = (
                                <Input
                                    type={'number'}
                                    disabled={!ft || !editable}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                        styles.numberInput,
                                    )}
                                    replace={field.meta.replace}
                                />
                            );
                        }
                        if (field.meta.type === 'label') {
                            itemField = (
                                <Title
                                    level={5}
                                    style={{ fontWeight: 400, fontSize: 14, position: 'absolute' }}
                                >
                                    {field.meta.placeholder}
                                </Title>
                            );
                        }
                        if (field.meta.type === 'select') {
                            itemField = (
                                <Selection
                                    disabled={!ft || !editable}
                                    showArrow={false}
                                    mode={field.key === 'ObjectID' ? 'multiple' : undefined}
                                    selectAll={field.key === 'ObjectID'}
                                    maxTagCount={field.key === 'ObjectID' ? 1 : 'responsive'}
                                    maxTagTextLength={36}
                                    allowClear
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    placeholder={field.meta.placeholder}
                                    showSearch
                                    onSelectAll={(result: any[]) => {
                                        setObjectList(result);
                                        form.setFields([
                                            {
                                                name: 'ObjectID',
                                                validating: true,
                                                errors: [],
                                                value: result,
                                                touched: true,
                                            },
                                        ]);
                                        setChanged(true);
                                    }}
                                    value={objectList}
                                    filterOption={
                                        field.meta.filterByOptionName
                                            ? (inputValue: any, option: any) =>
                                                  option?.children
                                                      .toLowerCase()
                                                      .includes(inputValue.toLowerCase())
                                            : true
                                    }
                                >
                                    {field.proposedData?.map((item, index) => (
                                        <Select.Option
                                            value={item.value}
                                            key={`${field.key}_${index}_proposedData`}
                                        >
                                            {item.is_hidden ? (
                                                <span style={{ color: '#ff4d4f' }}>
                                                    {item.label}
                                                </span>
                                            ) : (
                                                item.label
                                            )}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            );
                        }
                        if (field.meta.type === 'tagSelect') {
                            const tagRender = (props: any) => {
                                const { label, value, closable, onClose } = props;
                                const onPreventMouseDown = (event: any) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                };
                                return (
                                    <Tag
                                        color={rules['frequence'].test(value) ? 'default' : 'red'}
                                        onMouseDown={onPreventMouseDown}
                                        closable={closable}
                                        onClose={onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {label}
                                    </Tag>
                                );
                            };

                            itemField = (
                                <Selection
                                    multiline={true}
                                    open={false}
                                    showArrow={false}
                                    disabled={!ft || !editable}
                                    mode={'tags'}
                                    maxTagCount={8}
                                    maxTagTextLength={12}
                                    tagRender={tagRender}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    replace={field.meta.replace}
                                    pattern={field.meta.pattern}
                                />
                            );
                        }
                        return (
                            <Col span={field.meta.span}>
                                <Tooltip
                                    title={
                                        field.meta.tooltip
                                            ? field.meta.tooltip[configStore.lang]
                                            : null
                                    }
                                    trigger={['click', 'focus']}
                                >
                                    <Form.Item
                                        name={field.key}
                                        label={field.title}
                                        className="form__item"
                                        key={field.key}
                                        rules={(() => {
                                            const rulesArr = [];
                                            if (field.meta.required)
                                                rulesArr.push({
                                                    required: field.meta.required,
                                                    message: content.auth.validate.required,
                                                });
                                            if (field.meta.validation) {
                                                rulesArr.push({
                                                    validator: (
                                                        rule: RuleObject,
                                                        value: StoreValue,
                                                        callback: (error?: string) => void,
                                                    ) =>
                                                        value
                                                            ? field.meta.validation &&
                                                              field.meta.validation(
                                                                  rule,
                                                                  value,
                                                                  callback,
                                                                  //formInstance?.getFieldsValue()
                                                              )
                                                            : Promise.resolve(),
                                                });
                                            }
                                            return rulesArr;
                                        })()}
                                        {...field.formItemParams}
                                    >
                                        {itemField}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
            {editable && (
                <div className={styles.modalFooter}>
                    <Button
                        className="btn btn--w100 btn-blue"
                        onClick={onSubmitForm}
                        disabled={!changed || loading}
                    >
                        {content.newRequest.buttons.save}
                    </Button>
                </div>
            )}
        </Modal>
    );
};

export default Service;
