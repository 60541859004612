import axios, { AxiosResponse } from 'axios';
import urljoin from 'url-join';

import { logOut } from '../utils/auth';

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            if (sessionStorage.getItem('access_token')) logOut();
        }
        return error.response;
    },
);

export enum AxiosMethod {
    post = 'post',
    get = 'get',
    put = 'put',
}

export const requestTemplate = async <T, U>(
    url: string,
    method: AxiosMethod,
    data: T,
    token?: any,
    params?: any,
): Promise<AxiosResponse<U>> => {
    const config = {
        headers: token ? { Authorization: `Bearer ${token}` } : null,
        params,
    };

    try {
        return await axios[method](`${url}/`, data, config);
    } catch (e: any) {
        return {
            data: [] as unknown as U,
            status: 500,
            statusText: e.message,
            headers: {},
            config: {},
        };
    }
};

export const fileDownload = async (url: string, name: string, token?: any, params?: any) => {
    axios({
        url: urljoin(url),
        headers: token ? { Authorization: `Bearer ${token}` } : null,
        method: 'GET',
        responseType: 'blob',
        params,
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.error(err.message);
        });
};
