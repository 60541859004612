import { Col, Row, Form, Typography, Button, Tag } from 'antd';

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Support.module.scss';
import cs from '../../utils/composeStyles';
import getLanguageContent from '../../content';
import { useStores } from '../../store';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

import { Input, TextArea } from '../../components/Inputs/Inputs';
import { getSupportFields } from './SupportFields';

import AttachFile from './AttachFile/AttachFile';
import SupportFile from './SupportFile';
import { AttachIcon } from '../../components/Icons/Icons';

const { Title } = Typography;

const Support = (props: any) => {
    const { downloaded, files, setFiles, modal } = props;
    const { configStore, supportStore, authStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const { isAdmin } = authStore;

    const [form] = Form.useForm();

    const [changed, setChanged] = useState(false);
    const [attach, setAttach] = useState(false);

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            let edited = {} as any;

            for (let key in values) {
                if (values.hasOwnProperty(key) && form.isFieldTouched(key as string)) {
                    edited[key] = values[key];
                }
            }

            if (edited.support_1_en || edited.support_1_ru) {
                supportStore
                    .fetchUpdateSupport('support_1', {
                        en: values.support_1_en,
                        ru: values.support_1_ru,
                    })
                    .catch(err => console.error(err.message));
            }
            if (edited.support_2_ru) {
                supportStore
                    .fetchUpdateSupport('support_2', {
                        en: edited.support_2_ru,
                        ru: edited.support_2_ru,
                    })
                    .catch(err => console.error(err.message));
            }
            if (edited.support_3_ru) {
                supportStore
                    .fetchUpdateSupport('support_3', {
                        en: edited.support_3_ru,
                        ru: edited.support_3_ru,
                    })
                    .catch(err => console.error(err.message));
            }

            setChanged(false);
        });
    };

    const onAttachFile = (open: boolean) => {
        setAttach(open);
    };

    return (
        <div className="site-layout-content" style={{ display: 'flex' }}>
            {downloaded && (
                <Form
                    style={
                        !modal
                            ? {
                                  marginLeft: 40,
                                  marginRight: 40,
                                  paddingTop: 36,
                                  paddingBottom: 16,
                                  width: isAdmin ? '50%' : '100%',
                              }
                            : {}
                    }
                    form={form}
                    initialValues={supportStore.getInitialValues() as any}
                    onFieldsChange={() => {
                        setChanged(true);
                    }}
                >
                    <Row gutter={[20, 8]}>
                        {getSupportFields(
                            authStore.isAdmin,
                            content,
                            configStore.lang,
                            changed,
                            onSubmitForm,
                            onAttachFile,
                        ).map(field => {
                            let itemField: any = undefined;

                            if (field.meta.type === 'textarea') {
                                itemField = (
                                    <TextArea
                                        placeholder={field.meta.placeholder ?? field.title}
                                        className={cs(
                                            field.classNameField as string,
                                            styles.fullWidthInput,
                                        )}
                                        disabled={!isAdmin}
                                        style={field.meta.style}
                                    />
                                );
                            }
                            if (field.meta.type === 'input') {
                                itemField = (
                                    <Input
                                        placeholder={field.meta.placeholder ?? field.title}
                                        className={cs(
                                            field.classNameField as string,
                                            styles.fullWidthInput,
                                        )}
                                        replace={field.meta.replace}
                                        pattern={field.meta.pattern}
                                        disabled={!isAdmin}
                                    />
                                );
                            }
                            if (field.meta.type === 'label') {
                                itemField = (
                                    <Title
                                        level={5}
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            position: 'absolute',
                                        }}
                                    >
                                        {field.meta.placeholder}
                                    </Title>
                                );
                            }
                            if (field.meta.type === 'button') {
                                itemField = (
                                    <Button
                                        className="btn btn--w100 btn-blue"
                                        onClick={field.meta.onClick}
                                        disabled={field.meta.disabled || !isAdmin}
                                    >
                                        {field.meta.placeholder}
                                    </Button>
                                );
                            }
                            if (field.meta.type === 'space') {
                                itemField = <></>;
                            }
                            return (
                                <Col span={field.meta.span}>
                                    <Form.Item
                                        name={field.key}
                                        label={field.title}
                                        className="form__item"
                                        key={field.key}
                                        rules={(() => {
                                            const rulesArr = [];
                                            if (field.meta.required)
                                                rulesArr.push({
                                                    required: field.meta.required,
                                                    message: content.auth.validate.required,
                                                });
                                            if (field.meta.validation) {
                                                rulesArr.push({
                                                    validator: (
                                                        rule: RuleObject,
                                                        value: StoreValue,
                                                        callback: (error?: string) => void,
                                                    ) =>
                                                        value
                                                            ? field.meta.validation &&
                                                              field.meta.validation(
                                                                  rule,
                                                                  value,
                                                                  callback,
                                                                  //formInstance?.getFieldsValue()
                                                              )
                                                            : Promise.resolve(),
                                                });
                                            }
                                            return rulesArr;
                                        })()}
                                        {...field.formItemParams}
                                    >
                                        {itemField}
                                    </Form.Item>
                                </Col>
                            );
                        })}
                        {!isAdmin && (
                            <>
                                <Col span={24}>
                                    <Form.Item name="title" className="form__item">
                                        <Title
                                            level={5}
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                position: 'absolute',
                                                marginBottom: 24,
                                            }}
                                        >
                                            {content.support.fields.material}
                                        </Title>
                                    </Form.Item>
                                </Col>
                                <div className={styles.files} style={{ paddingTop: 24 }}>
                                    {files.map((file: any) => (
                                        <>
                                            <Col
                                                span={8}
                                                onClick={() =>
                                                    supportStore.fetchDocument(file.id, file.name)
                                                }
                                            >
                                                <Tag
                                                    id={file.id}
                                                    icon={<AttachIcon />}
                                                    closable={false}
                                                    className={styles.file}
                                                >
                                                    {file.name}
                                                </Tag>
                                            </Col>
                                            <Col span={16}>
                                                <p>
                                                    {configStore.lang === 'ru'
                                                        ? file.descr_ru
                                                        : file.descr_en}
                                                </p>
                                            </Col>
                                        </>
                                    ))}
                                </div>
                            </>
                        )}
                    </Row>
                </Form>
            )}
            {isAdmin && (
                <Form
                    style={{
                        marginLeft: 40,
                        marginRight: 40,
                        paddingTop: 36,
                        paddingBottom: 16,
                        width: '50%',
                    }}
                >
                    <Row gutter={[20, 8]} className={styles.files}>
                        <Col span={24}>
                            <Form.Item name="title" className="form__item">
                                <Title
                                    level={5}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        position: 'absolute',
                                    }}
                                >
                                    {content.support.fields.material}
                                </Title>
                            </Form.Item>
                        </Col>
                        {files.map((file: any) => (
                            <SupportFile file={file} setFiles={setFiles} />
                        ))}
                    </Row>
                </Form>
            )}

            {attach && <AttachFile open={attach} setFiles={setFiles} onClose={onAttachFile} />}
        </div>
    );
};

export default observer(Support);
