import React, { useState } from 'react';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { Modal, Button, Row, Col, Select, Typography, Form } from 'antd';
import { Input, TextArea, Selection, Switcher, Password } from '../../../components/Inputs/Inputs';

import EditPassword from './EditPassword';

import styles from './EditUser.module.scss';
import getLanguageContent from '../../../content';
import { useStores } from '../../../store';
import cs from '../../../utils/composeStyles';

import { getEditUserFields } from './EditUserFields';
import { CloseIcon } from '../../../components/Icons/Icons';

const { Title } = Typography;

const EditUser = (props: any) => {
    const { usersStore, configStore, authStore, dictionaryStore } = useStores();
    const { organizations, userGroup, functionalArea, rules } = dictionaryStore;
    const content = getLanguageContent(configStore.lang);

    const { open, newUser, editable, initialValues, onClose } = props;

    const [form] = Form.useForm();

    const [usergroup, setUsergroup] = useState<null | number>(null);

    const [changed, setChanged] = useState(false);
    const [fullNameExists, setFullNameExists] = useState(
        !!initialValues.full_name_en || !!initialValues.full_name_ru,
    );
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
        form.resetFields();
    };

    const handleUserGroupChange = (value: number) => {
        setUsergroup(value);
    };

    const handleChange = (key: string) => () => {
        if (key === 'full_name_en' || key === 'full_name_ru') {
            setFullNameExists(
                !!form.getFieldValue('full_name_en') || !!form.getFieldValue('full_name_ru'),
            );
            form.validateFields().catch(err => console.error(err.message));
        }
    };

    const checkCurrentUser = (id: number, edited: any) => {
        if (Number(sessionStorage.getItem('user_id')) === id) {
            edited.full_name_ru && sessionStorage.setItem(`contact_ru`, edited.full_name_ru);
            edited.full_name_en && sessionStorage.setItem(`contact_en`, edited.full_name_en);
            edited.company && sessionStorage.setItem(`company`, edited.company);
        }
    };

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            setLoading(true);
            if (!initialValues.id) {
                usersStore
                    .fetchNewUser(values)
                    .then(response => {
                        if (response && response.status === 200) {
                            handleClose();
                        }
                    })
                    .finally(() => setLoading(false));
            } else {
                let edited = {} as any;

                for (let key in values) {
                    if (values.hasOwnProperty(key) && form.isFieldTouched(key as string)) {
                        edited[key] = values[key];
                    }
                }

                usersStore
                    .fetchUpdateUser(initialValues.id, edited)
                    .then(response => {
                        if (response.status === 200) {
                            checkCurrentUser(initialValues.id, edited);
                            handleClose();
                        }
                    })
                    .finally(() => setLoading(false));
            }
        });
    };

    return (
        <Modal
            visible={open}
            width={'1080px'}
            onCancel={handleClose}
            footer={null}
            className={styles.modalWrapper}
            closable={false}
        >
            <div className={styles.header}>
                <h3 className={styles.title}>
                    {initialValues && initialValues.id
                        ? `${content.users.editUser.titleEdit} ID${initialValues.id}`
                        : content.users.editUser.titleNew}
                </h3>
                <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.closeBtn}
                    onClick={handleClose}
                />
            </div>

            <Form
                style={{ marginLeft: 40, marginRight: 40, paddingTop: 36, paddingBottom: 16 }}
                form={form}
                initialValues={initialValues}
                onFieldsChange={() => {
                    setChanged(true);
                }}
            >
                <Row gutter={[20, 8]}>
                    {getEditUserFields(
                        content,
                        Number(sessionStorage.getItem('user_id')),
                        authStore.isAdmin,
                        newUser,
                        !!initialValues.company,
                        initialValues.email_checked,
                        organizations,
                        userGroup,
                        functionalArea,
                        rules,
                        configStore.lang,
                        usergroup,
                        handleUserGroupChange,
                    ).map(field => {
                        let itemField: any = undefined;

                        if (field.meta.type === 'textarea') {
                            itemField = (
                                <TextArea
                                    disabled={!editable}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                />
                            );
                        }
                        if (field.meta.type === 'input') {
                            itemField = (
                                <Input
                                    disabled={
                                        !editable ||
                                        (field.meta.disabled
                                            ? field.meta.disabled(initialValues.id)
                                            : false)
                                    }
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    replace={field.meta.replace}
                                    pattern={field.meta.pattern}
                                    suffix={field.meta.suffix}
                                    onChange={handleChange(field.key)}
                                />
                            );
                        }
                        if (field.meta.type === 'password') {
                            itemField = (
                                <Password
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={field.classNameField}
                                />
                            );
                        }
                        if (field.meta.type === 'number') {
                            itemField = (
                                <Input
                                    type={'number'}
                                    disabled={field.meta.disabled || !editable}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                        styles.numberInput,
                                    )}
                                    replace={field.meta.replace}
                                />
                            );
                        }
                        if (field.meta.type === 'label') {
                            itemField = (
                                <Title
                                    level={5}
                                    style={{ fontWeight: 400, fontSize: 14, position: 'absolute' }}
                                >
                                    {field.meta.placeholder}
                                </Title>
                            );
                        }
                        if (field.meta.type === 'checkbox') {
                            itemField = (
                                <Switcher
                                    title={field.meta.placeholder ?? field.title}
                                    defaultChecked={initialValues[field.key]}
                                    disabled={
                                        !editable ||
                                        (field.meta.disabled
                                            ? field.meta.disabled(initialValues.id)
                                            : false)
                                    }
                                />
                            );
                        }
                        if (field.meta.type === 'select') {
                            itemField = (
                                <Selection
                                    disabled={!editable}
                                    mode={field.key === 'ObjectID' ? 'multiple' : undefined}
                                    maxTagCount={field.key === 'ObjectID' ? 1 : 'responsive'}
                                    maxTagTextLength={38}
                                    allowClear
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    placeholder={field.meta.placeholder}
                                    showSearch
                                    filterOption={
                                        field.meta.filterByOptionName
                                            ? (inputValue: any, option: any) =>
                                                  option?.children
                                                      .toLowerCase()
                                                      .includes(inputValue.toLowerCase())
                                            : true
                                    }
                                >
                                    {field.proposedData?.map(item => (
                                        <Select.Option
                                            value={item.value}
                                            key={`${field.key}_proposedData`}
                                        >
                                            {item.is_hidden ? (
                                                <span style={{ color: '#ff4d4f' }}>
                                                    {item.label}
                                                </span>
                                            ) : (
                                                item.label
                                            )}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            );
                        }
                        return (
                            <Col span={field.meta.span}>
                                <Form.Item
                                    name={field.key}
                                    label={field.title}
                                    className="form__item"
                                    key={field.key}
                                    rules={(() => {
                                        const rulesArr = [];
                                        if (
                                            field.meta.required &&
                                            field.meta.required(fullNameExists)
                                        )
                                            rulesArr.push({
                                                required: field.meta.required(fullNameExists),
                                                message: content.auth.validate.required,
                                            });
                                        if (field.meta.validation) {
                                            rulesArr.push({
                                                validator: (
                                                    rule: RuleObject,
                                                    value: StoreValue,
                                                    callback: (error?: string) => void,
                                                ) =>
                                                    value
                                                        ? field.meta.validation &&
                                                          field.meta.validation(
                                                              rule,
                                                              value,
                                                              callback,
                                                              form?.getFieldsValue(),
                                                          )
                                                        : Promise.resolve(),
                                            });
                                        }
                                        return rulesArr;
                                    })()}
                                    {...field.formItemParams}
                                >
                                    {itemField}
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
            {editable && (
                <div className={styles.modalFooter}>
                    {!newUser && <EditPassword />}
                    <Button
                        className="btn btn--w100 btn-blue"
                        onClick={onSubmitForm}
                        disabled={!changed || loading}
                        style={{ marginLeft: !newUser ? 16 : 'auto' }}
                    >
                        {content.newRequest.buttons.save}
                    </Button>
                </div>
            )}
        </Modal>
    );
};

export default EditUser;
