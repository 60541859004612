import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './References.module.scss';
import { Breadcrumb, PageHeader, Table, Form, Radio, Modal, Button } from 'antd';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

import { FileExcelOutlined, ExportOutlined } from '@ant-design/icons';

import Attributes from './AttributesEdit';

import columns from './columns';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const References = (props: any) => {
    const { title } = props;

    const { referencesStore, configStore } = useStores();
    const { lang } = configStore;
    const content = getLanguageContent(lang);

    const [newItem, setNewItem] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({ columnKey: 'id', order: 'ascend' });

    const params = useParams() as any;

    const [form] = Form.useForm();

    useEffect(() => {
        referencesStore.fetchItemList(params.id).catch(err => console.error(err.message));
    }, []);

    useEffect(() => {
        return () => referencesStore.setCurrentItem([]) as any;
    }, []);

    const handlerNewItemClick = () => {
        if (!newItem) {
            setNewItem(true);
            form.setFieldsValue({});
            form.resetFields();

            setSortedInfo({ columnKey: 'id', order: 'ascend' });
            setCurrentPage(1);

            referencesStore.setItemList([...[{ id: 0, key: 0 }], ...referencesStore.itemList]);
            referencesStore.setCurrentItem([0]);
        }
    };

    const onSaveHandler = () => {
        form.validateFields().then((values: any) => {
            let edited = {} as any;

            for (let key in values) {
                if (values.hasOwnProperty(key) && form.isFieldTouched(key as string)) {
                    edited[key] = values[key];
                }
            }
            referencesStore
                .fetchUpdateItem(referencesStore.currentItem[0], edited)
                .then(response => {
                    if (response.status === 200) {
                        referencesStore
                            .fetchItemList(params.id)
                            .then(() => {
                                referencesStore.setCurrentItem([]);
                                referencesStore.setItemList(
                                    referencesStore.itemList.filter((item: any) => item.id !== 0),
                                );
                            })
                            .catch(err => console.error(err.message));
                    }
                });
        });
    };

    const onCloseHandler = (id: number) => {
        Modal.confirm({
            title: content.references.modal.close,
            okText: content.references.modal.buttons.yes,
            cancelText: content.references.modal.buttons.no,
            onOk() {
                closeItem(id);
            },
        });
    };

    const closeItem = (id: number) => {
        referencesStore.fetchCloseItem(id, moment().format('YYYY-MM-DD')).then(response => {
            if (response.status === 200) {
                referencesStore
                    .fetchItemList(params.id)
                    .then(() => {
                        referencesStore.setCurrentItem([]);
                        referencesStore.setItemList(
                            referencesStore.itemList.filter((item: any) => item.id !== 0),
                        );
                    })
                    .catch(err => console.error(err.message));
            }
        });
    };

    const onLockHandler = (id: number) => {
        referencesStore.fetchLockItem(id).then(response => {
            if (response.status === 200) {
                referencesStore
                    .fetchItemList(params.id)
                    .then(() => {
                        referencesStore.setCurrentItem([]);
                        referencesStore.setItemList(
                            referencesStore.itemList.filter((item: any) => item.id !== 0),
                        );
                    })
                    .catch(err => console.error(err.message));
            }
        });
    };

    const onCreateHandler = () => {
        form.validateFields().then((values: any) => {
            referencesStore.fetchCreateItem(params.id, values).then(response => {
                if (response.status === 200) {
                    setNewItem(false);
                    referencesStore
                        .fetchItemList(params.id)
                        .then(() => referencesStore.setCurrentItem([]))
                        .catch(err => console.error(err.message));
                }
            });
        });
    };

    const handleExport = () => {
        referencesStore.fetchListFile(params.id).catch(err => console.error(err.message));
    };

    return (
        <div>
            <div className={styles.content}>
                <PageHeader
                    className={cs(styles.header)}
                    title={
                        <Breadcrumb className={cs(styles.title)}>
                            <Breadcrumb.Item>
                                <Link to="/references">{content.references.title}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{referencesStore.currentRef[lang]}</Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />

                <div className={styles.tableWrapper}>
                    <Form layout="inline" className="components-table-demo-control-bar">
                        <Form.Item>
                            <Radio.Group buttonStyle="solid" className={styles.buttons}>
                                <Radio.Button
                                    onClick={handlerNewItemClick}
                                    className={cs(styles.createBtn, 'btn-blue')}
                                >
                                    {
                                        <span>
                                            <FileExcelOutlined className={styles.icon} />
                                            {content.references.buttons.create}
                                        </span>
                                    }
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <div className={styles.space} />

                        <Form.Item
                            style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}
                        >
                            <Button
                                className={cs('btn-blue', styles.button)}
                                disabled={!referencesStore.currentItem[0] && !newItem}
                                onClick={
                                    newItem ? (onCreateHandler as any) : (onSaveHandler as any)
                                }
                            >
                                {content.references.buttons.save}
                            </Button>
                        </Form.Item>
                        <Form.Item
                            style={{ display: 'flex', alignItems: 'center', marginRight: 25 }}
                        >
                            <Button
                                className={cs(styles.exportBtn, styles.button)}
                                onClick={handleExport}
                            >
                                {
                                    <span>
                                        <ExportOutlined className={styles.icon} />
                                        {content.appManagement.buttons.export}
                                    </span>
                                }
                            </Button>
                        </Form.Item>
                    </Form>

                    <Form form={form}>
                        <Table
                            loading={referencesStore.itemsLoading}
                            columns={columns(
                                lang,
                                content.references,
                                sortedInfo,
                                referencesStore.currentItem[0],
                                newItem ? (onCreateHandler as any) : (onSaveHandler as any),
                                onCloseHandler,
                                onLockHandler,
                            )}
                            dataSource={referencesStore.itemList}
                            pagination={{
                                current: currentPage,
                                showSizeChanger: false,
                                pageSize: 10,
                                onChange: page => {
                                    setCurrentPage(page);
                                },
                            }}
                            size={'small'}
                            rowClassName={(row: any) =>
                                !row.dtime
                                    ? !row.is_hidden
                                        ? styles.expandableRow
                                        : styles.hiddenRow
                                    : styles.disabledRow
                            }
                            onChange={(pagination: any, filters: any, sorter: any) => {
                                setSortedInfo(sorter);
                                setNewItem(false);
                                referencesStore.setItemList(
                                    referencesStore.itemList.filter((item: any) => item.id !== 0),
                                );
                                referencesStore.setCurrentItem([]);
                                form.setFieldsValue({});
                                form.resetFields();
                            }}
                            expandable={{
                                expandedRowKeys: referencesStore.currentItem,
                                expandedRowRender: (record, index) => (
                                    <Attributes
                                        key={`expanded_${index}`}
                                        form={form}
                                        hidden={record.is_hidden}
                                    />
                                ),
                                expandIconColumnIndex: -1,
                                expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    if (!record.dtime) {
                                        record.id &&
                                            newItem &&
                                            referencesStore.setItemList(
                                                referencesStore.itemList.filter(
                                                    (item: any) => item.id !== 0,
                                                ),
                                            );
                                        record.id && setNewItem(false);

                                        if (record.id !== referencesStore.currentItem[0]) {
                                            record.id && form.setFieldsValue({});
                                            record.id && form.resetFields();
                                        }

                                        if (expanded) {
                                            form.setFieldsValue(record);
                                            referencesStore.setCurrentItem([record.id]);
                                        }
                                    }
                                },
                            }}
                            showSorterTooltip={false}
                        />
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default observer(References);
