import { Content } from 'antd/lib/layout/layout';
import { Breadcrumb, PageHeader } from 'antd';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Support.module.scss';
import cs from '../../utils/composeStyles';
import getLanguageContent from '../../content';
import { useStores } from '../../store';

import Support from './Support';

const SupportPage = (props: any) => {
    const { configStore, supportStore, authStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const { isAdmin } = authStore;

    const [downloaded, setDownloaded] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        supportStore
            .fetchData()
            .then(() => {
                supportStore
                    .fetchFiles()
                    .then((result: any) => {
                        setFiles(result.data);
                    })
                    .catch(err => console.error(err.message));
            })
            .catch(err => console.error(err.message))
            .finally(() => setDownloaded(true));
    }, []);

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.support.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <div className={styles.tableWrapper} style={!isAdmin ? { width: '45%' } : {}}>
                <Content>
                    <Support downloaded={downloaded} files={files} setFiles={setFiles} />
                </Content>
            </div>
        </div>
    );
};

export default observer(SupportPage);
