import { makeAutoObservable, runInAction } from 'mobx';

import getLanguageContent from '../content';
import ConfigurationStore from './configurationStore';

type MessageElement = {
    type: 'error' | 'success' | 'info' | 'warning';
    title: string;
    message: string;
    duration: number;
    id?: number;
    isnew?: boolean;
};

export class NotificationStore {
    messages: MessageElement[] = [];
    timeoutMs: 5 = 5;
    private static id = 0;

    constructor(private readonly configStore: ConfigurationStore) {
        makeAutoObservable(this);
        this.configStore = configStore;
    }

    setErrorMessage(text: string) {
        const content = getLanguageContent(this.configStore.lang);
        this.push({
            type: 'error',
            title: content.notifications.errorTitle,
            message: text,
            duration: this.timeoutMs,
        });
    }

    setInfoMessage(text: string) {
        const content = getLanguageContent(this.configStore.lang);
        this.push({
            type: 'info',
            title: content.notifications.informationTitle,
            message: text,
            duration: this.timeoutMs,
        });
    }

    setSuccessMessage(title: string, text: string) {
        this.push({ type: 'info', title, message: text, duration: this.timeoutMs });
    }

    close(message: MessageElement) {
        this.removeMessage(message);
    }

    private push(message: MessageElement) {
        message.id = ++NotificationStore.id;
        message.isnew = true;
        runInAction(() => {
            this.messages.push(message);
        });
    }

    private removeMessage(message: MessageElement) {
        const index = this.messages.findIndex(x => x.id === message.id);
        if (index > -1) {
            runInAction(() => {
                this.messages.splice(index, 1);
            });
        }
    }
}
