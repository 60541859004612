import React from 'react';
import moment from 'moment';

import { Dropdown, Tooltip, Menu, Button, Switch, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { parseItemName } from '../ApplicationManagement/utils/parseDictionaryItem';

export default (
    lang: string,
    content: any,
    organisations: any,
    grantAdminHandler: any,
    activateUserHandler: any,
) => {
    const { header, variables, modal } = content;

    const handlerRoleClick = (id: number, user: string, isAdmin: boolean) => {
        const title = modal.admin(user, isAdmin);
        Modal.confirm({
            title: title,
            okText: modal.buttons.yes,
            cancelText: modal.buttons.no,
            onOk() {
                grantAdminHandler(id, isAdmin);
            },
        });
    };

    const roles = (row: any) => (
        <Menu>
            <Menu.Item onClick={() => handlerRoleClick(row.id, row.username, true)}>
                {variables.admin}
            </Menu.Item>
            <Menu.Item onClick={() => handlerRoleClick(row.id, row.username, false)}>
                {variables.consumer}
            </Menu.Item>
        </Menu>
    );

    const handleActivateClick = (id: number, user: string, isActive: boolean) => {
        Modal.confirm({
            title: modal.activate(user, isActive),
            okText: modal.buttons.yes,
            cancelText: modal.buttons.no,
            onOk() {
                activateUserHandler(id, isActive);
            },
        });
    };

    return [
        {
            title: header.id,
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id,
            defaultSortOrder: 'descend' as any,
            width: '8%',
            align: 'left' as any,
        },
        {
            title: header.username,
            dataIndex: 'username',
            key: 'username',
            sorter: (a: any, b: any) => a.username.localeCompare(b.username),
            width: '10%',
        },
        {
            title: header.name,
            dataIndex: 'full_name_en',
            key: 'full_name_en',
            render: (text: string, row: any) => (
                <>
                    <p style={{ marginBottom: 0 }}>{row.full_name_en}</p>
                    <p style={{ marginBottom: 0 }}>{row.full_name_ru}</p>
                </>
            ),
            width: '18%',
        },
        {
            title: header.role,
            dataIndex: 'is_admin',
            key: 'is_admin',
            render: (admin: boolean, row: any) => (
                <Dropdown overlay={roles(row)} placement="bottomLeft" trigger={['click']}>
                    <Button type="text">{admin ? variables.admin : variables.consumer}</Button>
                </Dropdown>
            ),
            width: '10%',
        },
        {
            title: header.company,
            dataIndex: 'company',
            key: 'company',
            render: (companyId: number) => {
                const organisation = organisations.filter((org: any) => org.id === companyId)[0];
                return companyId && organisation ? parseItemName(organisation.name, lang) : '';
            },
            sorter: (a: any, b: any) => a.company_str.localeCompare(b.company_str),
            width: '24%',
        },
        {
            title: header.position,
            dataIndex: 'position',
            key: 'position',
            width: '10%',
        },
        {
            title: header.email,
            dataIndex: 'email_checked',
            key: 'email_checked',
            width: '10%',
            align: 'center' as any,
            render: (active: boolean) =>
                active ? (
                    <CheckCircleOutlined style={{ color: '#59A23F' }} />
                ) : (
                    <CloseCircleOutlined style={{ color: '#FF4557' }} />
                ),
        },
        {
            title: header.active,
            width: '8%',
            align: 'center' as any,
            render: (item: any, row: any) => (
                <Switch
                    checked={row.is_active}
                    disabled={!row.company}
                    onChange={() => handleActivateClick(row.id, row.username, !row.is_active)}
                />
            ),
        },
    ];
};
