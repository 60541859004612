import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../store';

import Request from '../Request/Request';
import getLanguageContent from '../../../content';

const NewRequest = (props: any) => {
    const { applicationManagementStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [created, setCreated] = useState(false);
    const [rq_id, setRequestId] = useState(0);
    const [editable, setEditable] = useState(true);
    const [rq_status, setRequestStatus] = useState({ en: 'Not saved', ru: 'Не сохранено' });

    const submitForm = (values: any) => {
        if (!created) {
            applicationManagementStore
                .fetchNewRequest({
                    ...values,
                    ...{ ContactID: Number(sessionStorage.getItem('user_id')) },
                })
                .then(response => {
                    if (response.status === 201) {
                        setRequestId(response.data.id);
                        setCreated(true);

                        applicationManagementStore
                            .fetchStatus(response.data.status)
                            .then(response => {
                                if (response.status === 200) {
                                    setRequestStatus(JSON.parse(response.data[0].name));
                                }
                            });
                    }
                });
        } else {
            applicationManagementStore.fetchUpdateRequest(rq_id, { ...values }).then(response => {
                if (response.status === 201) {
                }
            });
        }
    };

    const getRequestInfo = () => {
        applicationManagementStore
            .fetchRq(rq_id)
            .then(response => {
                if (response.status === 200) {
                    const header = response.data.header;

                    applicationManagementStore
                        .fetchStatus(header.status)
                        .then(response => {
                            if (response.status === 200) {
                                const status = JSON.parse(response.data[0].attrib).status;

                                setRequestStatus(JSON.parse(response.data[0].name));
                                setEditable(
                                    status === 0 || status === 1 || status === 3 || status === 5,
                                );
                            }
                        })
                        .catch(err => console.error(err.message));
                }
            })
            .catch(err => console.error(err.message));
    };

    return (
        <Request
            title={
                created
                    ? `${content.newRequest.titles.edit} ${rq_id}`
                    : content.newRequest.titles.new
            }
            contact={sessionStorage.getItem(`contact_${configStore.lang}`)}
            created={created}
            editable={editable}
            rq_id={rq_id}
            rq_status={rq_status}
            submitForm={submitForm}
            getRequestInfo={getRequestInfo}
        />
    );
};

export default observer(NewRequest);
