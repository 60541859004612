import styles from '../AppSubmenuItem/AppSubmenuItem.module.scss';
import React, { FC } from 'react';
import cs from '../../utils/composeStyles';
import useMainPageOverlay from '../../hooks/useMainPageOverlay/useMainPageOverlay';

type AppSubmenuItemProps = {
    onClick: () => void;
    item: sidebarSubmenuType;
    isSelected: boolean | undefined;
};

const AppSubmenuItem: FC<AppSubmenuItemProps> = ({ onClick, item, isSelected }) => {
    const { deactivateOverlay } = useMainPageOverlay();

    const onClickCallback = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        onClick();
        deactivateOverlay();
    };

    const selectStyle = isSelected ? styles.titleActive : '';

    return (
        <span
            onClick={e => onClickCallback(e)}
            key={item.id}
            className={cs(styles.title, selectStyle)}
        >
            {item.title}
        </span>
    );
};

export default AppSubmenuItem;
