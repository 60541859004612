import axios, { AxiosResponse } from 'axios';
import urljoin from 'url-join';
import ConfigurationStore from '../store/configurationStore';
import { AuthResponse, DataRegistration, LoginUser } from './models/auth';
import { AxiosMethod, requestTemplate } from './helper';

export default interface AuthServiceInterface {
    loginUser: (dataLogin: LoginUser) => Promise<AxiosResponse<AuthResponse>>;
    registrationUser: (
        dataRegistration: DataRegistration,
    ) => Promise<AxiosResponse<DataRegistration>>;
}

export class AuthService implements AuthServiceInterface {
    apiUrl = '';
    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    loginUser = async (dataLogin: LoginUser): Promise<AxiosResponse<AuthResponse>> => {
        const form = new FormData();
        form.append('username', dataLogin.username);
        form.append('password', dataLogin.password);
        return await requestTemplate<LoginUser, AuthResponse>(
            urljoin(this.apiUrl, '/login/token'),
            AxiosMethod.post,
            form as unknown as LoginUser,
        );
    };

    registrationUser = async (
        dataRegistration: DataRegistration,
    ): Promise<AxiosResponse<DataRegistration>> => {
        return await requestTemplate<DataRegistration, DataRegistration>(
            urljoin(this.apiUrl, '/users/create'),
            AxiosMethod.post,
            dataRegistration,
        );
    };

    checkIsAdmin = async (id: number): Promise<AxiosResponse<AuthResponse>> => {
        return await axios.get(urljoin(this.apiUrl, `/users/get/${id}`), {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
        });
    };

    resetPassword = async (email: string): Promise<AxiosResponse<AuthResponse>> => {
        return await axios.get(urljoin(this.apiUrl, `/users/pass_change_request/`), {
            params: { email },
        });
    };

    changePassword = async (
        uuid: string,
        password: string,
    ): Promise<AxiosResponse<AuthResponse>> => {
        return await axios.post(
            urljoin(this.apiUrl, `/users/pass_reset/${uuid}/`),
            {},
            { params: { password } },
        );
    };
}
