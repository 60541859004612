import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { MoreOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';

const parseItem = (lang: string) => (item: string) => {
    const name = item ? JSON.parse(item).name : item;
    return name ? name[lang] : name;
};

const parseArray = (lang: string, item: string): Array<any> => {
    const arr = item ? JSON.parse(item) : item;

    return arr.map((obj: any) => JSON.parse(obj.name)[lang]);
};

const setObjects = (arr: Array<any>) => {
    return (
        <div style={{ maxHeight: '90vh', overflow: 'auto' }}>
            <Menu>
                {arr.map(object => (
                    <Menu.Item>{object}</Menu.Item>
                ))}
            </Menu>
        </div>
    );
};

export default (
    lang: string,
    content: any,
    editable: boolean,
    tooltip: string,
    handlerDeleteClick: any,
    handlerEditServiceClick: any,
    setTooltip: any,
) => {
    const header = content.table.header;

    const menu = (service: any) => (
        <Menu>
            <Menu.Item onClick={() => handlerEditServiceClick(service)}>
                <FormOutlined style={{ marginRight: 10 }} />
                {content.buttons.open}
            </Menu.Item>
            <Menu.Item
                onClick={() => handlerDeleteClick(service.ft, service.id)}
                disabled={!editable}
            >
                <DeleteOutlined style={{ marginRight: 10 }} />
                {content.buttons.delete}
            </Menu.Item>
        </Menu>
    );

    return [
        {
            title: header.service,
            dataIndex: 'service_full',
            key: 'service_full',
            render: parseItem(lang),
            width: '25%',
        },
        {
            title: header.object,
            dataIndex: 'object_full',
            key: 'object_full',
            width: '23%',
            render: (item: any) => {
                const arr = parseArray(lang, item);

                return (
                    <Dropdown
                        overlay={setObjects(arr)}
                        placement="bottomLeft"
                        onVisibleChange={vis => (vis ? setTooltip(false) : setTooltip(!!tooltip))}
                    >
                        <div>
                            <span>
                                {arr[0].length > 60 ? `${arr[0].substring(0, 60)}...` : arr[0]}
                                <span style={{ color: 'grey' }}>
                                    {arr.length > 1
                                        ? `  + ${content.table.more} ${arr.length - 1}`
                                        : ''}
                                </span>
                            </span>
                        </div>
                    </Dropdown>
                );
            },
        },
        {
            title: header.model,
            dataIndex: 'model',
            key: 'model',
            width: '10%',
        },
        {
            title: header.class,
            dataIndex: 'eclass',
            key: 'eclass',
            width: '8%',
        },
        {
            title: header.recFrequency,
            key: 'franger',
            width: '12%',
            align: 'center' as any,
            render: (text: string, row: any) =>
                row.frangerfrom && row.frangerto ? (
                    <>{`${row.frangerfrom} - ${row.frangerto}`}</>
                ) : null,
        },
        {
            title: header.transFrequency,
            key: 'franget',
            width: '12%',
            align: 'center' as any,
            render: (text: string, row: any) =>
                row.frangerfrom && row.frangerto ? (
                    <>{`${row.frangetfrom} - ${row.frangetto}`}</>
                ) : null,
        },
        {
            title: header.actions,
            width: '10%',
            align: 'center' as any,
            render: (item: any) => (
                <Dropdown
                    overlay={menu(item)}
                    placement="bottomLeft"
                    onVisibleChange={vis => (vis ? setTooltip(false) : setTooltip(!!tooltip))}
                >
                    <Button type="text" shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];
};
