import { Layout, Menu } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link, useHistory, Route } from 'react-router-dom';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { observer } from 'mobx-react';

import styles from './MainPage.module.scss';
import logo from '../../assets/images/Logos/logo.svg';

import useBreakpoints from '../../hooks/useBreakpoints/useBreakpoints';
import AppHeader from '../../components/AppHeader/AppHeader';
import getLanguageContent from '../../content';
import { useStores } from '../../store';
import cs from '../../utils/composeStyles';
import AppMenuItem from '../../components/AppMenuItem/AppMenuItem';
import AppSubmenu from '../../components/AppSubmenu/AppSubmenu';
import MainPageOverlay from '../../components/MainPageOverlay/MainPageOverlay';
import useMainPageOverlay from '../../hooks/useMainPageOverlay/useMainPageOverlay';
import useSubmenuSettings from '../../hooks/useSubmenuSettings/useSubmenuSettings';

import Error from '../../components/Error/Error';
import ApplicationMenagement from '../ApplicationManagement/ApplicationManagement';
import NewRequest from '../ApplicationManagement/NewRequest/NewRequest';
import EditRequest from '../ApplicationManagement/EditRequest/EditRequest';
import Users from '../Users/Users';
import Support from '../Support/SupportPage';
import Reports from '../Reports/Reports';
import Monitoring from '../Monitoring/Monitoring';
import Journal from '../Journal/Journal';
import NotificationsPage from '../Notifications/NotificationsPage';
import References from '../References/References';
import ReferencesItems from '../References/ReferencesItems';
import jwt_decode from 'jwt-decode';

import { logOut } from '../../utils/auth';
import { regular as regularRouting, admin as adminRouting } from '../../utils/routing';

const { Content, Footer, Sider } = Layout;

const MainPage = () => {
    const history = useHistory();
    const { authStore, configStore, sidebarCollapsibleStore, notificationStore } = useStores();
    const { checkOverlay } = useMainPageOverlay();
    const { isBreakpoint, destroy } = useBreakpoints(1024);
    const arrayOfCallbacksSettings = useSubmenuSettings(history, configStore);

    const [settingsIsCollapsed, setSettingsCollapsed] = useState<boolean>(true);

    const content = getLanguageContent(configStore.lang);

    const { sideBar } = content;

    const { ApplicationManagement, ReportsAndStatistics, HelpAndSupport, Language, Settings } =
        sideBar;

    const routing = (key: string) => {
        authStore.isAdmin ? history.push(adminRouting(key)) : history.push(regularRouting(key));
    };

    const handlerSelectClick: MenuClickEventHandler = ({ item, key }) => {
        // Проверяем, открыто ли сабменю. Если сабменю открыто -> показываем overlay для планшетов
        checkOverlay(item);

        // При клике на Language меняем язык
        if (key === 'Language') {
            return configStore.toggleLanguage();
        }

        localStorage.setItem('app_menu', key);
        if (key !== 'Settings') configStore.setCurrentPage(key);

        // При клике на настройки открываем сабменю настроек
        if (key === 'Settings') {
            return setSettingsCollapsed(prev => !prev);
            // return setSettingsCollapsed(false)
        }

        // Закрываем все сабменю при клике по другим пунктам
        setSettingsCollapsed(true);

        routing(key);
    };

    const isCollapsed = sidebarCollapsibleStore.GET_SIDEBAR_IS_COLLAPSED;
    const sideMenuKey = localStorage.getItem('app_menu');

    useEffect(() => {
        return () => {
            destroy();
        };
    }, [destroy]);

    useEffect(() => {
        if (!isBreakpoint) {
            sidebarCollapsibleStore.SET_ACTIVE_SIDEBAR_COLLAPSED();
        } else {
            sidebarCollapsibleStore.SET_INACTIVE_SIDEBAR_COLLAPSED();
        }
    }, [isBreakpoint, sidebarCollapsibleStore]);

    useEffect(() => {
        authStore
            .fetchIsAdmin()
            .catch(err => console.error(err.message))
            .finally(() => {
                if (!sideMenuKey) {
                    localStorage.setItem('app_menu', 'ApplicationManagement');
                    configStore.setCurrentPage('ApplicationManagement');
                    history.push('/application-management/');
                } else routing(sideMenuKey as string);
            });
    }, []);

    useEffect(() => {
        const jwtDecode: any = jwt_decode(sessionStorage.getItem('access_token') as string);
        const exp = new Date(jwtDecode.exp * 1000);
        const cur = new Date();
        const deadline = exp.getTime() - cur.getTime();
        const notificationDeadline = exp.getTime() - cur.getTime() - 600000;

        setTimeout(() => {
            notificationStore.setInfoMessage(content.notifications.logoutNotification);
        }, notificationDeadline);

        setTimeout(() => {
            logOut();
            window.location.reload();
        }, deadline);
    }, []);

    return (
        <Layout className={cs(styles.mainPage, 'mainPageGlobal')}>
            <Sider
                collapsed={isCollapsed}
                className={cs(styles.sidebar, 'sidebar')}
                theme="light"
                width={255}
                collapsedWidth={70}
            >
                <Link to="/">
                    <div className={styles.logoContainer}>
                        <div className={styles.logoWrapper}>
                            <img src={logo} alt="Not found" className={styles.logo} />
                        </div>
                        <div className={styles.logoDescription}>
                            <h1 className={styles.logoDescriptionTopHead}>SPECTRUM</h1>
                            <h2 className={styles.logoDescriptionBottomHead}>order portal</h2>
                        </div>
                    </div>
                </Link>

                <div className={styles.menuWrapper}>
                    <Menu
                        defaultSelectedKeys={
                            sideMenuKey ? [sideMenuKey as string] : ['ApplicationManagement']
                        }
                        onClick={handlerSelectClick}
                        theme="light"
                        mode="inline"
                    >
                        {AppMenuItem(ApplicationManagement)}
                        {AppMenuItem(ReportsAndStatistics)}
                        {AppMenuItem(HelpAndSupport)}

                        <Menu.Divider className={styles.menuDivider} />

                        {authStore.isAdmin &&
                            AppSubmenu(Settings, settingsIsCollapsed, arrayOfCallbacksSettings)}
                    </Menu>

                    <Menu
                        onClick={handlerSelectClick}
                        theme="light"
                        mode="inline"
                        selectable={false}
                    >
                        {AppMenuItem(Language)}
                    </Menu>
                </div>
            </Sider>

            <Layout>
                <div className="MainPageLayout">
                    <AppHeader />
                    <Content style={{ margin: '0 16px' }}>
                        <Route path={'/application-management'}>
                            <ApplicationMenagement />
                        </Route>
                        <Route path={'/new-request'}>
                            <NewRequest />
                        </Route>
                        <Route path={'/edit-request/:id'}>
                            <EditRequest />
                        </Route>

                        <Route path={'/reports'}>
                            <Reports />
                        </Route>
                        <Route path={'/support'}>
                            <Support />
                        </Route>
                        <Route path={'/users'}>
                            <Users />
                        </Route>
                        <Route path={'/notifications'}>
                            <NotificationsPage />
                        </Route>
                        <Route path={'/references'}>
                            <References />
                        </Route>
                        <Route path={'/edit-reference/:id'}>
                            <ReferencesItems />
                        </Route>
                        <Route path={'/monitoring'}>
                            <Monitoring />
                        </Route>
                        <Route path={'/journal'}>
                            <Journal />
                        </Route>
                    </Content>
                    <Footer style={{ textAlign: 'center' }} />
                    <MainPageOverlay />
                </div>
            </Layout>
            <Error />
        </Layout>
    );
};

export default observer(MainPage);
