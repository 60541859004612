import { Col, Row, Form, Typography, Button, Select } from 'antd';

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Notifications.module.scss';
import cs from '../../utils/composeStyles';
import getLanguageContent from '../../content';
import { useStores } from '../../store';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

import { Input, Selection, TextArea } from '../../components/Inputs/Inputs';
import { getNotificationsFields } from './NotificationsFields';

const { Title } = Typography;

const NotificationsPage = (props: any) => {
    const { configStore, notificationsTemplateStore, authStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const { code, initialValues } = props;
    const { isAdmin } = authStore;

    const [changed, setChanged] = useState(false);

    const [form] = Form.useForm();

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            let edited = {} as any;

            for (let key in values) {
                if (values.hasOwnProperty(key) && form.isFieldTouched(key as string)) {
                    edited[key] = values[key];
                }
            }

            if (edited.descr_en || edited.descr_ru) {
                edited.name = {
                    en: values.descr_en,
                    ru: values.descr_ru,
                };
                edited.descr_en = undefined;
                edited.descr_ru = undefined;
            }

            notificationsTemplateStore
                .fetchUpdateTemplate(code, edited)
                .then(() =>
                    dictionaryStore
                        .getNotificationsTemplate()
                        .catch(err => console.error(err.message)),
                )
                .catch(err => console.error(err.message));

            setChanged(false);
        });
    };

    return (
        <Form
            style={{ marginLeft: 40, marginRight: 40, paddingBottom: 16 }}
            form={form}
            initialValues={initialValues}
            onFieldsChange={() => {
                setChanged(true);
            }}
        >
            <Row gutter={[20, 8]}>
                {getNotificationsFields(content, configStore.lang, changed, onSubmitForm).map(
                    field => {
                        let itemField: any = undefined;

                        if (field.meta.type === 'textarea') {
                            itemField = (
                                <TextArea
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    disabled={!isAdmin}
                                />
                            );
                        }
                        if (field.meta.type === 'input') {
                            itemField = (
                                <Input
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    replace={field.meta.replace}
                                    pattern={field.meta.pattern}
                                    disabled={!isAdmin}
                                />
                            );
                        }
                        if (field.meta.type === 'number') {
                            itemField = (
                                <Input
                                    type={'number'}
                                    placeholder={field.meta.placeholder ?? field.title}
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                        styles.numberInput,
                                    )}
                                    replace={field.meta.replace}
                                />
                            );
                        }
                        if (field.meta.type === 'label') {
                            itemField = (
                                <Title
                                    level={5}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        position: 'absolute',
                                    }}
                                >
                                    {field.meta.placeholder}
                                </Title>
                            );
                        }
                        if (field.meta.type === 'button') {
                            itemField = (
                                <Button
                                    className="btn btn--w100 btn-blue"
                                    onClick={field.meta.onClick}
                                    disabled={field.meta.disabled || !isAdmin}
                                >
                                    {field.meta.placeholder}
                                </Button>
                            );
                        }
                        if (field.meta.type === 'space') {
                            itemField = <></>;
                        }
                        if (field.meta.type === 'select') {
                            itemField = (
                                <Selection
                                    maxTagTextLength={38}
                                    allowClear
                                    className={cs(
                                        field.classNameField as string,
                                        styles.fullWidthInput,
                                    )}
                                    placeholder={field.meta.placeholder}
                                    showSearch
                                    filterOption={
                                        field.meta.filterByOptionName
                                            ? (inputValue: any, option: any) =>
                                                  option?.children
                                                      .toLowerCase()
                                                      .includes(inputValue.toLowerCase())
                                            : true
                                    }
                                >
                                    {field.proposedData?.map(item => (
                                        <Select.Option
                                            value={item.value}
                                            key={`${field.key}_proposedData`}
                                        >
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            );
                        }
                        return (
                            <Col span={field.meta.span}>
                                <Form.Item
                                    name={field.key}
                                    label={field.title}
                                    className="form__item"
                                    key={field.key}
                                    rules={(() => {
                                        const rulesArr = [];
                                        if (field.meta.required)
                                            rulesArr.push({
                                                required: field.meta.required,
                                                message: content.auth.validate.required,
                                            });
                                        if (field.meta.validation) {
                                            rulesArr.push({
                                                validator: (
                                                    rule: RuleObject,
                                                    value: StoreValue,
                                                    callback: (error?: string) => void,
                                                ) =>
                                                    value
                                                        ? field.meta.validation &&
                                                          field.meta.validation(
                                                              rule,
                                                              value,
                                                              callback,
                                                              //formInstance?.getFieldsValue()
                                                          )
                                                        : Promise.resolve(),
                                            });
                                        }
                                        return rulesArr;
                                    })()}
                                    {...field.formItemParams}
                                >
                                    {itemField}
                                </Form.Item>
                            </Col>
                        );
                    },
                )}
            </Row>
        </Form>
    );
};

export default observer(NotificationsPage);
