import React, { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { observer } from 'mobx-react';
import { NotificationStore } from '../../store/notificationStore';

const Notification: React.FC<{ notificationStore: NotificationStore }> = ({
    notificationStore,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const openNotification = () => {
        notificationStore.messages
            .filter(x => x.isnew === true)
            .forEach(x => {
                x.isnew = false;
                notification[x.type]({
                    message: x.title,
                    description: x.message,
                    duration: x.duration,
                    onClose: () => notificationStore.close(x),
                });
            });
    };

    useEffect(() => {
        ref.current?.click();
    });

    return (
        <div>
            {notificationStore.messages.length > 0 && (
                <div ref={ref} onClick={() => openNotification()} />
            )}
        </div>
    );
};

export default observer(Notification);
