import axios, { AxiosResponse } from 'axios';
import urljoin from 'url-join';
import ConfigurationStore from '../store/configurationStore';
import { AxiosMethod, requestTemplate } from './helper';

import { logOut } from '../utils/auth';

export default interface NotificationsTemplatesInterface {
    updateTemplate: (code: string, data: any) => Promise<AxiosResponse<any>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class NotificationsTemplatesService implements NotificationsTemplatesInterface {
    apiUrl = '';
    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    updateTemplate = async (
        code: string,
        data: any,
    ): Promise<AxiosResponse<{ ru: string; en: string }>> => {
        return await requestTemplate<any, { ru: string; en: string }>(
            urljoin(this.apiUrl, `/cls/mess_update_dict/${code}`),
            AxiosMethod.put,
            data,
            sessionStorage.getItem('access_token'),
        );
    };
}
