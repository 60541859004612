export default {
    title: 'Help & Support',
    fields: {
        information: 'Information',
        phone: 'Phone',
        email: 'E-mail',
        material: 'Material',
        addFile: 'Add file',
        save: 'Save',
        description: 'Description',
    },
    placeholders: {
        information_ru: 'Information on Russian',
        information_en: 'Information on English',
        phone: 'Phone number',
        email: 'e-mail',
        material: 'Material',
        addFile: 'Add file',
        description: 'Description',
        description_ru: 'Description on Russian',
        description_en: 'Description on English',
    },
    attach: {
        title: 'Upload File',
        buttons: {
            save: 'Save',
            cancel: 'Cancel',
            addFile: 'Add file',
        },
    },
    files: {
        delete: 'Do you really want to delete file',
    },
    modal: {
        buttons: {
            yes: 'Yes',
            no: 'No',
        },
    },
};
