import React from 'react';
import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store';
import getLanguageContent from '../../../../content';
import { getPasswordFields } from './PasswordResetFIelds';
import FormComponent from '../../../../components/FormComponent/FormComponent';
import { useHistory, useParams } from 'react-router';
import { DataRegistration } from '../../../../service/models/auth';

const PasswordSend = (props: any) => {
    const { authStore, configStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const history = useHistory();

    const params = useParams() as any;

    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields().then(async (values: DataRegistration) => {
            const response: any = await authStore.fetchChangePassword(params.uuid, values.password);

            if (response.status === 200) {
                Modal.success({
                    title: content.auth.modal.password.success,
                    onOk() {
                        history.push('/login');
                    },
                });
            } else {
                Modal.error({
                    title: content.auth.modal.password.error,
                    content:
                        configStore.lang === 'ru'
                            ? response.data.split('#\n')[1]
                            : response.data.split('#\n')[0],
                });
            }
        });
    };

    return (
        <>
            <div style={{ marginTop: 40 }}>
                <FormComponent
                    formName="Login"
                    formInstance={form}
                    formFields={getPasswordFields(content, dictionaryStore.rules)}
                />
            </div>
            <Button className="btn btn--w100 btn-blue" style={{ marginTop: 40 }} onClick={onSubmit}>
                {content.auth.buttons.changePass}
            </Button>
        </>
    );
};

export default observer(PasswordSend);
