import { makeAutoObservable } from 'mobx';

export class ErrorStore {
    error: any;

    constructor() {
        makeAutoObservable(this);
    }

    setError = (error: any) => {
        this.error = error;
    };

    apiError = (result: any) => {
        const { status } = result;

        switch (status) {
            case 400:
                if (result.data && !result.data.description) {
                    return this.setError([{ msg: result.data }]);
                }
                if (result.data && result.data.description) {
                    return this.setError([{ msg: result.data.description }]);
                }
                if (result.detail && result.detail.description) {
                    return this.setError([{ msg: result.detail.description }]);
                }
                return this.setError([{ msg: 'Unknown error' }]);

            case 401:
                if (result.data.detail) {
                    return this.setError([{ msg: result.data.detail }]);
                }
                return this.setError([{ msg: 'Unknown error' }]);

            case 405:
                if (result.data.detail) {
                    return this.setError([{ msg: result.data.detail }]);
                }
                return this.setError([{ msg: 'Unknown error' }]);

            case 409:
                if (result.data.detail) {
                    return this.setError([{ msg: result.data.detail.description }]);
                }
                return this.setError([{ msg: 'Unknown error' }]);

            case 422:
                if (result.status === 422) {
                    if (result.data.detail) {
                        return this.setError(result.data.detail);
                    }

                    if (result.data.description) {
                        return this.setError([{ msg: result.data.description }]);
                    }
                }
                return this.setError([{ msg: 'Unknown error' }]);

            case 500:
                return this.setError([{ msg: 'Internal server error' }]);

            default:
                return this.setError([{ msg: 'Unknown error' }]);
        }
    };
}
