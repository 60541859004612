import React from 'react';
import moment from 'moment';

export default (lang: string, header: any, type: number) => {
    const columns = {
        id: {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        ctime: {
            title: header.ctime,
            dataIndex: 'ctime',
            key: 'ctime',
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
        },
        username: {
            title: header.username,
            dataIndex: 'username',
            key: 'username',
        },
        fio: {
            title: header.fio,
            dataIndex: 'fio',
            key: 'fio',
        },
        email: {
            title: header.email,
            dataIndex: 'email',
            key: 'email',
        },
        login: {
            title: header.login,
            dataIndex: 'login',
            key: 'login',
        },
        event: {
            title: header.event,
            dataIndex: 'event',
            key: 'event',
        },
        rq_id: {
            title: header.rq_id,
            dataIndex: 'rq_id',
            key: 'rq_id',
        },
        status: {
            title: header.status,
            dataIndex: 'status',
            key: 'status',
        },
    };

    switch (type) {
        case 1:
            return [
                columns.username,
                columns.fio,
                columns.event,
                columns.rq_id,
                columns.status,
                columns.ctime,
            ];

        case 2:
            return [
                columns.username,
                columns.fio,
                columns.event,
                columns.email,
                columns.login,
                columns.ctime,
            ];
    }
};
