export default {
    filterTitle: 'Меню фильтра',
    filterConfirm: 'OK',
    filterReset: 'Сброс',
    filterEmptyText: 'Нет фильтров',
    emptyText: 'Нет данных',
    selectAll: 'Выберите текущую страницу',
    selectInvert: 'Invert current page',
    selectNone: 'Очистить данные',
    selectionAll: 'Выбрать все данные',
    sortTitle: 'Сортировка',
    expand: 'Раскрыть строку',
    collapse: 'Скрыть строку',
    triggerDesc: 'Click to sort descending',
    triggerAsc: 'Click to sort ascending',
    cancelSort: 'Click to cancel sorting',
};
