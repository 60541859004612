import axios, { AxiosResponse } from 'axios';
import urljoin from 'url-join';
import ConfigurationStore from '../store/configurationStore';
import { UsersItem } from './models/users';
import { AxiosMethod, requestTemplate } from './helper';
import { ApplicationManagementItem, EditRequestData } from './models/applicationManagement';
import { DataRegistration } from './models/auth';

import { logOut } from '../utils/auth';

export default interface UsersInterface {
    checkIsAdmin: (id: number) => Promise<AxiosResponse<any>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class UsersService implements UsersInterface {
    apiUrl = '';
    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url, '/'), config);
    };

    getList = async (): Promise<AxiosResponse<ApplicationManagementItem[]>> => {
        return await this.axiosGet(urljoin(this.apiUrl, '/users/get/all/'));
    };

    grantAdmin = async (id: number, is_admin: boolean): Promise<AxiosResponse<any>> => {
        return await requestTemplate<any, any>(
            urljoin(this.apiUrl, `/users/grant_admin`),
            AxiosMethod.put,
            {},
            sessionStorage.getItem('access_token'),
            { id, is_admin },
        );
    };

    activateUser = async (id: number, is_active: boolean): Promise<AxiosResponse<any>> => {
        return await requestTemplate<any, any>(
            urljoin(this.apiUrl, `/users/activate`),
            AxiosMethod.put,
            {},
            sessionStorage.getItem('access_token'),
            { id, is_active },
        );
    };

    newUser = async (
        dataRegistration: DataRegistration,
    ): Promise<AxiosResponse<DataRegistration>> => {
        return await requestTemplate<DataRegistration, DataRegistration>(
            urljoin(this.apiUrl, '/users/create'),
            AxiosMethod.post,
            dataRegistration,
        );
    };

    updateUser = async (
        id: number,
        dataRegistration: DataRegistration,
    ): Promise<AxiosResponse<DataRegistration>> => {
        return await requestTemplate<any, DataRegistration>(
            urljoin(this.apiUrl, `/users/modify/${id}`),
            AxiosMethod.put,
            dataRegistration,
            sessionStorage.getItem('access_token'),
        );
    };

    updatePassword = async (
        id: number,
        password: any,
    ): Promise<AxiosResponse<DataRegistration>> => {
        return await requestTemplate<any, DataRegistration>(
            urljoin(this.apiUrl, `/users/change_pass/${id}`),
            AxiosMethod.put,
            password,
            sessionStorage.getItem('access_token'),
        );
    };

    checkIsAdmin = async (id: number): Promise<AxiosResponse<any>> => {
        return await axios.get(urljoin(this.apiUrl, `/users/get/${id}`), {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
        });
    };
}
