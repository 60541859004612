export const authContentEN = {
    login: 'Sign in',
    registration: 'Sign up',
    step: 'STEP',
    registrationFields: {
        first_name_ru: 'First name*',
        last_name_ru: 'Last name*',
        username: 'Login*',
        email: 'Email* ',
        phone_num: 'Phone*',
        company_str: 'Organisation*',
        position: 'Position*',
        usergroup: 'Client group*',
        func_area: 'Functional Area*',
        manager_email: 'Directorate representative e-mail',
        password: 'Create password*',
        passwordConfirm: 'Password confirm*',
        captcha: 'Enter the characters*',
    },
    loginFields: {
        username: 'Login',
        password: 'Password',
    },
    validate: {
        invalidFirstName: 'Invalid first name',
        invalidLastName: 'Invalid last name',
        invalidEmail: 'Invalid Email',
        invalidPhone: 'Invalid phone number',
        invalidCompany: 'Invalid Company',
        invalidPosition: 'Invalid position',
        invalidUsername: 'Invalid Username',
        invalidPassword: 'Invalid password',
        mismatchPassword: 'Password mismatch',
        invalidCaptcha: 'Invalid characters',
        required: 'Required field',
    },
    buttons: {
        next: 'NEXT',
        back: 'BACK',
        registration: 'SIGN UP',
        login: 'LOG IN',
        changePass: 'CHANGE PASSWORD',
        restorePassword: 'Forgot password?',
        sendEmail: 'Request password change',
        backToLogin: 'Go back to the login page',
    },
    footer: {
        event: 'FISU World University Games 2023',
        helper: 'Help & support',
    },
    notifications: {
        notActivate:
            'Your user account is not active. Please contact your administrator - support@ekat2023.com',
        notCorrect: 'Invalid username or password',
        registrationTitle: 'Registration',
        registrationSuccess: (username: string) => `User ${username} registered.\n
        A message about registration was sent to the specified e-mail, you need to open the received message and confirm your e-mail`,
        registrationError: (message: string) => `Registration error: ${message}`,
    },
    modal: {
        email: {
            success: 'A password change link has been sent to the specified email address',
            error: 'Error while password change',
        },
        password: {
            success: 'Password changed successfully',
            error: 'Error when changing password',
        },
        buttons: {
            yes: 'Ok',
            no: 'Нет',
        },
    },
};
