import { useStores } from '../../store';

const useMainPageOverlay = () => {
    const { mainPageOverlayStore, sidebarCollapsibleStore } = useStores();

    const checkOverlay = (item: any) => {
        const isSubMenuClicked = /sidebarSubMenu/gi.test(item.props.className);

        if (isSubMenuClicked) {
            mainPageOverlayStore.SET_MAIN_PAGE_OVERLAY_ACTIVE();
        } else {
            mainPageOverlayStore.SET_MAIN_PAGE_OVERLAY_INACTIVE();
        }
    };

    const deactivateOverlay = () => {
        if (sidebarCollapsibleStore.GET_SIDEBAR_IS_COLLAPSED)
            mainPageOverlayStore.SET_MAIN_PAGE_OVERLAY_INACTIVE();
    };

    return {
        checkOverlay,
        deactivateOverlay,
    };
};

export default useMainPageOverlay;
