import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Request from '../Request/Request';
import { useStores } from '../../../store';
import { parseItem } from '../utils/parseDictionaryItem';
import getLanguageContent from '../../../content';

const EditRequest = () => {
    const { authStore, applicationManagementStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const params = useParams() as any;

    const [initialValues, setInitialValues] = useState({} as any);
    const [rejReason, setRejReason] = useState();
    const [files, setFiles] = useState<Array<{ key: string; title: any }> | []>([]);
    const [contact, setContact] = useState('');
    const [rq_status, setRequestStatus] = useState({ en: 'Not saved', ru: 'Не сохранено' });
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        getRequestInfo();
    }, []);

    const getRequestInfo = () => {
        applicationManagementStore
            .fetchRq(params.id)
            .then(response => {
                if (response.status === 200) {
                    const header = response.data.header;
                    const rqFiles = JSON.parse(header.files);

                    setInitialValues({
                        EventID: header.event,
                        PeriodID: header.period,
                        OrganisationID: header.organisation,
                        Description: header.descr,
                        ContactID: header.contact,
                    });
                    setRejReason(header.rej_reason ? header.rej_reason : '');
                    setContact(parseItem(header.contact_full, configStore.lang));
                    rqFiles
                        ? setFiles(
                              Object.keys(rqFiles).map((key: string) => ({
                                  key,
                                  title: rqFiles[key as any],
                              })),
                          )
                        : setFiles([]);

                    applicationManagementStore
                        .fetchStatus(header.status)
                        .then(response => {
                            if (response.status === 200) {
                                const status = JSON.parse(response.data[0].attrib).status;

                                setRequestStatus(JSON.parse(response.data[0].name));
                                setEditable(
                                    status !== 6 &&
                                        status !== 8 &&
                                        (((status === 2 || status === 4) && authStore.isAdmin) ||
                                            status === 0 ||
                                            status === 1 ||
                                            status === 3 ||
                                            status === 5 ||
                                            status === 7),
                                );
                            }
                        })
                        .catch(err => console.error(err.message));
                }
            })
            .catch(err => console.error(err.message));
    };

    const submitForm = (values: any) => {
        applicationManagementStore.fetchUpdateRequest(params.id, { ...values }).then(response => {
            if (response.status === 201) {
                getRequestInfo();
            }
        });
    };

    if (initialValues.EventID) {
        return (
            <Request
                title={`${content.newRequest.titles.edit} ${params.id}`}
                contact={contact}
                created
                rq_status={rq_status}
                edited
                editable={editable}
                files={files}
                rq_id={params.id}
                rejReason={rejReason}
                initialValues={initialValues}
                getRequestInfo={getRequestInfo}
                submitForm={submitForm}
            />
        );
    } else return null;
};

export default observer(EditRequest);
