import axios, { AxiosResponse } from 'axios';
import urljoin from 'url-join';
import ConfigurationStore from '../store/configurationStore';
import { AxiosMethod, requestTemplate, fileDownload } from './helper';

import { logOut } from '../utils/auth';

export default interface UsersInterface {
    getData: (id: number) => Promise<AxiosResponse<any>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class SupportService implements UsersInterface {
    apiUrl = '';
    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url), config);
    };

    getData = async (): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/cls/mess/`), { code: 'support' });
    };

    getFiles = async (): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(urljoin(this.apiUrl, '/common/doc_files/'));
    };

    activateUser = async (id: number, is_active: boolean): Promise<AxiosResponse<any>> => {
        return await requestTemplate<any, any>(
            urljoin(this.apiUrl, `/users/activate`),
            AxiosMethod.put,
            {},
            sessionStorage.getItem('access_token'),
            { id, is_active },
        );
    };

    updateSupport = async (
        code: string,
        nd: number,
        data: { ru: string; en: string },
    ): Promise<AxiosResponse<{ ru: string; en: string }>> => {
        return await requestTemplate<any, { ru: string; en: string }>(
            urljoin(this.apiUrl, `/cls/mess_update/${code}/${nd}`),
            AxiosMethod.put,
            data,
            sessionStorage.getItem('access_token'),
        );
    };

    getDocument = async (file_id: number, name: string) => {
        await fileDownload(
            urljoin(this.apiUrl, `/common/get_file_content/?file_id=${file_id}`),
            name,
            sessionStorage.getItem('access_token'),
        );
    };

    updateSupportDocument = async (
        id: number,
        data: { ru: string; en: string },
    ): Promise<AxiosResponse<{ ru: string; en: string }>> => {
        return await requestTemplate<any, { ru: string; en: string }>(
            urljoin(this.apiUrl, `/common/doc_file/${id}`),
            AxiosMethod.put,
            data,
            sessionStorage.getItem('access_token'),
        );
    };

    deleteDocument = async (id: number): Promise<AxiosResponse> => {
        return await axios.delete(urljoin(this.apiUrl, `/common/doc_file/${id}/`), {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
        });
    };

    uploadDocument = async (
        file: any,
        descr_ru: string,
        descr_en: string,
    ): Promise<AxiosResponse> => {
        return await requestTemplate<any, any>(
            urljoin(this.apiUrl, '/common/doc_upload'),
            AxiosMethod.post,
            file,
            sessionStorage.getItem('access_token'),
            { descr_ru, descr_en },
        );
    };
}
