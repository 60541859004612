const searchDropdownContent: arrayResponseSearchType = [
    {
        value: 'Itar TASS',
        text: 'Itar TASS',
    },
    {
        value: 'CNN News',
        text: 'CNN News',
    },
    {
        value: 'ABC News',
        text: 'ABC News',
    },
];

export default searchDropdownContent;
