import { Layout, Select, Input, Badge, Avatar, Image, Button, Tooltip } from 'antd';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './AppHeader.module.scss';

import searchPartialMatch from '../../utils/searchPartialMatch';
import searchDropdownContent from '../../mock/searchDropdownContent';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';

import Icons from '../../pages/ApplicationManagement/icons';
import getLanguageContent from '../../content';

import { logOut } from '../../utils/auth';

const { Header } = Layout;
const { Option } = Select;

type handleSearchType = ((value: string) => void) | undefined;

const AppHeader = () => {
    const { configStore, sidebarCollapsibleStore, searchStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const generatePlaceholder = useCallback(() => {
        if (configStore.lang === 'ru') return 'Поиск';
        return 'Search';
    }, [configStore.lang]);

    const [data, setData] = useState<arrayResponseSearchType>(searchDropdownContent);

    const options = data.map(d => (
        <Option value={d.value} key={d.value}>
            {d.text}
        </Option>
    ));

    const handleSearch: handleSearchType = value => {
        const searchMatches = searchDropdownContent.filter(item =>
            searchPartialMatch(item.text, value),
        );
        setData(searchMatches);
    };

    const handlerCollapsible = () => {
        sidebarCollapsibleStore.SET_TOGGLE_SIDEBAR_COLLAPSED();
    };

    return (
        <Header className={styles.AppHeader}>
            <div className={styles.collapseIconWrapper} onClick={handlerCollapsible}>
                <svg
                    className={styles.collapseIcon}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21 8L15.75 12L21 16"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 4.5H3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 19.5H3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11 9.5H3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11 14.5H3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <div className={styles.searchBarWrapper}>
                <div className={styles.searchIconWrapper}>
                    <svg
                        className={styles.searchIcon}
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14 14.3198L10.25 10.5698M11 6.81982C11 9.30511 8.98528 11.3198 6.5 11.3198C4.01472 11.3198 2 9.30511 2 6.81982C2 4.33454 4.01472 2.31982 6.5 2.31982C8.98528 2.31982 11 4.33454 11 6.81982Z"
                            stroke="#DFE0EB"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <Input
                    bordered={false}
                    allowClear={true}
                    className={styles.selectForm}
                    defaultValue={undefined}
                    placeholder={generatePlaceholder()}
                    value={searchStore.getCurrentSearch(configStore.currentPage as string)}
                    onChange={search => {
                        searchStore.setCurrentSearch(search.target.value);
                    }}
                />
            </div>
            <div className={styles.headerProfileBlock}>
                <div className={styles.splitLine} />
                <div className={styles.profileBlock}>
                    <span className={styles.profileBlockName}>
                        {sessionStorage.getItem(`contact_${configStore.lang}`)}
                    </span>
                    <Tooltip title={content.header.logout}>
                        <Button
                            shape="circle"
                            icon={<Icons.exitIcon />}
                            size="large"
                            onClick={() => {
                                logOut();
                                window.location.reload();
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </Header>
    );
};

export default observer(AppHeader);
