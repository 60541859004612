import React, { useState, useRef } from 'react';
import styles from './AttachFile.module.scss';

import { Modal, Divider, Button, Typography, Tag } from 'antd';
import getLanguageContent from '../../../content';
import { useStores } from '../../../store';

import { CloseIcon, PDFIcon } from '../../../components/Icons/Icons';

const AttachFile = (props: any) => {
    const { applicationManagementStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const { open, rq_id, files, setNewStatus } = props;

    const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

    const hiddenFileInput = useRef<HTMLInputElement>(null) as any;

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: any) => {
        setUploadedFiles([...uploadedFiles, ...event.target.files]);
    };

    const handleSave = async () => {
        const results = await Promise.all(uploadedFiles.map(file => onFileUpload(file)));

        if (results.filter(result => result.status === 200).length === uploadedFiles.length) {
            setNewStatus(6);
            handleClose(true);
        }
    };

    const onFileUpload = async (file: any) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        return await applicationManagementStore.fetchUploadDocument(formData, rq_id);
    };

    const handleClose = (saved: boolean) => {
        props.onClose(saved);
        setUploadedFiles([]);
    };

    return (
        <Modal
            visible={open}
            closable={false}
            width={420}
            onCancel={() => handleClose(false)}
            footer={null}
            className={styles.modalWrapper}
        >
            <div className={styles.header}>
                <h3 className={styles.title}>{content.appManagement.attach.title}</h3>
                <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.closeBtn}
                    onClick={() => handleClose(false)}
                />
            </div>

            <div className={styles.files}>
                <>
                    {files && files.length
                        ? files.map((file: any) => (
                              <Tag
                                  icon={<PDFIcon />}
                                  closable={false}
                                  className={styles.file}
                                  onClick={() => {
                                      applicationManagementStore
                                          .fetchDocument(file.key, file.title)
                                          .catch(err => console.error(err.message));
                                  }}
                                  onClose={e => {}}
                              >
                                  {file.title}
                              </Tag>
                          ))
                        : null}
                </>
                <>
                    {uploadedFiles.length
                        ? uploadedFiles.map((file: any, index: number) => (
                              <Tag
                                  icon={<PDFIcon />}
                                  closable
                                  className={styles.file}
                                  onClose={() => {
                                      const arr = [...[], ...uploadedFiles];
                                      arr.splice(index, 1);
                                      setUploadedFiles(arr);
                                  }}
                              >
                                  {file.name}
                              </Tag>
                          ))
                        : null}
                </>
            </div>

            <input
                ref={hiddenFileInput}
                type="file"
                style={{ display: 'none' }}
                onChange={handleChange}
            />

            <Button onClick={handleClick} className="btn btn--w100 btn-blue">
                {content.appManagement.attach.buttons.addFile}
            </Button>

            <Divider />

            <div className={styles.modalFooter}>
                <Button
                    className="btn btn--w100 btn-blue"
                    onClick={handleSave}
                    disabled={uploadedFiles.length === 0}
                >
                    {content.appManagement.attach.buttons.save}
                </Button>
                <Button
                    className="btn btn--w100 btn-white"
                    style={{ fontWeight: 500 }}
                    onClick={() => handleClose(false)}
                >
                    {content.appManagement.attach.buttons.cancel}
                </Button>
            </div>
        </Modal>
    );
};

export default AttachFile;
