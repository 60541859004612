import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Dropdown, Tooltip, Menu } from 'antd';

import { PDFIcon } from '../../components/Icons/Icons';
import { parseItemName } from './utils/parseDictionaryItem';

const parseItem: any =
    (lang: string): any =>
    (item: string): string => {
        const name = item ? JSON.parse(item).name : item;
        return name ? name[lang] : name;
    };

const setFiles = (object: any, getDocument: any) => {
    const files = JSON.parse(object);

    return (
        <Menu>
            {Object.keys(files).map((key: string) => (
                <Menu.Item onClick={() => getDocument(key, files[key])}>{files[key]}</Menu.Item>
            ))}
        </Menu>
    );
};

const filterData = (data: any) => (formatter: any) =>
    data.map((item: any) => ({
        text: formatter(item),
        value: formatter(item),
    }));

export default (lang: string, header: any, data: any, getDocument: any, statuses: any[]) => [
    {
        title: '',
        dataIndex: 'files',
        key: 'files',
        render: (files: Array<any>) => (files ? <PDFIcon style={{ fontSize: 24 }} /> : ''),
        width: '2%',
    },
    {
        title: header.request,
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id - b.id,
        width: '8%',
        align: 'left' as any,
    },
    {
        title: header.customer,
        dataIndex: 'organisation_full',
        key: 'organisation_full',
        width: '14%',
        filters: _.uniqWith(
            filterData(data)((item: any) => parseItem(lang)(item.organisation_full)),
            _.isEqual,
        ),
        onFilter: (value: any, record: any) => {
            return parseItem(lang)(record.organisation_full).indexOf(value) === 0;
        },
        filterSearch: true,
        sorter: (a: any, b: any) => a.organisation - b.organisation,
        render: (item: string) => {
            let text = parseItem(lang)(item);

            if (text.length > 48) {
                return (
                    <Tooltip placement="top" title={text}>
                        <span>{`${text.substring(0, 48)}...`}</span>
                    </Tooltip>
                );
            } else return text;
        },
    },
    {
        title: header.period,
        dataIndex: 'period_full',
        key: 'period_full',
        filters: _.uniqWith(
            filterData(data)((item: any) => parseItem(lang)(item.period_full)),
            _.isEqual,
        ),
        onFilter: (value: any, record: any) => {
            return parseItem(lang)(record.period_full).indexOf(value) === 0;
        },
        render: parseItem(lang),
        width: '18%',
    },
    {
        title: header.status,
        dataIndex: 'status_full',
        key: 'status_full',
        filters: _.uniqWith(
            statuses
                .slice()
                .sort((a: any, b: any) => a.id - b.id)
                .map((item: any) => {
                    const status = parseItemName(item.name, lang);
                    return {
                        text: status,
                        value: status,
                    };
                }),
            _.isEqual,
        ),
        onFilter: (value: any, record: any) => {
            return parseItem(lang)(record.status_full).indexOf(value) === 0;
        },
        sorter: (a: any, b: any) => a.status - b.status,
        render: parseItem(lang),
        width: '18%',
    },
    {
        title: header.licence,
        dataIndex: 'files',
        key: 'files',
        render: (files: object) =>
            files /*<a>PDF</a>*/ ? (
                <Dropdown overlay={setFiles(files, getDocument)} placement="bottomLeft">
                    <div>
                        <a>PDF</a>
                    </div>
                </Dropdown>
            ) : (
                <span>N/A</span>
            ),
        width: '2%',
        align: 'center' as any,
    },
    {
        title: header.owner,
        dataIndex: 'create_by_full',
        key: 'create_by_full',
        filters: _.uniqWith(
            filterData(data)((item: any) => parseItem(lang)(item.create_by_full)),
            _.isEqual,
        ),
        onFilter: (value: any, record: any) => {
            return parseItem(lang)(record.create_by_full).indexOf(value) === 0;
        },
        render: parseItem(lang),
        width: '10%',
    },
    {
        title: header.created,
        dataIndex: 'cdate',
        key: 'cdate',
        filters: _.uniqWith(
            filterData(data)((item: any) => moment(item.cdate).format('DD.MM.YYYY')),
            _.isEqual,
        ),
        onFilter: (value: any, record: any) => {
            return moment(record.cdate).format('DD.MM.YYYY').indexOf(value) === 0;
        },
        sorter: (a: any, b: any) => moment(a.cdate).valueOf() - moment(b.cdate).valueOf(),
        render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
        defaultSortOrder: 'descend' as any,
        width: '10%',
    },
    {
        title: header.description,
        dataIndex: 'descr',
        key: 'descr',
        render: (text: string) => {
            if (text.length > 60) {
                return (
                    <Tooltip placement="top" title={text}>
                        <span>{`${text.substring(0, 60)}...`}</span>
                    </Tooltip>
                );
            } else return text;
        },
        width: '25%',
    },
];
