import { FormFieldsInterface } from '../../../../components/FormComponent/FormComponent';
import { ContentType, Languages } from '../../../../content';
import { DictionaryItem } from '../../../../service/models/dictionaryItem';
import { parseDictionaryItemName } from '../../utils/parseDictionaryItem';

export const getNewServiceFields = (
    figureType: string | null,
    content: ContentType,
    servicesDictionary: DictionaryItem[],
    objectsDictionary: DictionaryItem[],
    standartsDictionary: DictionaryItem[],
    typesOfUseDictionary: DictionaryItem[],
    typeTuneDictionary: DictionaryItem[],
    rules: any,
    tooltip: any,
    lang: Languages,
    typetun: null | number,
    onChange: any,
): Array<FormFieldsInterface> => {
    const validation = (ruleName: string) => (rule: any, value: any, callback: any) => {
        if (!rules[ruleName].test(value)) {
            const validationMessage = content.newRequest.validate as any;

            callback(validationMessage[ruleName]);
        } else {
            callback();
        }
    };

    const arrValidation = (ruleName: string) => (rule: any, arr: string[], callback: any) => {
        const validatedArr = arr.map((value: string) => {
            return rules[ruleName].test(value);
        });

        const validated = !!validatedArr.length
            ? validatedArr.reduceRight((prev: boolean, curr: boolean) => prev && curr)
            : true;
        const validationMessage = content.newRequest.validate as any;

        validated ? callback() : callback(validationMessage[ruleName]);
    };

    const fields = {
        ObjectID: {
            key: 'ObjectID',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.newRequest.modal.object,
                required: true,
                filterByOptionName: 'label',
                span: 12,
            },
            proposedData: parseDictionaryItemName(objectsDictionary, lang),
        },
        ReceiveFrequence: {
            key: 'PreferredReceiveFrequence',
            classNameField: 'spectrumField',
            meta: {
                type: 'tagSelect',
                placeholder: content.newRequest.modal.freceive,
                validation: arrValidation(`frequence`),
                tooltip: tooltip.frequence,
                required: typetun === 2201,
                span: 12,
            },
        },
        TransmitFrequence: {
            key: 'PreferredTransmitFrequence',
            classNameField: 'spectrumField',
            meta: {
                type: 'tagSelect',
                placeholder: content.newRequest.modal.ftransmit,
                validation: arrValidation(`frequence`),
                tooltip: tooltip.frequence,
                required: typetun === 2201,
                span: 12,
            },
        },
        NominalReceiveFrequence: {
            key: 'PreferredReceiveFrequence',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.nfreceive,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 12,
            },
        },
        NominalTransmitFrequence: {
            key: 'PreferredTransmitFrequence',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.nftransmit,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 12,
            },
        },
        EmissionClass: {
            key: 'EmissionClass',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.eclass,
                validation: validation(`eclass`),
                tooltip: tooltip.eclass,
                required: true,
                span: 12,
            },
        },
        EquipmentModel: {
            key: 'EquipmentModel',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.model,
                required: true,
                span: 12,
            },
        },
        Manufacturer: {
            key: 'Manufacturer',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.brand,
                required: true,
                span: 12,
            },
        },
        EquipmentQuantity: {
            key: 'EquipmentQuantity',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.quant,
                validation: validation(`quantity`),
                tooltip: tooltip.quantity,
                required: true,
                span: 12,
            },
        },
        FrequensesQuantity: {
            key: 'FrequensesQuantity',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.fquant,
                validation: validation(`quantity`),
                tooltip: tooltip.quantity,
                required: true,
                span: 12,
            },
        },
        TuningRangeReceive: {
            key: 'TuningRange',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.newRequest.modal.franger,
                span: 12,
            },
        },
        TuningRangeTransmit: {
            key: 'TuningRangeTransmit',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.newRequest.modal.franget,
                span: 12,
            },
        },
        TuningReceiveRangeFrom: {
            key: 'TuningReceiveRangeFrom',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.frangerfrom,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 6,
            },
        },
        TuningReceiveRangeTo: {
            key: 'TuningReceiveRangeTo',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.frangerto,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 6,
            },
        },
        TuningTransmitRangeFrom: {
            key: 'TuningTransmitRangeFrom',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.frangetfrom,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 6,
            },
        },
        TuningTransmitRangeTo: {
            key: 'TuningTransmitRangeTo',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.frangetto,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 6,
            },
        },
        ChannelSize: {
            key: 'ChannelSize',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.chsize,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: true,
                span: 12,
            },
        },
        Duplex: {
            key: 'Duplex',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.duplex,
                validation: validation(`frequence`),
                tooltip: tooltip.frequence,
                required: false,
                span: 12,
                pattern: /^[0-9.,]*$/,
            },
        },
        MaxPower: {
            key: 'MaxPower',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.maxpower,
                required: false,
                span: 12,
                pattern: /^[0-9.,]*$/,
            },
        },
        TypeOfUse: {
            key: 'TypeOfUse',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.newRequest.modal.usetype,
                required: true,
                filterByOptionName: 'label',
                span: 12,
            },
            proposedData: parseDictionaryItemName(typesOfUseDictionary, lang),
        },
        TipeOfTuning: {
            key: 'TipeOfTuning',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.newRequest.modal.typetun,
                required: true,
                filterByOptionName: 'label',
                span: 12,
                onChange: onChange,
            },
            proposedData: parseDictionaryItemName(typeTuneDictionary, lang),
        },
        Comment: {
            key: 'Comment',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.newRequest.modal.comment,
                required: false,
                span: 12,
            },
        },
        ChannelStep: {
            key: 'ChannelStep',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.chstep,
                validation: validation(`chstep`),
                tooltip: tooltip.chstep,
                required: true,
                span: 12,
            },
        },
        SatteliteName: {
            key: 'SatteliteName',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.satname,
                required: false,
                span: 12,
            },
        },
        OrbitDegrees: {
            key: 'OrbitDegrees',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.georbit,
                required: false,
                span: 12,
            },
        },
        Latitude: {
            key: 'Latitude',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.lat,
                validation: validation(`coordinate`),
                tooltip: tooltip.coordinate,
                required: false,
                span: 12,
            },
        },
        Longitude: {
            key: 'Longitude',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.long,
                validation: validation(`coordinate`),
                tooltip: tooltip.coordinate,
                required: false,
                span: 12,
            },
        },
        OutputPower: {
            key: 'OutputPower',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.outpower,
                required: false,
                span: 12,
            },
        },
        AntennaDiameter: {
            key: 'AntennaDiameter',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.antd,
                validation: validation(`float`),
                tooltip: tooltip.quantity,
                required: false,
                span: 12,
            },
        },
        AntennaGainTransmit: {
            key: 'AntennaGainTransmit',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.antgaint,
                required: false,
                span: 12,
            },
        },
        AntennaGainReceive: {
            key: 'AntennaGainReceive',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.antgainr,
                required: false,
                span: 12,
            },
        },
        AntennaHeight: {
            key: 'AntennaHeight',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.antheight,
                required: false,
                span: 12,
            },
        },
        OutputPowerA: {
            key: 'OutputPowerA',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.outpower_a,
                required: true,
                span: 12,
            },
        },
        OutputPowerB: {
            key: 'OutputPowerB',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.outpower_b,
                required: true,
                span: 12,
            },
        },
        AntennaGainA: {
            key: 'AntennaGainA',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.again_a,
                required: false,
                span: 12,
            },
        },
        AntennaGainB: {
            key: 'AntennaGainB',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.newRequest.modal.again_b,
                required: false,
                span: 12,
            },
        },
        StandartID: {
            key: 'StandartID',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.newRequest.modal.standart,
                required: true,
                filterByOptionName: 'label',
                span: 12,
            },
            proposedData: parseDictionaryItemName(standartsDictionary, lang),
        },
        SSID: {
            key: 'SSID',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.ssid,
                required: false,
                span: 12,
            },
        },
        MACAddress: {
            key: 'MACAddress',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.newRequest.modal.macaddress,
                required: false,
                span: 12,
            },
        },
    };

    switch (figureType) {
        case 'ft1':
            return [
                fields.ObjectID,
                fields.ReceiveFrequence,
                fields.EquipmentModel,
                fields.TransmitFrequence,
                fields.Manufacturer,
                fields.EmissionClass,
                fields.FrequensesQuantity,
                fields.TuningRangeReceive,
                fields.EquipmentQuantity,
                fields.TuningReceiveRangeFrom,
                fields.TuningReceiveRangeTo,
                fields.TypeOfUse,
                fields.TuningRangeTransmit,
                fields.TipeOfTuning,
                fields.TuningTransmitRangeFrom,
                fields.TuningTransmitRangeTo,
                fields.MaxPower,
                fields.ChannelSize,
                fields.Duplex,
                fields.Comment,
                fields.ChannelStep,
            ];

        case 'ft2':
            return [
                fields.ObjectID,
                fields.NominalReceiveFrequence,
                fields.NominalTransmitFrequence,
                fields.EmissionClass,
                fields.EquipmentModel,
                fields.SatteliteName,
                fields.OrbitDegrees,
                fields.Latitude,
                fields.Longitude,
                fields.OutputPower,
                fields.AntennaDiameter,
                fields.AntennaGainTransmit,
                fields.AntennaGainReceive,
                fields.Comment,
                fields.AntennaHeight,
            ];

        case 'ft3':
            return [
                fields.ObjectID,
                fields.NominalReceiveFrequence,
                fields.NominalTransmitFrequence,
                fields.EmissionClass,
                fields.EquipmentModel,
                fields.SatteliteName,
                fields.OrbitDegrees,
                fields.OutputPower,
                fields.AntennaDiameter,
                fields.AntennaGainTransmit,
                fields.AntennaGainReceive,
                fields.Comment,
            ];

        case 'ft4':
            return [
                fields.ObjectID,
                fields.ReceiveFrequence,
                fields.TransmitFrequence,
                fields.EmissionClass,
                fields.EquipmentModel,
                fields.Manufacturer,
                fields.EquipmentQuantity,
                fields.FrequensesQuantity,
                fields.Duplex,
                fields.OutputPowerA,
                fields.OutputPowerB,
                fields.AntennaGainA,
                fields.AntennaGainB,
                fields.TuningRangeReceive,
                fields.ChannelSize,
                fields.TuningReceiveRangeFrom,
                fields.TuningReceiveRangeTo,
                fields.ChannelStep,
                fields.TuningRangeTransmit,
                fields.TypeOfUse,
                fields.TuningTransmitRangeFrom,
                fields.TuningTransmitRangeTo,
                fields.TipeOfTuning,
                fields.Comment,
            ];

        case 'ft5':
            return [
                fields.ObjectID,
                fields.ReceiveFrequence,
                fields.EmissionClass,
                fields.TransmitFrequence,
                fields.Manufacturer,
                fields.StandartID,
                fields.EquipmentModel,
                fields.TuningRangeReceive,
                fields.Duplex,
                fields.TuningReceiveRangeFrom,
                fields.TuningReceiveRangeTo,
                fields.TypeOfUse,
                fields.TuningRangeTransmit,
                fields.ChannelSize,
                fields.TuningTransmitRangeFrom,
                fields.TuningTransmitRangeTo,
                fields.SSID,
                fields.ChannelStep,
                fields.MACAddress,
                fields.Comment,
            ];

        default:
            return [];
    }
};
