import { useState } from 'react';

const useBreakpoints = (size: number) => {
    const [breakSize, setBreakSize] = useState(window.innerWidth > size);

    const handleResize = () => {
        if (window.innerWidth > size) {
            setBreakSize(true);
        } else {
            setBreakSize(false);
        }
    };

    window.addEventListener('resize', handleResize);

    return {
        destroy: () => window.removeEventListener('resize', handleResize),
        isBreakpoint: breakSize,
    };
};

export default useBreakpoints;
