const ApplicationManagement = 'ApplicationManagement';
const HelpAndSupport = 'HelpAndSupport';
const ReportsAndStatistics = 'ReportsAndStatistics';
const Users = 'Users';
const Notifications = 'Notifications';
const Monitoring = 'Monitoring';
const References = 'References';

export const admin = (key: string) => {
    switch (key) {
        case ApplicationManagement:
            return '/application-management/';

        case HelpAndSupport:
            return '/support/';

        case ReportsAndStatistics:
            return '/reports/';

        case Users:
            return '/users/';

        case Notifications:
            return '/notifications/';

        case Monitoring:
            return '/monitoring/';

        case References:
            return '/references/';

        default:
            localStorage.setItem('app_menu', ApplicationManagement);
            return '/application-management/';
    }
};

export const regular = (key: string) => {
    switch (key) {
        case ApplicationManagement:
            return '/application-management/';

        case HelpAndSupport:
            return '/support/';

        case ReportsAndStatistics:
            return '/reports/';

        default:
            localStorage.setItem('app_menu', ApplicationManagement);
            return '/application-management/';
    }
};
