import React, { useState, useRef } from 'react';
import styles from './AttachFile.module.scss';

import { Modal, Divider, Button, Typography, Tag, Form } from 'antd';
import getLanguageContent from '../../../content';
import { useStores } from '../../../store';

import { CloseIcon, AttachIcon } from '../../../components/Icons/Icons';
import { Input } from '../../../components/Inputs/Inputs';

const AttachFile = (props: any) => {
    const { supportStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const { open, setFiles } = props;

    const [form] = Form.useForm();

    const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

    const hiddenFileInput = useRef<HTMLInputElement>(null) as any;

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: any) => {
        setUploadedFiles([...uploadedFiles, ...event.target.files]);
    };

    const handleSave = () => {
        form.validateFields().then((values: any) => {
            onFileUpload(uploadedFiles[0], values.descr_ru, values.descr_en);
        });
    };

    const onFileUpload = (file: any, descr_ru: string, descr_en: string) => {
        const formData = new FormData();

        formData.append('file', file, file.name);

        supportStore
            .fetchUploadDocument(formData, descr_ru, descr_en)
            .then(response => {
                if (response.status === 200) {
                    handleClose(false);
                    supportStore
                        .fetchFiles()
                        .then((result: any) => {
                            setFiles(result.data);
                        })
                        .catch(err => console.error(err.message));
                }
            })
            .catch(err => console.error(err.message));
    };

    const handleClose = (saved: boolean) => {
        props.onClose(saved);
        setUploadedFiles([]);
    };

    return (
        <Modal
            visible={open}
            closable={false}
            width={420}
            onCancel={() => handleClose(false)}
            footer={null}
            className={styles.modalWrapper}
        >
            <div className={styles.header}>
                <h3 className={styles.title}>{content.support.attach.title}</h3>
                <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.closeBtn}
                    onClick={() => handleClose(false)}
                />
            </div>

            <div className={styles.files}>
                <>
                    {uploadedFiles.length
                        ? uploadedFiles.map((file: any, index: number) => (
                              <Tag
                                  icon={<AttachIcon />}
                                  closable
                                  className={styles.file}
                                  onClose={() => {
                                      const arr = [...[], ...uploadedFiles];
                                      arr.splice(index, 1);
                                      setUploadedFiles(arr);
                                  }}
                              >
                                  {file.name}
                              </Tag>
                          ))
                        : null}
                </>
            </div>

            <input
                ref={hiddenFileInput}
                type="file"
                style={{ display: 'none' }}
                onChange={handleChange}
            />

            {!uploadedFiles.length ? (
                <Button onClick={handleClick} className="btn btn--w100 btn-blue">
                    {content.support.attach.buttons.addFile}
                </Button>
            ) : (
                <Form form={form}>
                    <Form.Item
                        name={'descr_ru'}
                        rules={[{ required: true, message: content.auth.validate.required }]}
                    >
                        <Input
                            placeholder={content.support.placeholders.description_ru}
                            className={styles.fullWidthInput}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'descr_en'}
                        rules={[{ required: true, message: content.auth.validate.required }]}
                    >
                        <Input
                            placeholder={content.support.placeholders.description_en}
                            className={styles.fullWidthInput}
                        />
                    </Form.Item>
                </Form>
            )}

            <Divider />

            <div className={styles.modalFooter}>
                <Button
                    className="btn btn--w100 btn-blue"
                    onClick={handleSave}
                    disabled={uploadedFiles.length === 0}
                >
                    {content.support.attach.buttons.save}
                </Button>
                <Button
                    className="btn btn--w100 btn-white"
                    style={{ fontWeight: 500 }}
                    onClick={() => handleClose(false)}
                >
                    {content.support.attach.buttons.cancel}
                </Button>
            </div>
        </Modal>
    );
};

export default AttachFile;
