import React from 'react';
import { observer } from 'mobx-react';

import { Modal } from 'antd';

import { useStores } from '../../store';
import getLanguageContent from '../../content';

const ErrorModal = () => {
    const { errorStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    return (
        <Modal
            visible={errorStore.error && errorStore.error.length}
            title={content.appManagementError.title}
            okText={content.appManagementError.close}
            onOk={() => errorStore.setError([])}
            onCancel={() => errorStore.setError([])}
            cancelButtonProps={{ style: { visibility: 'hidden' } }}
            zIndex={2000}
            closable
        >
            {errorStore.error &&
                errorStore.error.map((error: any) => (
                    <p>
                        {error.loc && <b>{`${error.loc[1]}: `}</b>}
                        {error.msg}
                    </p>
                ))}
        </Modal>
    );
};

export default observer(ErrorModal);
