export default {
    title: 'References',
    header: {
        enName: 'Name (eng.)',
        ruName: 'Name (rus.)',
        enDescr: 'Description (eng.)',
        ruDescr: 'Description (rus.)',
    },
    validate: {
        invalid: 'Value does not match template',
    },
    buttons: {
        create: 'Create',
        save: 'Save',
        close: 'Close',
        lock: 'Lock',
        unlock: 'Unlock',
        archive: 'Archive',
        xls: 'xls-file',
        pdf: 'pdf-file',
    },
    modal: {
        close: 'Do you really want to archive record?',
        buttons: {
            yes: 'Yes',
            no: 'No',
        },
    },
};
