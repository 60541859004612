export default {
    title: 'Notifications',
    fields: {
        templates: 'Notifications template',
        sla: 'SLA settings',
    },
    placeholders: {
        type: 'Notifications type',
        information_ru: 'Text on Russian',
        information_en: 'Text on English',
        approvalDays: 'Approval period, days',
        approvalNotification: 'Notification before approve, days',
        notificationInterval: 'Late appointments notification period, days',
    },
    modal: {
        buttons: {
            yes: 'Yes',
            no: 'No',
        },
    },
    logoutNotification: 'Attention! The authorization time expires on 10 minutes',
    errorTitle: 'Error',
    informationTitle: 'Information',
};
