import {
    Table,
    PageHeader,
    Button,
    Radio,
    Form,
    Row,
    Col,
    Select,
    Tooltip,
    Breadcrumb,
    Dropdown,
    Modal,
    Menu,
} from 'antd';
import { Input, Selection } from '../../../components/Inputs/Inputs';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './Request.module.scss';
import MouseTooltip from '../../../components/Tooltip/MouseTooltip';

import { useStores } from '../../../store';
import cs from '../../../utils/composeStyles';

import getLanguageContent from '../../../content';

import columns from './columns';

import { UploadOutlined, DeleteOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';

import Service from './Service/Service';
import AttachFile from '../AttachFile/AttachFile';
import File from './File/File';
import Error from '../../../components/Error/Error';

import Icons from '../icons';

import { parseItemName, parseRqStatus } from '../utils/parseDictionaryItem';
import RejectDialog from '../RejectDialog/RejectDialog';

const NewRequest = (props: any) => {
    const { authStore, applicationManagementStore, configStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const { organizations, events, periods, statuses } = dictionaryStore;
    const [form] = Form.useForm();

    const {
        title,
        created,
        files,
        rq_status,
        edited,
        editable,
        rq_id,
        submitForm,
        rejReason,
        initialValues,
        contact,
        getRequestInfo,
    } = props;

    const [changed, setChanged] = useState(false);
    const [modal, setModal] = useState(false);
    const [attach, setAttach] = useState(false);
    const [serviceList, setService] = useState([]);
    const [avStat, setAvStat] = useState([]);
    const [initialServiceValues, setInitialServiceValues] = useState({});
    const [reject, setReject] = useState(false);
    const [rejectStatus, setRejectStatus] = useState('');
    const [tooltip, setTooltip] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (rq_id) {
            getServiceList();
            applicationManagementStore
                .fetchRqStatus(rq_id as number, { rq_id: rq_id, available_only: true })
                .then(response => {
                    if (response.status === 200) {
                        setAvStat(response.data);
                    }
                });
        }
    }, []);

    const onModalClose = () => {
        setModal(!modal);
        getServiceList();
        updateRequestList();
        setInitialServiceValues({});
    };

    const onAttachClose = () => {
        setAttach(!attach);
        getRequestInfo();
    };

    const onRejectClose = () => {
        setReject(!reject);
        getRequestInfo();
        updateRequestList();
    };

    const getServiceList = () => {
        applicationManagementStore.fetchRq(rq_id).then(response => {
            if (response.status === 200) {
                const data = response.data;

                setService([
                    ...data.ft1.map((service: any) => Object.assign(service, { ft: 'ft1' })),
                    ...data.ft2.map((service: any) => Object.assign(service, { ft: 'ft2' })),
                    ...data.ft3.map((service: any) => Object.assign(service, { ft: 'ft3' })),
                    ...data.ft4.map((service: any) => Object.assign(service, { ft: 'ft4' })),
                    ...data.ft5.map((service: any) => Object.assign(service, { ft: 'ft5' })),
                ] as any);
            }
        });
    };

    const handlerNewServiceClick = () => {
        setModal(true);
    };

    const handlerEditServiceClick = (service: any) => {
        setInitialServiceValues({
            id: service.id,
            ft: service.ft,
            ServiceID: service.service,
            ObjectID: service.object,
            PreferredReceiveFrequence: service.freceive,
            PreferredTransmitFrequence: service.ftransmit,
            EquipmentModel: service.model,
            Manufacturer: service.brand,
            EmissionClass: service.eclass,
            FrequensesQuantity: service.fquant,
            TuningRangeReceive: service.frange,
            EquipmentQuantity: service.quant,
            TuningReceiveRangeFrom: service.frangerfrom,
            TuningReceiveRangeTo: service.frangerto,
            TypeOfUse: service.usetype,
            TipeOfTuning: service.typetun,
            TuningRangeTransmit: service.franget,
            MaxPower: service.maxpower,
            TuningTransmitRangeFrom: service.frangetfrom,
            TuningTransmitRangeTo: service.frangetto,
            ChannelSize: service.chsize,
            Duplex: service.duplex,
            ChannelStep: service.chstep,
            Comment: service.comment,
            SatteliteName: service.satname,
            OrbitDegrees: service.georbit,
            Latitude: service.lat,
            Longitude: service.long,
            OutputPower: service.outpower,
            AntennaDiameter: service.antd,
            AntennaGainTransmit: service.antgaint,
            AntennaGainReceive: service.antgainr,
            AntennaHeight: service.antheight,
            OutputPowerA: service.outpower_a,
            OutputPowerB: service.outpower_b,
            AntennaGainA: service.again_a,
            AntennaGainB: service.again_b,
            StandartID: service.standart,
            SSID: service.ssid,
            MACAddress: service.macaddress,
        });
        setModal(true);
    };

    const onSubmitForm = () => {
        form.validateFields().then((values: any) => {
            let edited = {} as any;

            for (let key in values) {
                if (
                    values.hasOwnProperty(key) &&
                    (form.isFieldTouched(key as string) || key === 'OrganisationID')
                ) {
                    edited[key] = values[key];
                }
            }
            submitForm(edited);
            setChanged(false);
        });
    };

    const setNewStatus = (status: number) => {
        applicationManagementStore
            .fetchRqStatus(rq_id as number, { rq_id, available_only: false, to_status: status })
            .then(response => {
                if (response.status === 200) {
                    updateRequestList();
                    getRequestInfo();
                }
            });
    };

    const updateRequestList = () => {
        applicationManagementStore
            .fetchList()
            .then(response => {
                if (response.status === 200) {
                    applicationManagementStore
                        .fetchRqStatus(rq_id as number, { rq_id: rq_id, available_only: true })
                        .then(response => {
                            if (response.status === 200) {
                                getRequestInfo();
                                setAvStat(response.data);
                            }
                        });
                }
            })
            .catch(err => console.error(err.message));
    };

    const onSetStatus = (item: any) => {
        switch (item.key) {
            case '3':
                setRejectStatus(item.key);
                setReject(true);
                break;

            case '5':
                setRejectStatus(item.key);
                setReject(true);
                break;

            case '6':
                setAttach(true);
                break;

            default:
                Modal.confirm({
                    title: content.appManagement.modal.status,
                    okText: content.appManagement.modal.buttons.yes,
                    cancelText: content.appManagement.modal.buttons.no,
                    onOk() {
                        setNewStatus(item.key);
                    },
                });
        }
    };

    const handlerServiceDeleteClick = (ft: string, id: number) => {
        Modal.confirm({
            title: content.appManagement.modal.service,
            okText: content.appManagement.modal.buttons.yes,
            cancelText: content.appManagement.modal.buttons.no,
            onOk() {
                applicationManagementStore
                    .fetchDeleteService(ft, id)
                    .then(response => {
                        if (response.status === 200) getServiceList();
                    })
                    .catch(err => console.error(err.message));
            },
        });
    };

    const handlerDeleteClick = () => {
        Modal.confirm({
            title: content.appManagement.modal.delete,
            okText: content.appManagement.modal.buttons.yes,
            cancelText: content.appManagement.modal.buttons.no,
            onOk() {
                setNewStatus(8);
            },
        });
    };

    const handlerDocumentDeleteClick = (file_id: number) => {
        Modal.confirm({
            title: content.appManagement.modal.document,
            okText: content.appManagement.modal.buttons.yes,
            cancelText: content.appManagement.modal.buttons.no,
            onOk() {
                applicationManagementStore
                    .fetchDeleteDocument(rq_id, file_id)
                    .then(() => {
                        getRequestInfo();
                        updateRequestList();
                    })
                    .catch(err => console.error(err.message));
            },
        });
    };

    const handlerExportClick = () => {
        applicationManagementStore
            .fetchRqExportFile(rq_id)
            .catch(err => console.error(err.message));
    };

    const menu = (
        <Menu onClick={onSetStatus} className={styles.actions}>
            {dictionaryStore.statuses.map(action => {
                const attrib = parseRqStatus(action as any);

                if (attrib.onaction) {
                    return (
                        <Menu.Item
                            key={attrib.status}
                            disabled={avStat.indexOf(attrib.status as never) === -1}
                        >
                            {parseItemName(action.descr, configStore.lang)}
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>
                            <Link to="/application-management">{content.appManagement.title}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <div className={styles.tableWrapper}>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={() => {
                        setChanged(true);
                    }}
                >
                    <Row gutter={24} style={{ marginBottom: 8 }}>
                        <Col span={6}>
                            <Form.Item
                                name={'EventID'}
                                className="form__item"
                                key={'EventID'}
                                rules={[
                                    {
                                        required: true,
                                        message: content.newRequest.validate.required,
                                    },
                                ]}
                            >
                                <Selection
                                    className={styles.antSelect}
                                    placeholder={content.newRequest.selects.event}
                                    disabled={!editable}
                                >
                                    {events.map(event => (
                                        <Select.Option value={event.id}>
                                            {parseItemName(event.name, configStore.lang)}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={'PeriodID'}
                                className="form__item"
                                key={'PeriodID'}
                                rules={[
                                    {
                                        required: true,
                                        message: content.newRequest.validate.required,
                                    },
                                ]}
                            >
                                <Selection
                                    className={styles.antSelect}
                                    placeholder={content.newRequest.selects.period}
                                    disabled={!editable}
                                >
                                    {periods.map(period => (
                                        <Select.Option value={period.id}>
                                            {parseItemName(period.name, configStore.lang)}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={'OrganisationID'}
                                className="form__item"
                                key={'OrganisationID'}
                                initialValue={Number(localStorage.getItem('company'))}
                                rules={[
                                    {
                                        required: true,
                                        message: content.newRequest.validate.required,
                                    },
                                ]}
                            >
                                <Selection
                                    className={styles.antSelect}
                                    placeholder={content.newRequest.selects.organization}
                                    disabled
                                >
                                    {organizations.map(organization => (
                                        <Select.Option value={organization.id}>
                                            {parseItemName(organization.name, configStore.lang)}
                                        </Select.Option>
                                    ))}
                                </Selection>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder={content.newRequest.selects.contact}
                                value={contact}
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Input
                                value={rejReason}
                                className={styles.antInput}
                                placeholder={content.newRequest.inputs.reason}
                                disabled={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'Description'}
                                className="form__item"
                                key={'Description'}
                            >
                                <Input
                                    placeholder={content.newRequest.inputs.description}
                                    disabled={!editable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: 17 }}>
                        {created && editable ? (
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    size="large"
                                    className={'btn-blue'}
                                    onClick={handlerNewServiceClick}
                                />
                                <span style={{ paddingLeft: 16, fontWeight: 'bold' }}>
                                    {content.newRequest.buttons.add}
                                </span>
                            </Col>
                        ) : null}
                        <div className={styles.status}>
                            <span>
                                {content.appManagement.status}:{' '}
                                {!created ? (
                                    <span style={{ color: '#FF4557', fontWeight: 'bold' }}>
                                        {rq_status[configStore.lang]}
                                    </span>
                                ) : (
                                    <span style={{ color: '#59A13F', fontWeight: 'bold' }}>
                                        {rq_status[configStore.lang]}
                                    </span>
                                )}
                            </span>
                        </div>
                    </Row>
                </Form>

                {created && serviceList.length ? (
                    <>
                        <Table
                            className={styles.table}
                            rowClassName={(record: any) =>
                                record.complete ? styles.greenRow : record.late ? styles.redRow : ''
                            }
                            dataSource={serviceList}
                            columns={columns(
                                configStore.lang,
                                content.newRequest,
                                editable,
                                tooltip,
                                handlerServiceDeleteClick,
                                handlerEditServiceClick,
                                setShowTooltip,
                            )}
                            pagination={{
                                showSizeChanger: false,
                                pageSize: 5,
                                position: ['bottomLeft'],
                            }}
                            onRow={record => {
                                return {
                                    onMouseEnter: () => {
                                        setShowTooltip(true);
                                        setTooltip(record.comment);
                                    },
                                    onMouseLeave: () => {
                                        setShowTooltip(false);
                                        setTooltip('');
                                    },
                                };
                            }}
                            showSorterTooltip={false}
                        />
                        <MouseTooltip
                            visible={showTooltip}
                            style={{ zIndex: 999 }}
                            offsetX={20}
                            offsetY={0}
                        >
                            {!!tooltip && (
                                <div className={'ant-tooltip'}>
                                    <div className={'ant-tooltip-inner'}>{tooltip}</div>
                                </div>
                            )}
                        </MouseTooltip>
                    </>
                ) : null}
            </div>

            <Radio.Group buttonStyle="solid" className={styles.buttons}>
                <Radio.Button
                    onClick={onSubmitForm}
                    className={'btn-blue'}
                    disabled={!changed || ((created || !edited) && !editable)}
                >
                    {
                        <span>
                            <UploadOutlined className={styles.icon} />
                            {content.newRequest.buttons.save}
                        </span>
                    }
                </Radio.Button>
                <Radio.Button
                    className={'btn-blue'}
                    disabled={avStat.indexOf(8 as never) === -1 || !created || !editable}
                    onClick={handlerDeleteClick}
                >
                    {
                        <span>
                            <DeleteOutlined className={styles.icon} />
                            {content.newRequest.buttons.delete}{' '}
                        </span>
                    }
                </Radio.Button>
                <Dropdown
                    trigger={['click']}
                    overlay={menu}
                    placement="bottomLeft"
                    disabled={!created || !editable}
                >
                    <Radio.Button className={cs(styles.revokeBtn, 'btn-blue')}>
                        {
                            <span>
                                <Icons.rknIcon className={styles.icon} disabled={!editable} />
                                {content.appManagement.buttons.actions}
                            </span>
                        }
                    </Radio.Button>
                </Dropdown>
                <Radio.Button
                    className={'btn-blue'}
                    disabled={!created}
                    onClick={handlerExportClick}
                >
                    {
                        <span>
                            <ExportOutlined className={styles.icon} />
                            {content.appManagement.buttons.export}
                        </span>
                    }
                </Radio.Button>
            </Radio.Group>

            <div className={styles.filesWrapper}>
                {files &&
                    files.map((file: any) => (
                        <File
                            id={file.key}
                            name={file.title}
                            getDocument={applicationManagementStore.fetchDocument}
                            deletable={authStore.isAdmin}
                            handleDelete={() => handlerDocumentDeleteClick(file.key)}
                        />
                    ))}
            </div>

            {modal && (
                <Service
                    open={modal}
                    rq_id={rq_id}
                    initialValues={initialServiceValues}
                    onClose={onModalClose}
                    editable={editable}
                />
            )}
            <AttachFile
                open={attach}
                rq_id={rq_id}
                files={files}
                setNewStatus={setNewStatus}
                onClose={onAttachClose}
            />
            <RejectDialog
                open={reject}
                rq_id={rq_id}
                status={rejectStatus}
                onClose={onRejectClose}
            />
            <Error />
        </div>
    );
};

export default observer(NewRequest);
