import { FormFieldsInterface } from '../../components/FormComponent/FormComponent';
import { ContentType, Languages } from '../../content';
import React from 'react';

export const getSupportFields = (
    admin: boolean,
    content: ContentType,
    lang: Languages,
    changed: boolean,
    onSubmitForm: any,
    onAttachFile: any,
): Array<FormFieldsInterface> => {
    const fields = {
        Information: {
            key: 'information',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.support.fields.information,
                span: 24,
            },
        },
        InformationInputRu: {
            key: 'support_1_ru',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.support.placeholders.information_ru,
                required: false,
                span: 24,
            },
        },
        InformationInputEn: {
            key: 'support_1_en',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.support.placeholders.information_en,
                required: false,
                span: 24,
            },
        },
        Phone: {
            key: 'phone',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.support.fields.phone,
                span: 4,
            },
        },
        PhoneNumber: {
            key: 'support_2_ru',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.support.placeholders.phone,
                required: true,
                span: 20,
                validation: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9()+-]*$/.test(value)) {
                        callback(content.auth.validate.invalidPhone);
                    } else {
                        callback();
                    }
                },
                style: { height: 45, minHeight: 45, resize: 'none' },
            },
        },
        Email: {
            key: 'email',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.support.fields.email,
                span: 4,
            },
        },
        EmailAddress: {
            key: 'support_3_ru',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.support.placeholders.email,
                required: true,
                span: 20,
                validation: (rule: any, value: any, callback: any) => {
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
                style: { height: 45, minHeight: 45, resize: 'none' },
            },
        },
        Material: {
            key: 'material',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.support.fields.material,
                span: 16,
            },
        },
        SaveButton: {
            key: 'saveButton',
            classNameField: 'spectrumField',
            meta: {
                type: 'button',
                placeholder: content.support.fields.save,
                span: 8,
                onClick: onSubmitForm,
                disabled: !changed,
            },
        },
        AddButton: {
            key: 'addButton',
            classNameField: 'spectrumField',
            meta: {
                type: 'button',
                placeholder: content.support.fields.addFile,
                span: 8,
                onClick: () => onAttachFile(true),
            },
        },
        Description: {
            key: 'description',
            classNameField: 'spectrumField',
            meta: {
                type: 'file',
                placeholder: content.support.fields.description,
                span: 8,
            },
        },
        Space16: {
            key: 'space',
            meta: {
                type: 'space',
                span: 16,
            },
        },
        Space8: {
            key: 'space',
            meta: {
                type: 'space',
                span: 8,
            },
        },
    };

    return admin
        ? [
              fields.Information,
              fields.InformationInputRu,
              fields.InformationInputEn,
              fields.Phone,
              fields.PhoneNumber,
              fields.Email,
              fields.EmailAddress,
              fields.Space8,
              fields.AddButton,
              fields.SaveButton,
          ]
        : [
              fields.Information,
              lang === 'ru' ? fields.InformationInputRu : fields.InformationInputEn,
              fields.Phone,
              fields.PhoneNumber,
              fields.Email,
              fields.EmailAddress,
          ];
};
