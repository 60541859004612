import React, { useEffect } from 'react';

import { Col, Form, Row, Select, Switch } from 'antd';
import { Input, Selection, TextArea } from '../../components/Inputs/Inputs';
import cs from '../../utils/composeStyles';
import styles from './References.module.scss';
import getLanguageContent from '../../content';
import { useStores } from '../../store';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { parseDictionaryItemName } from '../ApplicationManagement/utils/parseDictionaryItem';

const Attributes = (props: any) => {
    const { form, hidden } = props;
    const { configStore, referencesStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const { attributes } = referencesStore;

    return (
        <Row gutter={[20, 8]} style={{ marginLeft: '3.4%' }}>
            {Object.keys(attributes).map((title: string) => {
                const field = attributes[title];
                let itemField: any = undefined;

                if (field.type === 'textarea') {
                    itemField = (
                        <TextArea
                            disabled={hidden}
                            placeholder={title}
                            className={cs(field.classNameField as string, styles.fullWidthInput)}
                        />
                    );
                } else {
                    itemField = (
                        <Input
                            disabled={hidden}
                            placeholder={title}
                            className={styles.fullWidthInput}
                        />
                    );
                }
                if (field.type === 'int') {
                    itemField = (
                        <Input
                            disabled={hidden}
                            type={'number'}
                            placeholder={title}
                            className={cs(styles.fullWidthInput, styles.numberInput)}
                        />
                    );
                }
                if (field.type === 'bool') {
                    itemField = <Switch disabled={hidden} />;
                }
                if (field.link) {
                    itemField = (
                        <Selection
                            disabled={hidden}
                            placeholder={title}
                            maxTagTextLength={38}
                            allowClear
                            className={cs('spectrumField', styles.fullWidthInput)}
                            showSearch
                            filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA: any, optionB: any) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {parseDictionaryItemName(
                                dictionaryStore.dictionaries[field.link],
                                configStore.lang,
                            )
                                ?.sort((a: any, b: any) =>
                                    a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()),
                                )
                                .map((item: any) => (
                                    <Select.Option
                                        value={item.value}
                                        key={`${field.key}_proposedData`}
                                    >
                                        {item.is_hidden ? (
                                            <span style={{ color: '#ff4d4f' }}>{item.label}</span>
                                        ) : (
                                            item.label
                                        )}
                                    </Select.Option>
                                ))}
                        </Selection>
                    );
                }
                return (
                    <Col>
                        {field.type === 'bool' ? (
                            <Form.Item
                                name={['attrib', title]}
                                valuePropName={'checked'}
                                label={title}
                                className={cs('form__item', styles.formItem)}
                                key={field.key}
                                {...field.formItemParams}
                            >
                                {itemField}
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name={['attrib', title]}
                                className={cs('form__item', styles.formItem)}
                                key={field.key}
                                rules={(() => {
                                    const rulesArr = [];
                                    if (!!field.req)
                                        rulesArr.push({
                                            required: !!field.req,
                                            message: content.auth.validate.required,
                                        });
                                    if (field.regex) {
                                        const regexp = new RegExp(field.regex);
                                        const validation = (
                                            rule: any,
                                            value: any,
                                            callback: any,
                                            values: any,
                                        ) => {
                                            if (!regexp.test(value)) {
                                                callback(content.references.validate.invalid);
                                            } else {
                                                callback();
                                            }
                                        };
                                        rulesArr.push({
                                            validator: (
                                                rule: RuleObject,
                                                value: StoreValue,
                                                callback: (error?: string) => void,
                                            ) =>
                                                value
                                                    ? regexp &&
                                                      validation(
                                                          rule,
                                                          value,
                                                          callback,
                                                          form?.getFieldsValue(),
                                                      )
                                                    : Promise.resolve(),
                                        });
                                    }
                                    return rulesArr;
                                })()}
                                {...field.formItemParams}
                            >
                                {itemField}
                            </Form.Item>
                        )}
                    </Col>
                );
            })}
        </Row>
    );
};

export default Attributes;
