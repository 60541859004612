import axios, { AxiosResponse } from 'axios';
import ConfigurationStore from '../store/configurationStore';
import urljoin from 'url-join';

import { logOut } from '../utils/auth';
import { fileDownload } from './helper';

interface ReportsServiceInterface {
    getList: (type: string) => Promise<AxiosResponse<any[]>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class ReportsService implements ReportsServiceInterface {
    apiUrl = '';

    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    private axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url, '/'), config);
    };

    getList = async (type: string, begin?: any, end?: any): Promise<AxiosResponse<any[]>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/report/svod/${type}`), {
            cdate_from: begin,
            cdate_to: end,
        });
    };

    getTotals = async (): Promise<AxiosResponse<any>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/report/totals`));
    };

    getListFile = async (type: string, xls: boolean, begin?: any, end?: any) => {
        await fileDownload(
            urljoin(this.apiUrl, `report/svod/${type}/`),
            `reports-${type}.${xls ? 'xls' : 'pdf'}`,
            sessionStorage.getItem('access_token'),
            {
                cdate_from: begin,
                cdate_to: end,
                to_xls: xls,
                to_pdf: !xls,
            },
        );
    };
}

export default ReportsService;
