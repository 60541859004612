import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './References.module.scss';
import { Breadcrumb, Form, PageHeader, Radio, Table } from 'antd';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

import { FileExcelOutlined } from '@ant-design/icons';

import columns from './columns';
import { useHistory } from 'react-router';

const References = (props: any) => {
    const { referencesStore, dictionaryStore, configStore } = useStores();
    const history = useHistory();
    const { lang } = configStore;
    const content = getLanguageContent(lang);

    const [sortedInfo, setSortedInfo] = useState({ columnKey: 'id', order: 'ascend' });

    useEffect(() => {
        referencesStore
            .fetchList()
            .then(() => {
                dictionaryStore
                    .getReferencesDictionaries()
                    .catch(err => console.error(err.message));
            })
            .catch(err => console.error(err.message));
    }, []);

    return (
        <div>
            <div className={styles.content}>
                <PageHeader
                    className={cs(styles.header)}
                    title={
                        <Breadcrumb className={cs(styles.title)}>
                            <Breadcrumb.Item>{content.references.title}</Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />

                <div className={styles.tableWrapper}>
                    <Form layout="inline" className="components-table-demo-control-bar">
                        <Form.Item>
                            <Radio.Group buttonStyle="solid" className={styles.buttons}>
                                <Radio.Button disabled style={{ visibility: 'hidden' }}>
                                    {
                                        <span>
                                            <FileExcelOutlined className={styles.icon} />
                                            {content.users.buttons.create}
                                        </span>
                                    }
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <div className={styles.space} />
                    </Form>

                    <Table
                        loading={referencesStore.loading}
                        columns={columns(lang, content.references, sortedInfo)}
                        dataSource={referencesStore.list}
                        pagination={{ showSizeChanger: false, pageSize: 15 }}
                        size={'small'}
                        rowClassName={styles.expandableRow}
                        onChange={(pagination: any, filters: any, sorter: any) => {
                            setSortedInfo(sorter);
                        }}
                        onRow={record => {
                            return {
                                onClick: event => {
                                    referencesStore.setCurrentRef(record.name);
                                    referencesStore.setAttributes(record.attrib);
                                    history.push(`/edit-reference/${record.id}`);
                                },
                            };
                        }}
                        showSorterTooltip={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(References);
