import React from 'react';

import {
    parseDictionaryItemName,
    parseDictionaryItemAttrib,
} from '../../ApplicationManagement/utils/parseDictionaryItem';
import { ContentType, Languages } from '../../../content';
import { DictionaryItem } from '../../../service/models/dictionaryItem';
import { FormFieldsUsersInterface } from '../../../components/FormComponent/FormComponent';

import { Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const getEditUserFields = (
    content: ContentType,
    userId: number,
    isAdmin: boolean,
    newUser: boolean,
    company: boolean,
    emailChecked: boolean,
    companyDictionary: DictionaryItem[],
    usergroupDictionary: DictionaryItem[],
    functionalAreaDictionary: DictionaryItem[],
    rules: any,
    lang: Languages,
    usergroup: null | number,
    onChange: any,
): Array<FormFieldsUsersInterface> => {
    const fields = {
        Username: {
            key: 'username',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.username,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`username`].test(value)) {
                        callback(content.auth.validate.invalidUsername);
                    } else {
                        callback();
                    }
                },
                required: () => true,
                span: 12,
                disabled: (id: number) => id !== userId && !newUser,
            },
        },
        FullNameRu: {
            key: 'full_name_ru',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.full_name_ru,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`first_name_ru`].test(value)) {
                        callback(content.auth.validate.invalidFirstName);
                    } else {
                        callback();
                    }
                },
                required: (fullNameExists: boolean) => !fullNameExists,
                span: 12,
            },
        },
        FullNameEn: {
            key: 'full_name_en',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.full_name_en,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`first_name_en`].test(value)) {
                        callback(content.auth.validate.invalidFirstName);
                    } else {
                        callback();
                    }
                },
                required: (fullNameExists: boolean) => !fullNameExists,
                span: 12,
            },
        },
        IsAdmin: {
            key: 'is_admin',
            classNameField: 'spectrumField',
            meta: {
                type: 'checkbox',
                placeholder: content.users.editUser.is_admin,
                span: 6,
                disabled: () => !isAdmin,
            },
        },
        IsActive: {
            key: 'is_active',
            classNameField: 'spectrumField',
            meta: {
                type: 'checkbox',
                placeholder: content.users.editUser.is_active,
                span: 6,
                disabled: () => !isAdmin || newUser || !company,
            },
        },
        FuncArea: {
            key: 'func_area',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.users.editUser.func_area,
                required: () => usergroup === 2012,
                disabled: () => usergroup !== 2012,
                filterByOptionName: 'label',
                span: 12,
            },
            proposedData: parseDictionaryItemAttrib(functionalAreaDictionary, lang),
        },
        Email: {
            key: 'email',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.email,
                required: () => true,
                span: 12,
                suffix: !newUser ? (
                    <Tooltip
                        title={
                            emailChecked
                                ? content.users.editUser.email_checked
                                : content.users.editUser.email_unchecked
                        }
                    >
                        {emailChecked ? (
                            <CheckCircleOutlined style={{ color: '#59A23F' }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: '#FF4557' }} />
                        )}
                    </Tooltip>
                ) : null,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`email`].test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
            },
        },
        EmailChecked: {
            key: 'email_checked',
            classNameField: 'spectrumField',
            meta: {
                type: 'checkbox',
                placeholder: content.users.editUser.email_checked,
                span: 12,
                disabled: () => true,
            },
        },
        ManagerEmail: {
            key: 'manager_email',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.manager_email,
                required: () => usergroup === 2012,
                span: 12,
                validation: (rule: any, value: any, callback: any) => {
                    if (!!value && usergroup === 2012 && !rules[`email`].test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
                disabled: () => usergroup !== 2012,
            },
        },
        CompanyName: {
            key: `company_str_${lang}`,
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.company_str,
                span: 12,
                disabled: () => true,
            },
        },
        Company: {
            key: 'company',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.users.editUser.company,
                required: () => true,
                filterByOptionName: 'label',
                span: 12,
            },
            proposedData: parseDictionaryItemName(companyDictionary, lang),
        },
        UserGroup: {
            key: 'usergroup',
            classNameField: 'spectrumField',
            meta: {
                type: 'select',
                placeholder: content.users.editUser.usergroup,
                required: () => true,
                filterByOptionName: 'label',
                span: 12,
                onChange: onChange,
            },
            proposedData: parseDictionaryItemName(usergroupDictionary, lang),
        },
        Position: {
            key: 'position',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.position,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`position`].test(value)) {
                        callback(content.auth.validate.invalidPosition);
                    } else {
                        callback();
                    }
                },
                required: () => true,
                span: 12,
            },
        },
        PhoneNumber: {
            key: 'phone_num',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.users.editUser.phone_num,
                required: () => true,
                span: 12,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`phone_num`].test(value)) {
                        callback(content.auth.validate.invalidFirstName);
                    } else {
                        callback();
                    }
                },
            },
        },
        Password: {
            key: 'password',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.registrationFields.password,
                validation: (rule: any, value: any, callback: any) => {
                    if (!rules[`password`].test(value)) {
                        callback(content.auth.validate.invalidPassword);
                    } else {
                        callback();
                    }
                },
                required: () => true,
                span: 12,
            },
        },
    };

    return newUser
        ? [
              fields.Username,
              fields.Password,
              fields.FullNameRu,
              fields.FullNameEn,
              fields.FuncArea,
              fields.CompanyName,
              fields.Company,
              fields.UserGroup,
              fields.Position,
              fields.PhoneNumber,
              fields.Email,
              fields.ManagerEmail,
          ]
        : [
              fields.Username,
              fields.IsAdmin,
              fields.IsActive,
              fields.FullNameRu,
              fields.FullNameEn,
              fields.FuncArea,
              fields.CompanyName,
              fields.Company,
              fields.UserGroup,
              fields.Position,
              fields.PhoneNumber,
              fields.Email,
              fields.ManagerEmail,
          ];
};
