import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ErrorStore } from './errorStore';
import { UsersItem } from '../service/models/users';
import { UsersService } from '../service/usersService';

export class UsersStore {
    username = '';
    isAdmin = false;
    loading = false;
    selectedRow: any;
    error: any;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly usersService: UsersService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.usersService = usersService;
    }

    usersList: Array<UsersItem> = [];
    user: any = {};

    selectRow = (id: number | null) => {
        this.selectedRow = id;
    };

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async () => {
        try {
            return await this.fetchWrapper(
                () => this.usersService.getList(),
                (result: any) => {
                    this.usersList = result.data.map((user: UsersItem) =>
                        Object.assign(user, { key: user.id }),
                    );
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchGrantAdmin = async (id: number, is_admin: boolean) => {
        try {
            return await this.fetchWrapper(() => this.usersService.grantAdmin(id, is_admin));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchActivateUser = async (id: number, is_active: boolean) => {
        try {
            return await this.fetchWrapper(() => this.usersService.activateUser(id, is_active));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUser = async (id: number) => {
        try {
            return await this.fetchWrapper(
                () => this.usersService.checkIsAdmin(id),
                (result: any) => {
                    this.user = {
                        ...{ id },
                        ...result.data,
                        ...{ is_admin: true },
                    };
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchNewUser = async (user: any) => {
        try {
            return await this.fetchWrapper(() => this.usersService.newUser(user));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdateUser = async (id: number, user: any) => {
        try {
            return await this.fetchWrapper(() => this.usersService.updateUser(id, user));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdatePassword = async (id: number, password: any) => {
        try {
            return await this.fetchWrapper(() => this.usersService.updatePassword(id, password));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    setUser(value: any) {
        this.user = value;
    }
}
