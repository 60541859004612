import { DataRegistration } from '../service/models/auth';

// Функция, возвращающая поле full_name посредлством конкатенации first_name и last_name, с учётом локали
const full_name_local = (dataRegistration: DataRegistration) => {
    return {
        full_name_ru:
            'first_name_ru' in dataRegistration
                ? `${dataRegistration.first_name_ru} ${dataRegistration.last_name_ru}`
                : '',
        full_name_en:
            'first_name_en' in dataRegistration
                ? `${dataRegistration.first_name_en} ${dataRegistration.last_name_en}`
                : '',
    };
};

// Функция, преобразующая объект регистрации с полями
const transformDataRegistration = (dataRegistration: DataRegistration) => {
    return {
        ...dataRegistration,
        ...full_name_local(dataRegistration),
    };
};

export default transformDataRegistration;
