import { observer } from 'mobx-react';

import './MainPageOverlay.module.scss';
import { useStores } from '../../store';

const MainPageOverlay = () => {
    const { mainPageOverlayStore } = useStores();

    const overlayIsActive = mainPageOverlayStore.GET_MAIN_PAGE_OVERLAY
        ? 'MainPageOverlay_active'
        : '';

    return <div className={`MainPageOverlay ${overlayIsActive}`} />;
};

export default observer(MainPageOverlay);
