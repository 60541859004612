import React from 'react';
import { Input } from '../../../../components/Inputs/Inputs';
import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react';
import loginStyles from '../Login/Login.module.scss';
import { useStores } from '../../../../store';
import getLanguageContent from '../../../../content';
import FormComponent from '../../../../components/FormComponent/FormComponent';
import { getEmailField } from './PasswordResetFIelds';
import { DataRegistration } from '../../../../service/models/auth';
import { useHistory } from 'react-router';

const EmailSend = (props: any) => {
    const { authStore, configStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const history = useHistory();

    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields().then(async (values: DataRegistration) => {
            const response: any = await authStore.fetchResetPassword(values.email);

            if (response.status === 200) {
                Modal.success({
                    title: content.auth.modal.email.success,
                    onOk() {
                        history.push('/login');
                    },
                });
            } else {
                Modal.error({
                    title: content.auth.modal.email.error,
                    content:
                        configStore.lang === 'ru'
                            ? response.data.split('#\n')[1]
                            : response.data.split('#\n')[0],
                });
            }
        });
    };

    return (
        <>
            <div style={{ marginTop: 40 }}>
                <FormComponent
                    formName="Login"
                    formInstance={form}
                    formFields={getEmailField(content, dictionaryStore.rules)}
                />
            </div>
            <Button className="btn btn--w100 btn-blue" style={{ marginTop: 40 }} onClick={onSubmit}>
                {content.auth.buttons.sendEmail}
            </Button>
            <a href="/login" className={loginStyles.textRestore}>
                {content.auth.buttons.backToLogin}
            </a>
        </>
    );
};

export default observer(EmailSend);
