import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ReferencesService } from '../service/referencesService';
import { ErrorStore } from './errorStore';
import { DictionaryStore } from './dictionaryStore';

import { ReferencesItem } from '../service/models/references';

export class ReferencesStore {
    loading = false;
    itemsLoading = false;
    type = 'object';

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly dictionaryStore: DictionaryStore,
        private readonly referencesService: ReferencesService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.dictionaryStore = dictionaryStore;
        this.referencesService = referencesService;
    }

    list: Array<any> = [];
    itemList: Array<any> = [];
    attributes: any = {};
    currentRef: any = {};
    currentItem: Array<number> = [];

    setAttributes = (attr: any) =>
        runInAction(() => {
            this.attributes = attr;
        });

    setCurrentRef = (ref: any) =>
        runInAction(() => {
            this.currentRef = ref;
        });

    setItemList = (arr: Array<any>) =>
        runInAction(() => {
            this.itemList = arr;
        });

    setCurrentItem = (arr: Array<number>) =>
        runInAction(() => {
            this.currentItem = arr;
        });

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203 ||
            result.status === 204
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async () => {
        this.loading = true;

        try {
            return await this.fetchWrapper(
                () => this.referencesService.getList(),
                (result: any) => {
                    this.list = result.data
                        .filter((row: any) => row.id !== 0 && JSON.parse(row.name).en !== 'Users')
                        .map((row: any, index: number) => {
                            row.key = row.id;
                            row.name = JSON.parse(row.name);
                            row.descr = JSON.parse(row.descr);
                            row.attrib = JSON.parse(row.attrib);

                            return row;
                        });
                },
            );
        } catch (err: any) {
            console.error(err.message);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    fetchListFile = async (clsrid: string) => {
        try {
            return await this.fetchWrapper(() => this.referencesService.getListFile({ clsrid }));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchItemList = async (id: number) => {
        this.itemsLoading = true;

        try {
            return await this.fetchWrapper(
                () => this.referencesService.getItemList(id),
                (result: any) => {
                    this.itemList = result.data
                        ? result.data.map((row: any, index: number) => {
                              row.key = row.id;
                              row.name = JSON.parse(row.name);
                              row.descr = JSON.parse(row.descr);
                              row.attrib = JSON.parse(row.attrib);

                              return row;
                          })
                        : [];
                },
            );
        } catch (err: any) {
            console.error(err.message);
        } finally {
            runInAction(() => {
                this.itemsLoading = false;
            });
        }
    };

    fetchUpdateItem = async (id: number, item: ReferencesItem) => {
        try {
            return await this.fetchWrapper(() => this.referencesService.updateItem(id, item));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchCreateItem = async (clsr: number, item: ReferencesItem) => {
        try {
            return await this.fetchWrapper(() => this.referencesService.createItem(clsr, item));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchCloseItem = async (clsr: number, date: any) => {
        try {
            return await this.fetchWrapper(() => this.referencesService.closeItem(clsr, date));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchLockItem = async (clsr: number) => {
        try {
            return await this.fetchWrapper(() => this.referencesService.lockItem(clsr));
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
