import { Col, Row, Form, PageHeader, Breadcrumb, Select } from 'antd';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Notifications.module.scss';
import cs from '../../utils/composeStyles';
import getLanguageContent from '../../content';
import { useStores } from '../../store';

import { Selection } from '../../components/Inputs/Inputs';

import { Content } from 'antd/lib/layout/layout';
import { parseItemCode } from '../ApplicationManagement/utils/parseDictionaryItem';

import Notifications from './Notifications';

const NotificationsPage = (props: any) => {
    const { configStore, dictionaryStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        dictionaryStore.getNotificationsTemplate().catch(err => console.error(err.message));
    }, []);

    const handleTemplateTypeSelect = (value: string) => {
        setLoading(true);
        const template = dictionaryStore.messageTemplates.filter(
            (item: any) => value === item.id,
        )[0];
        setCode(JSON.parse(template.attrib as string).code);

        setTimeout(() => {
            setInitialValues({
                descr_en: JSON.parse(template.name).en,
                descr_ru: JSON.parse(template.name).ru,
                days_1: JSON.parse(template.attrib as string).days_1,
                days_2: JSON.parse(template.attrib as string).days_2,
                days_3: JSON.parse(template.attrib as string).days_3,
            });
            setLoading(false);
        }, 10);
    };

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.notifications.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <div className={styles.tableWrapper}>
                <Content>
                    <div className="site-layout-content">
                        <Form
                            style={{
                                marginLeft: 40,
                                marginRight: 40,
                                paddingTop: 36,
                                paddingBottom: 16,
                            }}
                        >
                            <Row gutter={[20, 8]}>
                                <Col span={12}>
                                    <Form.Item className="form__item">
                                        <Selection
                                            className={cs('spectrumField', styles.fullWidthInput)}
                                            placeholder={content.notifications.placeholders.type}
                                            showSearch
                                            onChange={handleTemplateTypeSelect}
                                        >
                                            {dictionaryStore.messageTemplates
                                                .filter(template =>
                                                    /email/i.test(
                                                        parseItemCode(template.attrib as string),
                                                    ),
                                                )
                                                .sort((a: any, b: any) =>
                                                    parseItemCode(a.attrib as string).localeCompare(
                                                        parseItemCode(b.attrib as string),
                                                    ),
                                                )
                                                .map(template => (
                                                    <Select.Option value={template.id}>
                                                        {parseItemCode(template.attrib as string)}
                                                    </Select.Option>
                                                ))}
                                        </Selection>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {!loading && <Notifications code={code} initialValues={initialValues} />}
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default observer(NotificationsPage);
