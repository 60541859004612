export default {
    title: 'Monitoring',
    inputs: {
        filter: 'View',
        startDate: 'Start date',
        endDate: 'End date',
    },
    table: {
        header: {
            ctime: 'Date',
            source: 'Source',
            level: 'Level',
        },
    },
    level: 'Log level',
};
