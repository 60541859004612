import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ReportsService } from '../service/reportsService';
import { ApplicationManagementItem } from '../service/models/applicationManagement';
import { ErrorStore } from './errorStore';
import moment from 'moment';

export class ReportsStore {
    loading = false;
    type = 'period';

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly reportsService: ReportsService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.reportsService = reportsService;
    }

    list: Array<ApplicationManagementItem> = [];
    ruList: Array<ApplicationManagementItem> = [];
    enList: Array<ApplicationManagementItem> = [];
    columns: Array<any> = [];
    ruColumns: Array<any> = [];
    enColumns: Array<any> = [];
    totals: Array<any> = [];
    beginDate: any;
    endDate: any;

    selectType = (type: string) => {
        this.type = type;
    };

    setBeginDate = (date: any) => {
        this.beginDate = date;
    };

    setEndDate = (date: any) => {
        this.endDate = date;
    };

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async (type: string) => {
        try {
            return await this.fetchWrapper(
                () =>
                    this.reportsService.getList(
                        type,
                        this.beginDate
                            ? moment(this.beginDate).format('YYYY-MM-DD[T]00:00:00')
                            : null,
                        this.endDate ? moment(this.endDate).format('YYYY-MM-DD[T]23:59:59') : null,
                    ),
                (result: any) => {
                    const data = JSON.parse(result.data);

                    this.list = data.index
                        .map((row: any, index: number) => ({
                            ...{ title: !!row && row !== '0' ? row : 'Total #\nВсего' },
                            ...Object.assign({}, data.data[index]),
                        }))
                        .filter((row: any) => row.title !== 'Total #\nВсего')
                        .sort((a: any, b: any) => a.title.localeCompare(b.title));
                    this.ruList = this.list.map((row: any) => {
                        let cur: any = { ...row };
                        cur.title = row.title.split('#\n')[1];

                        return cur;
                    });
                    this.enList = this.list.map((row: any) => {
                        let cur: any = { ...row };
                        cur.title = row.title.split('#\n')[0];

                        return cur;
                    });

                    this.columns = [
                        ...[{ dataIndex: 'title' }],
                        ...data.columns.map((column: string, index: number) => ({
                            title: column[1],
                            dataIndex: index.toString(),
                            key: index.toString(),
                        })),
                    ];
                    this.ruColumns = [
                        ...[{ dataIndex: 'title' }],
                        ...data.columns.map((column: string, index: number) => ({
                            title: column[1].split('#\n')[1],
                            dataIndex: index.toString(),
                            key: index.toString(),
                        })),
                    ];
                    this.enColumns = [
                        ...[{ dataIndex: 'title' }],
                        ...data.columns.map((column: string, index: number) => ({
                            title: column[1].slice(2).split('#\n')[0],
                            dataIndex: index.toString(),
                            key: index.toString(),
                        })),
                    ];
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchListFile = async (xls: boolean) => {
        try {
            return await this.fetchWrapper(() =>
                this.reportsService.getListFile(
                    this.type,
                    xls,
                    this.beginDate ? moment(this.beginDate).format('YYYY-MM-DD[T]00:00:00') : null,
                    this.endDate ? moment(this.endDate).format('YYYY-MM-DD[T]23:59:59') : null,
                ),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchTotals = async () => {
        try {
            return await this.fetchWrapper(
                () => this.reportsService.getTotals(),
                (result: any) => {
                    this.totals = result.data;
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
