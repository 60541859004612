import {
    Table,
    PageHeader,
    Button,
    Radio,
    Form,
    DatePicker,
    Breadcrumb,
    Dropdown,
    Menu,
    Modal,
} from 'antd';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './ApplicationManagement.module.scss';
import { useStores } from '../../store';
import cs from '../../utils/composeStyles';

import getLanguageContent from '../../content';

import columns from './columns';

import { parseRqStatus, parseItemName } from './utils/parseDictionaryItem';

import {
    FileExcelOutlined,
    CopyOutlined,
    FormOutlined,
    DeleteOutlined,
    ReloadOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import Icons from './icons';
import AttachFile from './AttachFile/AttachFile';
import RejectDialog from './RejectDialog/RejectDialog';
import Error from '../../components/Error/Error';

import 'moment/locale/ru';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';

interface DataType {
    id: React.Key;
}

const parseItem = (lang: string) => (item: string) => {
    const name = item ? JSON.parse(item).name : item;
    return name ? name[lang] : name;
};

const ApplicationManagement = () => {
    const history = useHistory();
    const { applicationManagementStore, dictionaryStore, configStore, searchStore, authStore } =
        useStores();
    const content = getLanguageContent(configStore.lang);

    const [avStat, setAvStat] = useState([]);
    const [curStat, setCurStat] = useState();
    const [attach, setAttach] = useState(false);
    const [reject, setReject] = useState(false);
    const [rejectStatus, setRejectStatus] = useState('');

    useEffect(() => {
        applicationManagementStore.fetchList().catch(err => console.error(err.message));
        dictionaryStore.getAppManagementDictionaries().catch(err => console.error(err.message));
        dictionaryStore.getRulesDictionary().catch(err => console.error(err.message));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            applicationManagementStore.fetchList().catch(err => console.error(err.message));
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const handleBeginDateChange = (date: any) => {
        applicationManagementStore.setBeginDate(date);
    };

    const handleEndDateChange = (date: any) => {
        applicationManagementStore.setEndDate(date);
    };

    const handleExport = (item: any) => {
        switch (item.key) {
            case 'export-list':
                applicationManagementStore.fetchListFile().catch(err => console.error(err.message));
                break;

            case 'export-full':
                applicationManagementStore
                    .fetchRqExportFile(0, 'application-full')
                    .catch(err => console.error(err.message));
                break;

            default:
                applicationManagementStore.fetchListFile().catch(err => console.error(err.message));
                break;
        }
    };

    const onAttachClose = (saved: boolean) => {
        setAttach(!attach);
        saved && onSelectNone();
    };

    const onRejectClose = (saved: boolean) => {
        setReject(!reject);
        saved && onSelectNone();
    };

    const setNewStatus = (status: number) => {
        const id = applicationManagementStore.selectedRow;

        applicationManagementStore
            .fetchRqStatus(id as number, { rq_id: id, available_only: false, to_status: status })
            .then(response => {
                if (response.status === 200) {
                    applicationManagementStore
                        .fetchList()
                        .then(response => {
                            if (response.status === 200) {
                                onSelectNone();
                            }
                        })
                        .catch(err => console.error(err.message));
                }
            });
    };

    const handlerNewRequestClick = () => {
        onSelectNone();
        history.push('/new-request');
    };

    const handlerEditRequestClick = () => {
        history.push(`/edit-request/${applicationManagementStore.selectedRow}`);
        onSelectNone();
    };

    const handlerCopyRequestClick = () => {
        applicationManagementStore
            .fetchCopyRequest(applicationManagementStore.selectedRow)
            .then((response: any) => {
                if (response.data.new_id) {
                    history.push(`/edit-request/${response.data.new_id}`);
                    onSelectNone();
                }
            });
    };

    const handlerDeleteClick = () => {
        Modal.confirm({
            title: content.appManagement.modal.delete,
            okText: content.appManagement.modal.buttons.yes,
            cancelText: content.appManagement.modal.buttons.no,
            onOk() {
                setNewStatus(8);
            },
        });
    };

    const handlerRevokeClick = () => {
        Modal.confirm({
            title: content.appManagement.modal.revoke,
            okText: content.appManagement.modal.buttons.yes,
            cancelText: content.appManagement.modal.buttons.no,
            onOk() {
                setNewStatus(7);
            },
        });
    };

    const onSelectChange = (record: any, selected: boolean, selectedRows: DataType[]) => {
        if (selected) {
            setCurStat(record.status);
            applicationManagementStore.selectRow(record.id);
            applicationManagementStore
                .fetchRqStatus(record.id as number, { rq_id: record.id, available_only: true })
                .then(response => {
                    if (response.status === 200) {
                        setAvStat(response.data);
                    }
                });
        } else onSelectNone();
    };

    const onSelectNone = () => {
        applicationManagementStore.selectRow(null);
    };

    const onSetStatus = (item: any) => {
        switch (item.key) {
            case '3':
                setRejectStatus(item.key);
                setReject(true);
                break;

            case '5':
                setRejectStatus(item.key);
                setReject(true);
                break;

            case '6':
                setAttach(true);
                break;

            default:
                Modal.confirm({
                    title: content.appManagement.modal.status,
                    okText: content.appManagement.modal.buttons.yes,
                    cancelText: content.appManagement.modal.buttons.no,
                    onOk() {
                        setNewStatus(item.key);
                    },
                });
        }
    };

    const menu = (
        <Menu onClick={onSetStatus} className={styles.actions}>
            {dictionaryStore.statuses.map(action => {
                const attrib = parseRqStatus(action as any);

                if (attrib.onaction) {
                    return (
                        <Menu.Item
                            key={attrib.status}
                            disabled={avStat.indexOf(attrib.status as never) === -1}
                        >
                            {parseItemName(action.descr, configStore.lang)}
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );

    const exportMenu = (
        <Menu onClick={handleExport} className={styles.actions}>
            <Menu.Item key={'export-list'}>{content.appManagement.export.list}</Menu.Item>
            <Menu.Item key={'export-full'}>{content.appManagement.export.full}</Menu.Item>
        </Menu>
    );

    const dataSource = applicationManagementStore.rcList.filter((item: any) => {
        const searchText = searchStore.getCurrentSearch('ApplicationManagement');

        if (!!searchText) {
            const search = searchText.toLowerCase();
            const organisation =
                item.organisation_full && parseItem(configStore.lang)(item.organisation_full);
            const period = item.period_full && parseItem(configStore.lang)(item.period_full);
            const status = item.status_full && parseItem(configStore.lang)(item.status_full);
            const createBy =
                item.create_by_full && parseItem(configStore.lang)(item.create_by_full);

            return (
                ('' + item.id).search(searchText) !== -1 ||
                (!!organisation && organisation.toLowerCase().search(search) !== -1) ||
                (!!period && period.toLowerCase().search(search) !== -1) ||
                (!!status && status.toLowerCase().search(search) !== -1) ||
                (!!createBy && createBy.toLowerCase().search(search) !== -1) ||
                (item.descr && item.descr.toLowerCase().search(search) !== -1)
            );
        } else return true;
    });

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.appManagement.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <div className={styles.tableWrapper}>
                <Form layout="inline" className="components-table-demo-control-bar">
                    <Form.Item>
                        <Radio.Group buttonStyle="solid" className={styles.buttons}>
                            <Radio.Button
                                onClick={handlerNewRequestClick}
                                className={cs(styles.createBtn, 'btn-blue')}
                            >
                                {
                                    <span>
                                        <FileExcelOutlined className={styles.icon} />
                                        {content.appManagement.buttons.create}
                                    </span>
                                }
                            </Radio.Button>
                            <Radio.Button
                                onClick={handlerCopyRequestClick}
                                className={cs(styles.copyBtn, 'btn-blue')}
                                disabled={!applicationManagementStore.selectedRow}
                            >
                                {
                                    <span>
                                        <CopyOutlined className={styles.icon} />
                                        {content.appManagement.buttons.copy}{' '}
                                    </span>
                                }
                            </Radio.Button>
                            <Radio.Button
                                onClick={handlerEditRequestClick}
                                className={cs(styles.editBtn, 'btn-blue')}
                                disabled={!applicationManagementStore.selectedRow || curStat === 8}
                            >
                                {
                                    <span>
                                        <FormOutlined className={styles.icon} />
                                        {content.appManagement.buttons.edit}
                                    </span>
                                }
                            </Radio.Button>
                            <Radio.Button
                                onClick={handlerDeleteClick}
                                className={cs(styles.deleteBtn, 'btn-blue')}
                                disabled={
                                    !applicationManagementStore.selectedRow ||
                                    avStat.indexOf(8 as never) === -1
                                }
                            >
                                {
                                    <span>
                                        <DeleteOutlined className={styles.icon} />
                                        {content.appManagement.buttons.delete}
                                    </span>
                                }
                            </Radio.Button>
                            <Radio.Button
                                onClick={handlerRevokeClick}
                                className={cs(styles.revokeBtn, 'btn-blue')}
                                disabled={
                                    !applicationManagementStore.selectedRow ||
                                    avStat.indexOf(7 as never) === -1
                                }
                            >
                                {
                                    <span>
                                        <ReloadOutlined className={styles.icon} />
                                        {content.appManagement.buttons.cancel}
                                    </span>
                                }
                            </Radio.Button>
                            <Dropdown
                                trigger={['click']}
                                overlay={menu}
                                placement="bottomLeft"
                                disabled={!applicationManagementStore.selectedRow}
                            >
                                <Radio.Button className={cs(styles.revokeBtn, 'btn-blue')}>
                                    {
                                        <span>
                                            <Icons.rknIcon className={styles.icon} />
                                            {content.appManagement.buttons.actions}
                                        </span>
                                    }
                                </Radio.Button>
                            </Dropdown>
                        </Radio.Group>
                    </Form.Item>

                    <div className={styles.space} />

                    <Form.Item style={{ margin: 0, marginRight: authStore.isAdmin ? 0 : 17 }}>
                        <div className={styles.datePicker}>
                            <DatePicker
                                placeholder={content.appManagement.inputs.startDate}
                                locale={configStore.lang === 'ru' ? ru : en}
                                onChange={handleBeginDateChange}
                            />
                            <DatePicker
                                placeholder={content.appManagement.inputs.endDate}
                                locale={configStore.lang === 'ru' ? ru : en}
                                onChange={handleEndDateChange}
                            />
                        </div>
                    </Form.Item>
                    {authStore.isAdmin && (
                        <Form.Item
                            style={{ display: 'flex', alignItems: 'center', marginRight: 25 }}
                        >
                            <Dropdown
                                trigger={['click']}
                                overlay={exportMenu}
                                placement="bottomLeft"
                            >
                                <Button className={cs(styles.exportBtn, styles.button)}>
                                    {
                                        <span>
                                            <ExportOutlined className={styles.icon} />
                                            {content.appManagement.buttons.export}
                                        </span>
                                    }
                                </Button>
                            </Dropdown>
                        </Form.Item>
                    )}
                </Form>
                <Table
                    rowSelection={{
                        selectedRowKeys: [applicationManagementStore.selectedRow],
                        onSelect: onSelectChange,
                        onSelectAll: onSelectNone,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                setCurStat(record.status as any);
                                applicationManagementStore.selectRow(record.id);
                                applicationManagementStore
                                    .fetchRqStatus(record.id as number, {
                                        rq_id: record.id,
                                        available_only: true,
                                    })
                                    .then(response => {
                                        if (response.status === 200) {
                                            setAvStat(response.data);
                                        }
                                    });
                            },
                        };
                    }}
                    rowClassName={(application: any) =>
                        application.is_outdated
                            ? cs(styles.doubleRow, styles.redRow)
                            : styles.doubleRow
                    }
                    dataSource={dataSource}
                    columns={
                        columns(
                            configStore.lang,
                            content.appManagement.table.header,
                            dataSource,
                            applicationManagementStore.fetchDocument,
                            dictionaryStore.statuses,
                        ) as any
                    }
                    pagination={{ showSizeChanger: false, pageSize: 10 }}
                    onChange={() => onSelectNone()}
                    size={'small'}
                    showSorterTooltip={false}
                />
            </div>

            <AttachFile
                open={attach}
                rq_id={applicationManagementStore.selectedRow}
                files={[]}
                setNewStatus={setNewStatus}
                onClose={onAttachClose}
            />
            <RejectDialog
                open={reject}
                rq_id={applicationManagementStore.selectedRow}
                status={rejectStatus}
                onClose={onRejectClose}
            />
            <Error />
        </div>
    );
};

export default observer(ApplicationManagement);
