import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { JournalService } from '../service/journalService';
import { ErrorStore } from './errorStore';

export class JournalStore {
    loading = false;
    type = 1;
    page = 0;
    pageSize = 9;
    messId: number | boolean = false;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly journalService: JournalService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.journalService = journalService;
    }

    list: Array<any> = [];
    beginDate: any;
    endDate: any;

    setType = (type: number) => {
        this.type = type;
        this.list = [];
    };

    setBeginDate = (date: any) => {
        this.beginDate = date;
    };

    setEndDate = (date: any) => {
        this.endDate = date;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setMess = (mess: number | boolean) => {
        this.messId = mess;
    };

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchList = async () => {
        try {
            return await this.fetchWrapper(
                () => this.journalService.getList(this.type, this.beginDate, this.endDate),
                (result: any) => {
                    this.list = JSON.parse(result.data);
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
