import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ErrorStore } from './errorStore';
import { UsersStore } from './usersStore';
import { NotificationsTemplatesService } from '../service/notificationsTemplatesService';

export class NotificationsTemplateStore {
    support_1_ru = '';
    support_1_en = '';
    support_2_ru = '';
    support_2_en = '';
    support_3_ru = '';
    support_3_en = '';
    loading = false;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly usersStore: UsersStore,
        private readonly notificationsTemplatesService: NotificationsTemplatesService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.usersStore = usersStore;
        this.notificationsTemplatesService = notificationsTemplatesService;
    }

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchUpdateTemplate = async (code: string, data: any) => {
        try {
            return await this.fetchWrapper(() =>
                this.notificationsTemplatesService.updateTemplate(code, data),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
