import { Menu } from 'antd';
import { ReactNode } from 'react';

import './AppMenuItem.module.scss';

type AppMenuItemProps = (data: sidebarMenuItemType) => ReactNode;

const AppMenuItem: AppMenuItemProps = data => {
    const { title, icon, id } = data;

    return (
        <Menu.Item
            key={id}
            className="sidebarMenuItem"
            title={null}
            icon={<img className="sidebarMenuItem__icon" src={icon} alt="Not Found" />}
        >
            {title}
        </Menu.Item>
    );
};

export default AppMenuItem;
