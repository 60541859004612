import axios, { AxiosResponse } from 'axios';
import ConfigurationStore from '../store/configurationStore';
import urljoin from 'url-join';

import { logOut } from '../utils/auth';

interface JournalServiceInterface {
    getList: (
        page: number,
        pageSize: number,
        level: string,
        begin: any,
        end: any,
    ) => Promise<AxiosResponse<any[]>>;
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logOut();
            window.location.reload();
        }
        return error.response;
    },
);

export class JournalService implements JournalServiceInterface {
    appManUrl = '';
    apiUrl = '';

    constructor(configStore: ConfigurationStore) {
        this.appManUrl = configStore.appConfig.appManUrl;
        this.apiUrl = configStore.appConfig.apiUrl;
    }

    private axiosGet = async (url: string, params?: any): Promise<AxiosResponse> => {
        const token = sessionStorage.getItem('access_token');

        let config = {} as any;
        if (token)
            config['headers'] = {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            };
        if (params) config['params'] = params;

        return await axios.get(urljoin(url, '/'), config);
    };

    getList = async (tabtype: number, start: any, end: any): Promise<AxiosResponse<any[]>> => {
        return await this.axiosGet(urljoin(this.apiUrl, `/common/view_log_short/`), {
            tabtype,
            start,
            end,
        });
    };
}

export default JournalService;
