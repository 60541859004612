import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import { ErrorStore } from './errorStore';
import { UsersStore } from './usersStore';
import { UsersItem } from '../service/models/users';
import { SupportService } from '../service/supportService';

export class SupportStore {
    support_1_ru = '';
    support_1_en = '';
    support_2_ru = '';
    support_2_en = '';
    support_3_ru = '';
    support_3_en = '';
    loading = false;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly errorStore: ErrorStore,
        private readonly usersStore: UsersStore,
        private readonly supportService: SupportService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.errorStore = errorStore;
        this.usersStore = usersStore;
        this.supportService = supportService;
    }

    fetchWrapper = async (fetch: any, onSuccess?: any, onFalse?: any) => {
        this.loading = true;
        const result = await fetch();

        if (
            result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 203
        ) {
            onSuccess && onSuccess(result);
        } else {
            this.errorStore.apiError(result);
            onFalse && onFalse(result);
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchData = async () => {
        try {
            return await this.fetchWrapper(
                () => this.supportService.getData(),
                (result: any) => {
                    runInAction(() => {
                        this.support_1_ru = JSON.parse(result.data['"support_1"'].name).ru;
                        this.support_1_en = JSON.parse(result.data['"support_1"'].name).en;
                        this.support_2_ru = JSON.parse(result.data['"support_2"'].name).ru;
                        this.support_2_en = JSON.parse(result.data['"support_2"'].name).en;
                        this.support_3_ru = JSON.parse(result.data['"support_3"'].name).ru;
                        this.support_3_en = JSON.parse(result.data['"support_3"'].name).en;
                    });
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    getInitialValues = () => {
        return {
            support_1_ru: this.support_1_ru,
            support_1_en: this.support_1_en,
            support_2_ru: this.support_2_ru,
            support_2_en: this.support_2_en,
            support_3_ru: this.support_3_ru,
            support_3_en: this.support_3_en,
        };
    };

    fetchFiles = async () => {
        try {
            return await this.fetchWrapper(
                () => this.supportService.getFiles(),
                (result: any) => {
                    return result;
                },
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdateSupport = async (code: string, data: any) => {
        try {
            return await this.fetchWrapper(() => this.supportService.updateSupport(code, 0, data));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUploadDocument = async (file: any, descr_ru: string, descr_en: string) => {
        try {
            return await this.fetchWrapper(() =>
                this.supportService.uploadDocument(file as any, descr_ru, descr_en),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchDocument = async (id: number, name: string) => {
        try {
            return await this.fetchWrapper(() => this.supportService.getDocument(id, name));
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchUpdateSupportDocument = async (id: number, data: any) => {
        try {
            return await this.fetchWrapper(() =>
                this.supportService.updateSupportDocument(id, data),
            );
        } catch (err: any) {
            console.error(err.message);
        }
    };

    fetchDeleteDocument = async (id: number) => {
        try {
            return await this.fetchWrapper(() => this.supportService.deleteDocument(id));
        } catch (err: any) {
            console.error(err.message);
        }
    };
}
