import React from 'react';

import { Button, Tooltip } from 'antd';

import styles from './File.module.scss';
import { CloseIcon, PDFIcon } from '../../../../components/Icons/Icons';

const File = (props: any) => {
    const { id, name, deletable, getDocument, handleDelete } = props;

    return (
        <div className={styles.file} onClick={() => getDocument(id, name)}>
            <PDFIcon className={styles.icon} />
            {deletable && (
                <Tooltip title={'Удалить документ'}>
                    <Button
                        type="text"
                        icon={<CloseIcon />}
                        size="small"
                        className={styles.closeBtn}
                        onClick={e => {
                            e.stopPropagation();
                            handleDelete();
                        }}
                    />
                </Tooltip>
            )}
            <Tooltip title={name}>
                <p>{name}</p>
            </Tooltip>
        </div>
    );
};

export default File;
