import React, { useState } from 'react';
import { useStores } from '../../../../store';
import { Button, Form, Spin } from 'antd';
import FormComponent from '../../../../components/FormComponent/FormComponent';
import styles from './Registration.module.scss';
import {
    getRegistrationFirstStep,
    getRegistrationSecondStep,
    getRegistrationThirdStep,
} from './RegistrationFormFIelds';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { DataRegistration } from '../../../../service/models/auth';
import getLanguageContent from '../../../../content';
import { useHistory } from 'react-router';
import Captcha from './Captcha';

enum RegistrationSteps {
    first = 'first',
    second = 'second',
    third = 'third',
}

const stepsNumber = {
    [RegistrationSteps.first]: 1,
    [RegistrationSteps.second]: 2,
    [RegistrationSteps.third]: 3,
};

const Registration = () => {
    const { authStore, dictionaryStore, configStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const history = useHistory();
    const [form] = Form.useForm();
    const [activeStep, setActiveStep] = useState<RegistrationSteps>(RegistrationSteps.first);

    const [usergroup, setUsergroup] = useState<null | number>(null);

    const onChange = (value: number) => {
        setUsergroup(value);
    };

    const [stepsData, setStepsData] = useState<DataRegistration>({} as DataRegistration);

    const onSubmitForm = () => {
        form.validateFields().then(async (values: DataRegistration) => {
            const response = await authStore.fetchRegistration({
                ...stepsData,
                ...{ password: values.password },
            });

            if (response.status === 200) {
                history.push('/login');
            }
        });
    };

    const onSubmitStep = (nextStep: RegistrationSteps) => {
        form.validateFields().then((values: DataRegistration) => {
            setStepsData({ ...stepsData, ...values });
            setActiveStep(nextStep);
        });
    };

    const getStepFields = (step: RegistrationSteps) => {
        switch (step) {
            case RegistrationSteps.first:
                return getRegistrationFirstStep(
                    content,
                    dictionaryStore.organizations,
                    dictionaryStore.rules,
                    dictionaryStore.tooltips,
                    configStore.lang,
                );

            case RegistrationSteps.second:
                return getRegistrationSecondStep(
                    content,
                    dictionaryStore.userGroup,
                    dictionaryStore.functionalArea,
                    dictionaryStore.rules,
                    dictionaryStore.tooltips,
                    configStore.lang,
                    usergroup,
                    onChange,
                );

            case RegistrationSteps.third:
                return getRegistrationThirdStep(
                    content,
                    dictionaryStore.userGroup,
                    dictionaryStore.functionalArea,
                    dictionaryStore.rules,
                    dictionaryStore.tooltips,
                    configStore.lang,
                );
        }
    };

    return (
        <>
            <p className={styles.steps}>
                {content.auth.step} {stepsNumber[activeStep]}/3
            </p>
            <Spin spinning={!dictionaryStore.dictionariesLoaded}>
                <div className={styles.formContainer}>
                    <FormComponent
                        formName="Login"
                        formInstance={form}
                        formFields={getStepFields(activeStep)}
                    />
                    {activeStep === RegistrationSteps.first && (
                        <Button
                            className="btn btn--w100 btn-blue"
                            style={{ marginTop: 40 }}
                            onClick={() => onSubmitStep(RegistrationSteps.second)}
                        >
                            {content.auth.buttons.next}
                        </Button>
                    )}
                    {activeStep === RegistrationSteps.second && (
                        <>
                            <Button
                                className="btn btn--w100 btn-white"
                                style={{ marginTop: 32 }}
                                onClick={() => setActiveStep(RegistrationSteps.first)}
                                icon={<ArrowLeftOutlined style={{ marginRight: 10 }} />}
                            >
                                {content.auth.buttons.back}
                            </Button>
                            <Button
                                className="btn btn--w100 btn-blue"
                                style={{ marginTop: 8 }}
                                onClick={() => onSubmitStep(RegistrationSteps.third)}
                            >
                                {content.auth.buttons.next}
                            </Button>
                        </>
                    )}
                    {activeStep === RegistrationSteps.third && (
                        <>
                            <Captcha form={form} />
                            <Button
                                className="btn btn--w100 btn-white"
                                style={{ marginTop: 32 }}
                                onClick={() => setActiveStep(RegistrationSteps.second)}
                                icon={<ArrowLeftOutlined style={{ marginRight: 10 }} />}
                            >
                                {content.auth.buttons.back}
                            </Button>
                            <Button
                                className="btn btn--w100 btn-blue"
                                style={{ marginTop: 8 }}
                                onClick={onSubmitForm}
                            >
                                {content.auth.buttons.registration}
                            </Button>
                        </>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default observer(Registration);
