import Workplace from '../../assets/images/Icons/Workplace.svg';
import ApplicationManagement from '../../assets/images/Icons/ApplicationManagement.svg';
import ReportsAndStatistics from '../../assets/images/Icons/ReportsAndStatistics.svg';
import HelpAndSupport from '../../assets/images/Icons/HelpAndSupport.svg';
import Settings from '../../assets/images/Icons/Settings.svg';
import Language from '../../assets/images/Icons/Language.svg';

const sidebar_content_en: sidebar_content_type = {
    Workplace: {
        id: 'Workplace',
        title: 'Workplace',
        icon: Workplace,
        menuItemType: 'menuItem',
    },
    ApplicationManagement: {
        id: 'ApplicationManagement',
        title: 'Application Management',
        icon: ApplicationManagement,
        menuItemType: 'menuItem',
    },
    ReportsAndStatistics: {
        id: 'ReportsAndStatistics',
        title: 'Reports And Statistics',
        icon: ReportsAndStatistics,
        menuItemType: 'menuItem',
    },
    HelpAndSupport: {
        id: 'HelpAndSupport',

        title: 'Help And Support',
        icon: HelpAndSupport,
        menuItemType: 'menuItem',
    },
    Settings: {
        id: 'Settings',

        title: 'Settings',
        icon: Settings,
        menuItemType: 'submenu',
        submenuItems: [
            {
                id: 'UserAccounts',
                title: 'User Accounts',
            },
            {
                id: 'Notifications',
                title: 'Notifications',
            },
            {
                id: 'Monitoring',
                title: 'Monitoring',
            },
            {
                id: 'Journal',
                title: 'Log',
            },
            {
                id: 'References',
                title: 'References',
            },
        ],
    },

    Language: {
        id: 'Language',

        title: 'Русский',
        icon: Language,
        menuItemType: 'menuItem',
    },
};

export default sidebar_content_en;
