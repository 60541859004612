import { makeAutoObservable, runInAction } from 'mobx';
import { NotificationStore } from './notificationStore';
import ConfigurationStore from './configurationStore';
import { ErrorStore } from "./errorStore";
import { DataRegistration, LoginUser } from '../service/models/auth';
import { AuthService } from '../service/authService';
import transformDataRegistration from '../utils/transformDataRegistration';
import getLanguageContent from '../content';

class AuthStore {
    username = '';
    loading = false;
    isAdmin = false;

    constructor(
        private readonly notificationStore: NotificationStore,
        private readonly configStore: ConfigurationStore,
        private readonly errorStore: ErrorStore,
        private readonly authService: AuthService,
    ) {
        makeAutoObservable(this);
        this.notificationStore = notificationStore;
        this.configStore = configStore;
        this.errorStore = errorStore;
        this.authService = authService;
    }

    fetchLogin = async (dataLogin: LoginUser) => {
        const content = getLanguageContent(this.configStore.lang);

        this.loading = true;
        const result = await this.authService.loginUser(dataLogin);

        if (result.status === 200) {
            sessionStorage.setItem('access_token', result.data.access_token);
            sessionStorage.setItem('user_id', Number(result.data.user_id).toString());
            sessionStorage.setItem('company', Number(result.data.company).toString());
            sessionStorage.setItem(
                'contact_ru',
                result.data.full_name_ru ? result.data.full_name_ru : result.data.full_name_en,
            );
            sessionStorage.setItem(
                'contact_en',
                result.data.full_name_en ? result.data.full_name_en : result.data.full_name_ru,
            );

            // this.notificationStore.setSuccessMessage(
            //     "Авторизация",
            //     `Пользователь ${dataLogin.username} авторизован`
            // );
        } else {
            if (result.status === 403) {
                this.notificationStore.setErrorMessage(content.auth.notifications.notActivate);
            } else {
                this.notificationStore.setErrorMessage(content.auth.notifications.notCorrect);
            }
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    fetchIsAdmin = async () => {
        const result = await this.authService.checkIsAdmin(Number(sessionStorage.getItem('user_id')));

        if (result.status === 200) {
            this.setAdmin(result.data.is_admin);
        }
    };

    fetchRegistration = async (dataRegistration: DataRegistration) => {
        const content = getLanguageContent(this.configStore.lang);
        this.loading = true;

        const transformedDataRegistration = transformDataRegistration(dataRegistration);

        const result = await this.authService.registrationUser(transformedDataRegistration);

        if (result.status === 200) {
            this.notificationStore.setSuccessMessage(
                content.auth.notifications.registrationTitle,
                content.auth.notifications.registrationSuccess(
                    transformedDataRegistration.username,
                ),
            );
        } else {
             this.errorStore.apiError(result);
             const errMessage = this.errorStore.error && this.errorStore.error[0] ? this.errorStore.error[0].msg : result.statusText;

             this.notificationStore.setErrorMessage(content.auth.notifications.registrationError(errMessage));
        }

        runInAction(() => {
            this.loading = false;
        });

        return result;
    };

    setUsername(value: number) {
        this.username = String(value);
    }

    setAdmin(value: boolean) {
        this.isAdmin = value;
    }

    fetchResetPassword = async (email: string) => {
        return await this.authService.resetPassword(email);
    };

    fetchChangePassword = async (uuid: string, password: string) => {
        return await this.authService.changePassword(uuid, password);
    };
}

export default AuthStore;
