import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cs from '../../utils/composeStyles';
import styles from './Reports.module.scss';
import {
    Breadcrumb,
    Button,
    DatePicker,
    Select,
    Form,
    PageHeader,
    Table,
    Dropdown,
    Menu,
} from 'antd';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

import 'moment/locale/ru';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';

import Paper from './Paper';

import { ExportOutlined } from '@ant-design/icons';

const Report = (props: any) => {
    const { reportsStore, configStore } = useStores();
    const { lang } = configStore;
    const content = getLanguageContent(lang);

    const menu = (
        <Menu>
            <Menu.Item onClick={() => reportsStore.fetchListFile(true) as any}>
                {content.references.buttons.xls}
            </Menu.Item>
            <Menu.Item onClick={() => reportsStore.fetchListFile(false) as any}>
                {content.references.buttons.pdf}
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        reportsStore.fetchTotals().catch(err => console.error(err.message));
        reportsStore.fetchList('period').catch(err => console.error(err.message));
    }, []);

    const handleChange = (type: string) => {
        reportsStore.selectType(type);
        reportsStore.fetchList(type).catch(err => console.error(err.message));
    };

    const handleBeginDateChange = (date: any) => {
        reportsStore.setBeginDate(date);
        reportsStore.fetchList(reportsStore.type).catch(err => console.error(err.message));
    };

    const handleEndDateChange = (date: any) => {
        reportsStore.setEndDate(date);
        reportsStore.fetchList(reportsStore.type).catch(err => console.error(err.message));
    };

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.reports.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <div className={styles.paperWrapper}>
                <Paper
                    title={content.reports.papers.created}
                    number={
                        reportsStore.totals && reportsStore.totals[0]
                            ? reportsStore.totals[0].count
                            : '-'
                    }
                />
                <Paper
                    title={content.reports.papers.operator}
                    number={
                        reportsStore.totals && reportsStore.totals[1]
                            ? reportsStore.totals[1].count
                            : '-'
                    }
                />
                <Paper
                    title={content.reports.papers.correction}
                    number={
                        reportsStore.totals && reportsStore.totals[2]
                            ? reportsStore.totals[2].count
                            : '-'
                    }
                />
                <Paper
                    title={content.reports.papers.rkn}
                    number={
                        reportsStore.totals && reportsStore.totals[3]
                            ? reportsStore.totals[3].count
                            : '-'
                    }
                />
                <Paper
                    title={content.reports.papers.cancelled}
                    number={
                        reportsStore.totals && reportsStore.totals[4]
                            ? reportsStore.totals[4].count
                            : '-'
                    }
                />
                <Paper
                    title={content.reports.papers.accepted}
                    number={
                        reportsStore.totals && reportsStore.totals[5]
                            ? reportsStore.totals[5].count
                            : '-'
                    }
                    good
                />
                <Paper
                    title={content.reports.papers.late}
                    number={
                        reportsStore.totals && reportsStore.totals[8]
                            ? reportsStore.totals[8].count
                            : '-'
                    }
                    poor
                />
            </div>

            <div className={styles.tableWrapper}>
                <Form layout="inline" className="components-table-demo-control-bar">
                    <Select
                        defaultValue={'period'}
                        className={styles.antSelect}
                        onChange={handleChange}
                    >
                        <Select.Option value="period">{content.reports.types.period}</Select.Option>
                        <Select.Option value="org">
                            {content.reports.types.organisation}
                        </Select.Option>
                        <Select.Option value="object">{content.reports.types.object}</Select.Option>
                    </Select>

                    <div className={styles.space} />

                    <Form.Item style={{ margin: 0 }}>
                        <div className={styles.datePicker}>
                            <DatePicker
                                value={reportsStore.beginDate}
                                placeholder={content.appManagement.inputs.startDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleBeginDateChange}
                            />
                            <DatePicker
                                value={reportsStore.endDate}
                                placeholder={content.appManagement.inputs.endDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleEndDateChange}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item style={{ display: 'flex', alignItems: 'center', marginRight: 25 }}>
                        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                            <Button className={cs(styles.exportBtn, styles.button)}>
                                {
                                    <span>
                                        <ExportOutlined className={styles.icon} />
                                        {content.appManagement.buttons.export}
                                    </span>
                                }
                            </Button>
                        </Dropdown>
                    </Form.Item>
                </Form>

                <Table
                    columns={lang === 'ru' ? reportsStore.ruColumns : reportsStore.enColumns}
                    dataSource={lang === 'ru' ? reportsStore.ruList : reportsStore.enList}
                    pagination={{ showSizeChanger: false, pageSize: 8 }}
                    size={'small'}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
};

export default observer(Report);
