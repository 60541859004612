import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './Monitoring.module.scss';
import { Breadcrumb, Form, PageHeader, Select, Table, Pagination, DatePicker, Modal } from 'antd';
import JSONPretty from 'react-json-pretty';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

import columns from './columns';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';

const Monitoring = (props: any) => {
    const { monitoringStore, configStore } = useStores();
    const { lang } = configStore;
    const content = getLanguageContent(lang);

    useEffect(() => {
        monitoringStore.fetchList().catch(err => console.error(err.message));
    }, []);

    const handleChange = (level: string) => {
        monitoringStore.setLevel(level);
        monitoringStore.fetchList().catch(err => console.error(err.message));
    };

    const handlePageChange = (page: number) => {
        monitoringStore.setPage(page);
        monitoringStore.fetchList().catch(err => console.error(err.message));
    };

    const handleBeginDateChange = (date: any) => {
        monitoringStore.setBeginDate(date);
        monitoringStore.fetchList().catch(err => console.error(err.message));
    };

    const handleEndDateChange = (date: any) => {
        monitoringStore.setEndDate(date);
        monitoringStore.fetchList().catch(err => console.error(err.message));
    };

    return (
        <div className={styles.content}>
            <PageHeader
                className={cs(styles.header)}
                title={
                    <Breadcrumb className={cs(styles.title)}>
                        <Breadcrumb.Item>{content.monitoring.title}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <div className={styles.tableWrapper}>
                <Form layout="inline" className="components-table-demo-control-bar">
                    <Select
                        className={styles.antSelect}
                        placeholder={content.monitoring.level}
                        onChange={handleChange}
                    >
                        <Select.Option value="">ALL</Select.Option>
                        <Select.Option value="DEBUG">DEBUG</Select.Option>
                        <Select.Option value="INFO">INFO</Select.Option>
                        <Select.Option value="ERROR">ERROR</Select.Option>
                        <Select.Option value="CRITICAL">CRITICAL</Select.Option>
                        <Select.Option value="FATAL">FATAL</Select.Option>
                    </Select>

                    <div className={styles.space} />

                    <Form.Item style={{ margin: 0 }}>
                        <div className={styles.datePicker}>
                            <DatePicker
                                value={monitoringStore.beginDate}
                                placeholder={content.appManagement.inputs.startDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleBeginDateChange}
                            />
                            <DatePicker
                                value={monitoringStore.endDate}
                                placeholder={content.appManagement.inputs.endDate}
                                locale={lang === 'ru' ? ru : en}
                                onChange={handleEndDateChange}
                            />
                        </div>
                    </Form.Item>
                </Form>

                <Table
                    columns={columns(configStore.lang, content.monitoring.table.header)}
                    dataSource={monitoringStore.list}
                    pagination={{ position: [], pageSize: monitoringStore.pageSize }}
                    size={'small'}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event: any) => {
                                monitoringStore.setMess(
                                    rowIndex || rowIndex === 0 ? rowIndex : false,
                                );
                            },
                        };
                    }}
                    showSorterTooltip={false}
                />

                <Pagination
                    defaultCurrent={1}
                    current={monitoringStore.page}
                    total={monitoringStore.totalPages * 10}
                    className={styles.pagination}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                />
            </div>

            <Modal
                visible={!!monitoringStore.messId || monitoringStore.messId === 0}
                title={
                    !!monitoringStore.messId || monitoringStore.messId === 0
                        ? `ID ${monitoringStore.list[monitoringStore.messId as number].id}`
                        : ''
                }
                onCancel={() => monitoringStore.setMess(false)}
                footer={null}
                width={''}
                style={{ minWidth: 540 }}
            >
                <JSONPretty
                    data={
                        !!monitoringStore.messId || monitoringStore.messId === 0
                            ? monitoringStore.list[monitoringStore.messId as number].mess
                            : {}
                    }
                />
            </Modal>
        </div>
    );
};

export default observer(Monitoring);
