import { FormFieldsInterface } from '../../components/FormComponent/FormComponent';
import { ContentType, Languages } from '../../content';
import React from 'react';

export const getNotificationsFields = (
    content: ContentType,
    lang: Languages,
    changed: boolean,
    onSubmitForm: any,
): Array<FormFieldsInterface> => {
    const fields = {
        Title: {
            key: 'Title',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.notifications.fields.templates,
                span: 16,
            },
        },
        InformationInputEn: {
            key: 'descr_en',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.notifications.placeholders.information_en,
                required: true,
                span: 24,
            },
        },
        InformationInputRu: {
            key: 'descr_ru',
            classNameField: 'spectrumField',
            meta: {
                type: 'textarea',
                placeholder: content.notifications.placeholders.information_ru,
                required: true,
                span: 24,
            },
        },
        SLA: {
            key: 'SLA',
            classNameField: 'spectrumField',
            meta: {
                type: 'label',
                placeholder: content.notifications.fields.sla,
                span: 24,
            },
        },
        ApprovalDays: {
            key: 'days_1',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.notifications.placeholders.approvalDays,
                required: false,
                span: 8,
                suffix: <span>дн.</span>,
            },
        },
        ApprovalNotification: {
            key: 'days_2',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.notifications.placeholders.approvalNotification,
                required: false,
                span: 8,
                suffix: <span>дн.</span>,
            },
        },
        NotificationInterval: {
            key: 'days_3',
            classNameField: 'spectrumField',
            meta: {
                type: 'number',
                placeholder: content.notifications.placeholders.notificationInterval,
                required: false,
                span: 8,
                suffix: <span>дн.</span>,
            },
        },
        SaveButton: {
            key: 'saveButton',
            classNameField: 'spectrumField',
            meta: {
                type: 'button',
                placeholder: content.support.fields.save,
                span: 4,
                onClick: onSubmitForm,
                disabled: !changed,
            },
        },
        Space16: {
            key: 'space',
            meta: {
                type: 'space',
                span: 16,
            },
        },
        Space8: {
            key: 'space',
            meta: {
                type: 'space',
                span: 8,
            },
        },
        Space4: {
            key: 'space',
            meta: {
                type: 'space',
                span: 4,
            },
        },
    };

    return [
        fields.Title,
        fields.InformationInputRu,
        fields.InformationInputEn,
        fields.SLA,
        fields.ApprovalDays,
        fields.ApprovalNotification,
        fields.NotificationInterval,
        fields.Space16,
        fields.Space4,
        fields.SaveButton,
    ];
};
