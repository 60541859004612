import React, { FC, useEffect, useRef, useState } from 'react';

import styles from './AppSubmenuItems.module.scss';
import cs from '../../utils/composeStyles';
import { useStores } from '../../store';

import AppSubmenuItem from '../AppSubmenuItem/AppSubmenuItem';

type AppSubmenuItemsProps = {
    mainTitle: string | undefined;
    items: submenuItemsTypes | undefined;
    isCollapsed: boolean;
    arrayOfCallbacks: Array<any>;
};

const AppSubmenuItems: FC<AppSubmenuItemsProps> = ({
    mainTitle,
    items,
    isCollapsed,
    arrayOfCallbacks,
}) => {
    const { mainPageOverlayStore, sidebarCollapsibleStore } = useStores();
    const isOverlay = mainPageOverlayStore.GET_MAIN_PAGE_OVERLAY;
    const isSidebarCollapsed = sidebarCollapsibleStore.GET_SIDEBAR_IS_COLLAPSED;

    const [subMenuHeight, setSubMenuHeight] = useState<null | number>(null);
    const subMenuContainerRef = useRef<HTMLDivElement>(null);

    const [submenuItems, setSubmenuItems] = useState(
        items?.map(() => ({ id: Math.random(), isSelected: false })),
    );

    const isCollapsedStyle = () => {
        if (!isCollapsed && subMenuHeight) {
            return { maxHeight: `${subMenuHeight}px` };
        }

        if (isCollapsed && subMenuHeight) {
            return { maxHeight: `0px` };
        }

        return {};
    };

    const changeSelectedTitles = (indexArr: number) => {
        setSubmenuItems(prev =>
            prev?.map((item, index) => {
                if (index === indexArr) {
                    return { ...item, isSelected: true };
                }

                return { ...item, isSelected: false };
            }),
        );
    };

    const onClickCallback = (index: number) => {
        changeSelectedTitles(index);
        arrayOfCallbacks[index]();
    };

    useEffect(() => {
        if (subMenuContainerRef.current) {
            setSubMenuHeight(subMenuContainerRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setSubmenuItems(prev => prev?.map(item => ({ ...item, isSelected: false })));
        }
    }, [isCollapsed]);

    return (
        <div
            ref={subMenuContainerRef}
            className={cs(styles.subMenuContainer, `subMenuContainer`)}
            style={
                Object.assign(isCollapsedStyle(), {
                    visibility: !isOverlay && isSidebarCollapsed ? 'hidden' : 'visible',
                }) as any
            }
        >
            <span className={styles.mainTitle}>{mainTitle}</span>

            {items &&
                items.map((item, index) =>
                    index === 2 && sessionStorage.getItem('user_id') !== '2045' ? null : (
                        <AppSubmenuItem
                            isSelected={submenuItems && submenuItems[index].isSelected}
                            key={`${item.id} ${isCollapsed}`}
                            onClick={() => onClickCallback(index)}
                            item={item}
                        />
                    ),
                )}
        </div>
    );
};

export default AppSubmenuItems;
