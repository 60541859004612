import { Col, Form, Modal, Row, Tag } from 'antd';

import React from 'react';
import { observer } from 'mobx-react';
import styles from './Support.module.scss';
import { AttachIcon } from '../../components/Icons/Icons';
import { Input } from '../../components/Inputs/Inputs';
import { useStores } from '../../store';
import getLanguageContent from '../../content';

const SupportFile = (props: any) => {
    const { file, setFiles } = props;

    const { configStore, supportStore } = useStores();
    const content = getLanguageContent(configStore.lang);

    const [form] = Form.useForm();

    const handleSubmit = (lang: string, field: string) => {
        let data = {} as any;

        form.validateFields().then((values: any) => {
            if (file[field] !== values[field]) {
                data[lang] = values[field];
                supportStore
                    .fetchUpdateSupportDocument(file.id, data)
                    .catch(err => console.error(err.message));
            }
        });
    };

    return (
        <Form className={styles.fileForm} form={form} initialValues={file}>
            <Row gutter={[20, 8]} className={styles.files}>
                <Col className="form__item" span={8}>
                    <Tag
                        id={file.id}
                        icon={<AttachIcon />}
                        closable
                        className={styles.file}
                        onClick={() => supportStore.fetchDocument(file.id, file.name)}
                        onClose={() => {
                            Modal.confirm({
                                title: `${content.support.files.delete} ${file.name}?`,
                                okText: content.support.modal.buttons.yes,
                                cancelText: content.support.modal.buttons.no,
                                onOk() {
                                    supportStore.fetchDeleteDocument(file.id).then(() =>
                                        supportStore
                                            .fetchFiles()
                                            .then((result: any) => {
                                                setFiles(result.data);
                                            })
                                            .catch(err => console.error(err.message)),
                                    );
                                },
                            });
                        }}
                    >
                        {file.name}
                    </Tag>
                </Col>
                <Col className="form__item" span={8}>
                    <Form.Item
                        name={'descr_ru'}
                        rules={[{ required: true, message: content.auth.validate.required }]}
                    >
                        <Input
                            placeholder={content.support.placeholders.description_ru}
                            className={styles.fullWidthInput}
                            onBlur={() => handleSubmit('ru', 'descr_ru')}
                        />
                    </Form.Item>
                </Col>
                <Col className="form__item" span={8}>
                    <Form.Item
                        name={'descr_en'}
                        rules={[{ required: true, message: content.auth.validate.required }]}
                    >
                        <Input
                            placeholder={content.support.placeholders.description_en}
                            className={styles.fullWidthInput}
                            onBlur={() => handleSubmit('en', 'descr_en')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default observer(SupportFile);
