export default {
    titles: {
        new: 'New Application',
        edit: 'Application',
        copy: 'Copy Application',
    },
    buttons: {
        save: 'Save',
        delete: 'Delete',
        open: 'Open',
        send: 'Send to RKN',
        export: 'Export to',
        add: 'Add equipment type',
    },
    selects: {
        event: 'Event*',
        period: 'License Period*',
        organization: 'Organisation*',
        contact: 'Contact Name',
    },
    inputs: {
        description: 'Description',
        reason: "Approver's Comment",
    },
    table: {
        header: {
            service: 'Equipment Туре',
            object: 'Location',
            model: 'Equipment Model Name',
            class: 'Class of emission',
            recFrequency: 'Equipment Tuning Range Rx (MHz)',
            transFrequency: 'Equipment Tuning Range Tx (MHz)',
            actions: 'Actions',
        },
        more: 'more',
    },
    modal: {
        service: 'Equipment Туре',
        object: 'Location',
        freceive: 'Preferred Rx frequence ( MHz)',
        ftransmit: 'Preferred Tx frequence ( MHz)',
        nfreceive: 'Frequency nominal (MHz), Receive',
        nftransmit: 'Frequency nominal (MHz), Transmit',
        model: 'Equipment Model Name',
        comment: 'Additional info (comment)',
        brand: 'Equipment Manufacture Name',
        eclass: 'Class of emission',
        quant: 'Number of identical devices',
        fquant: 'Number of requested frequencies',
        frange: 'Equipment Tuning Range(mHz)',
        franger: 'Equipment Tuning Range Rx (MHz)',
        franget: 'Equipment Tuning Range Tx (MHz)',
        frangerfrom: 'From',
        frangerto: 'To',
        frangetfrom: 'From',
        frangetto: 'To',
        chsize: 'Channel size (kHz)',
        duplex: 'Duplex (MHz)',
        maxpower: 'Max transmit power (W)',
        usetype: 'Type of use',
        typetun: 'Type of channel tuning',
        standart: 'Wi-Fi Standard (for Wireless LAN)',
        chstep: 'Channel tuning step (kHz)',
        satname: 'Satellite Name',
        georbit: 'Geostationary Satellite Orbital Position (W/E, degrees)',
        lat: 'Location: N (deg, min, sec) coordinate',
        long: 'Location: E (deg, min, sec) coordinate',
        outpower: 'Total Output Power (one carrier), dBw',
        antd: 'Diameter (size) of antenna (m)',
        antgaint: 'Antenna Gain (dB), Transmit',
        antgainr: 'Antenna Gain (dB), Receive',
        antheight: 'Antenna installation height above ground (m)',
        outpower_a: 'Output Power Point A (W)',
        outpower_b: 'Output Power Point B (W)',
        again_a: 'Antenna Gain (dB) (point A)',
        again_b: 'Antenna Gain (dB) (point B)',
        ssid: 'SSID of access point',
        macaddress: 'MAC address of access point',
        from: 'from',
        to: 'to',
        mHz: 'mHz',
    },
    validate: {
        required: 'Required field',
        frequence: 'Incorrect frequence',
        eclass: 'Incorrect class of emission',
        quantity: 'Incorrect class of quantity',
        coordinate: 'Incorrect coordinate',
        chstep: 'Incorrect channel tuning step',
        float: 'Incorrect value',
    },
};
