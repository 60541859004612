import { makeAutoObservable } from 'mobx';

type searchType = {
    users: string;
    applications: string;
    setCurrentSearch: (text: string) => void;
    getCurrentSearch: (page: string) => string;
};

const search: searchType = {
    users: '',
    applications: '',

    setCurrentSearch(text) {
        switch (localStorage.getItem('app_menu')) {
            case 'ApplicationManagement':
                this.applications = text;
                break;

            case 'Users':
                this.users = text;
                break;

            default:
                return '';
        }
    },

    getCurrentSearch(page) {
        switch (page) {
            case 'ApplicationManagement':
                return this.applications;

            case 'Users':
                return this.users;

            default:
                return '';
        }
    },
};

const searchStore = makeAutoObservable(search);

export default searchStore;
