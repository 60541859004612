import React from 'react';
import { Button, Input, Form } from 'antd';
import styles from './References.module.scss';

export default (
    lang: string,
    content: any,
    sortedInfo?: any,
    current?: number,
    onSaveHandler?: any,
    onCloseHandler?: any,
    onLockHandler?: any,
) => {
    const { header } = content;

    sortedInfo = sortedInfo || {};

    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id,
            sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
            width: '5%',
            align: 'left' as any,
            render: (id: number) => (id ? <span>{id}</span> : ''),
        },
        {
            title: header.enName,
            dataIndex: ['name', 'en'],
            key: 'enName',
            align: 'left' as any,
            sorter: (a: any, b: any) => a.name.en.localeCompare(b.name.en),
            sortOrder: sortedInfo.columnKey === 'enName' && sortedInfo.order,
            render: (item: string, row: any) =>
                row.id === current ? (
                    <Form.Item name={['name', 'en']} className={styles.tableFormItem}>
                        <Input defaultValue={item} placeholder={'en'} disabled={row.is_hidden} />
                    </Form.Item>
                ) : (
                    <span>{item}</span>
                ),
        },
        {
            title: header.ruName,
            dataIndex: ['name', 'ru'],
            key: 'ruName',
            align: 'left' as any,
            sorter: (a: any, b: any) => a.name.ru.localeCompare(b.name.ru),
            sortOrder: sortedInfo.columnKey === 'ruName' && sortedInfo.order,
            render: (item: string, row: any) =>
                row.id === current ? (
                    <Form.Item name={['name', 'ru']} className={styles.tableFormItem}>
                        <Input defaultValue={item} placeholder={'ru'} disabled={row.is_hidden} />
                    </Form.Item>
                ) : (
                    <span>{item}</span>
                ),
        },
        {
            title: header.enDescr,
            dataIndex: ['descr', 'en'],
            key: 'enDescr',
            align: 'left' as any,
            render: (item: string, row: any) =>
                row.id === current ? (
                    <Form.Item name={['descr', 'en']} className={styles.tableFormItem}>
                        <Input defaultValue={item} placeholder={'en'} disabled={row.is_hidden} />
                    </Form.Item>
                ) : (
                    <span>{item}</span>
                ),
        },
        {
            title: header.ruDescr,
            dataIndex: ['descr', 'ru'],
            key: 'ruDescr',
            align: 'left' as any,
            render: (item: string, row: any) =>
                row.id === current ? (
                    <Form.Item name={['descr', 'ru']} className={styles.tableFormItem}>
                        <Input defaultValue={item} placeholder={'ru'} disabled={row.is_hidden} />
                    </Form.Item>
                ) : (
                    <span>{item}</span>
                ),
        },
        {
            key: 'button',
            width: '5%',
            align: 'center' as any,
            render: (item: string, row: any) =>
                onLockHandler && !row.dtime && row.id ? (
                    <Button
                        disabled={row.key !== current}
                        className={'btn-blue'}
                        onClick={() => onLockHandler(row.id)}
                    >
                        {!row.is_hidden ? content.buttons.lock : content.buttons.unlock}
                    </Button>
                ) : null,
        },
        {
            key: 'button',
            width: '5%',
            render: (item: string, row: any) =>
                onCloseHandler && !row.dtime && row.id ? (
                    <Button
                        disabled={row.key !== current}
                        className={'btn-red'}
                        onClick={() => onCloseHandler(row.id)}
                    >
                        {content.buttons.archive}
                    </Button>
                ) : null,
        },
    ];
};
