import { FormFieldsInterface } from '../../../../components/FormComponent/FormComponent';
import { ContentType } from '../../../../content';

export const getEmailField = (content: ContentType, rules: any): Array<FormFieldsInterface> => {
    return [
        {
            key: 'email',
            classNameField: 'spectrumField',
            meta: {
                type: 'input',
                placeholder: content.auth.registrationFields.email,
                validation: (rule, value, callback) => {
                    if (!rules[`email`].test(value)) {
                        callback(content.auth.validate.invalidEmail);
                    } else {
                        callback();
                    }
                },
                required: true,
            },
        },
    ];
};

export const getPasswordFields = (content: ContentType, rules: any): Array<FormFieldsInterface> => {
    return [
        {
            key: 'password',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.registrationFields.password,
                validation: (rule, value, callback) => {
                    if (!rules[`password`].test(value)) {
                        callback(content.auth.validate.invalidPassword);
                    } else {
                        callback();
                    }
                },
                required: true,
            },
        },
        {
            key: 'passwordConfirm',
            classNameField: 'spectrumField',
            meta: {
                type: 'password',
                placeholder: content.auth.registrationFields.passwordConfirm,
                required: true,
                validation: (rule, value, callback, values) => {
                    if (values.password !== value) {
                        callback(content.auth.validate.mismatchPassword);
                    } else {
                        callback();
                    }
                },
            },
        },
    ];
};
