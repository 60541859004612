import axios, { AxiosResponse } from 'axios';
import ConfigurationStore from '../store/configurationStore';
import urljoin from 'url-join';
import { DictionaryItem, DictionaryListItem } from './models/dictionaryItem';

interface DictionaryServiceInterface {
    getDictionariesCode: () => Promise<AxiosResponse<DictionaryListItem>>;
    getDictionary: (code: number) => Promise<AxiosResponse<DictionaryItem[]>>;
}

export class DictionaryService implements DictionaryServiceInterface {
    apiUrl = '';
    dictionariesUrl = '';

    constructor(configStore: ConfigurationStore) {
        this.apiUrl = configStore.appConfig.apiUrl;
        this.dictionariesUrl = configStore.appConfig.dictionariesUrl;
    }

    getDictionariesCode = async (): Promise<AxiosResponse<DictionaryListItem>> => {
        const response = await axios.get(urljoin(this.apiUrl, 'cls/clsr_id/'));
        return response;
    };

    getDictionary = async (code: number): Promise<AxiosResponse<DictionaryItem[]>> => {
        const response = await axios.get(urljoin(this.dictionariesUrl, `${code}/`));
        return response;
    };
}

export default DictionaryServiceInterface;
