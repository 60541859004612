export default {
    title: 'Application management',
    status: 'Status',
    buttons: {
        create: 'New',
        copy: 'Copy',
        edit: 'Open',
        delete: 'Delete',
        cancel: 'Revoke',
        actions: 'Actions',
        export: 'Export to',
        selectAll: 'Select All',
    },
    export: {
        xls: 'excel',
        csv: 'csv',
        list: 'list',
        full: 'full',
    },
    inputs: {
        filter: 'View',
        startDate: 'Start date',
        endDate: 'End date',
    },
    table: {
        header: {
            request: 'Request №',
            customer: 'Organization',
            period: 'Period',
            status: 'Status',
            licence: 'Licence',
            owner: 'Owner',
            created: 'Created',
            description: 'Description',
        },
    },
    attach: {
        title: 'Loaded files',
        buttons: {
            save: 'Save',
            cancel: 'Cancel',
            addFile: 'Add licence file',
        },
    },
    modal: {
        delete: 'Do you really want to delete application?',
        revoke: 'Do you really want to revoke application?',
        document: 'Do you really want to delete document?',
        service: 'Do you really want to delete service?',
        status: 'Do you really want to change status of application?',
        buttons: {
            yes: 'Yes',
            no: 'No',
        },
    },
};
