import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AuthLinks.module.scss';
import getLanguageContent from '../../../../content';
import { useStores } from '../../../../store';
import { observer } from 'mobx-react';

type AuthLinksPropTypes = {};

const AuthLinks: FC<AuthLinksPropTypes> = () => {
    const { configStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    return (
        <div className={styles.container}>
            <NavLink
                activeClassName={styles.navLink__active}
                className={styles.navLink}
                to="/login"
            >
                {content.auth.login}
            </NavLink>
            <NavLink
                activeClassName={styles.navLink__active}
                className={styles.navLink}
                to="/registration"
            >
                {content.auth.registration}
            </NavLink>
        </div>
    );
};

export default observer(AuthLinks);
