import { authContentRU } from './auth/auth-content-ru';
import { authContentEN } from './auth/auth-content-en';
import header_content_ru from './header/header-content-ru';
import header_content_en from './header/header-content-en';
import sidebar_content_ru from './sideBar/sidebar-content-ru';
import sidebar_content_en from './sideBar/sidebar-content-en';
import app_management_content_ru from './appManagement/app-management-content-ru';
import app_management_content_en from './appManagement/app-management-content-en';
import new_request_content_ru from './appManagement/newRequest/new-request-content-ru';
import new_request_content_en from './appManagement/newRequest/new-request-content-en';
import app_management_error_content_ru from './appManagement/error/error-content-ru';
import app_management_error_content_en from './appManagement/error/error-content-en';
import users_content_ru from './users/users-content-ru';
import users_content_en from './users/users-content-en';
import support_content_ru from './support/support-content-ru';
import support_content_en from './support/support-content-en';
import reports_content_ru from './reports/reports-content-ru';
import reports_content_en from './reports/reports-content-en';
import monitoring_content_ru from './monitoring/monitoring-content-ru';
import monitoring_content_en from './monitoring/monitoring-content-en';
import journal_content_ru from './journal/journal-content-ru';
import journal_content_en from './journal/journal-content-en';
import notifications_content_ru from './notifications/notifications-content-ru';
import notifications_content_en from './notifications/notifications-content-en';
import references_content_ru from './references/references-content-ru';
import references_content_en from './references/references-content-en';
import table_content_ru from '../components/Table/table-content-ru';
import table_content_en from '../components/Table/table-content-en';

export const contentRU = {
    auth: authContentRU,
    header: header_content_ru,
    sideBar: sidebar_content_ru,
    appManagement: app_management_content_ru,
    newRequest: new_request_content_ru,
    appManagementError: app_management_error_content_ru,
    users: users_content_ru,
    support: support_content_ru,
    reports: reports_content_ru,
    monitoring: monitoring_content_ru,
    journal: journal_content_ru,
    notifications: notifications_content_ru,
    references: references_content_ru,
    table: table_content_ru,
};

export const contentEN = {
    auth: authContentEN,
    header: header_content_en,
    sideBar: sidebar_content_en,
    appManagement: app_management_content_en,
    newRequest: new_request_content_en,
    appManagementError: app_management_error_content_en,
    users: users_content_en,
    support: support_content_en,
    reports: reports_content_en,
    monitoring: monitoring_content_en,
    journal: journal_content_en,
    notifications: notifications_content_en,
    references: references_content_en,
    table: table_content_en,
};

export enum Languages {
    RU = 'ru',
    EN = 'en',
}

export type ContentType = typeof contentRU | typeof contentEN;

const getLanguageContent = (language: Languages) => {
    if (language === Languages.RU) return contentRU;
    return contentEN;
};

export default getLanguageContent;
