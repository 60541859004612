import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplateNoReload,
    validateCaptcha, // @ts-ignore
} from 'react-simple-captcha';
import { Form, Input } from 'antd';
import { useStores } from '../../../../store';
import getLanguageContent from '../../../../content';

const Captcha = (props: any) => {
    const { form } = props;
    const { configStore } = useStores();
    const content = getLanguageContent(configStore.lang);
    const [failed, setFailed] = useState(true);
    const [padding, setPadding] = useState(false);

    useEffect(() => {
        failed && loadCaptchaEnginge(6);
    }, []);

    return (
        <Form form={form} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ margin: 'auto', paddingBottom: padding ? 24 : 0 }}>
                <LoadCanvasTemplateNoReload />
            </div>
            <Form.Item
                name={'captcha'}
                className="form__item"
                key={'captcha'}
                style={{ width: '50%' }}
                validateTrigger={'onFormFinish'}
                rules={[
                    { required: true, message: content.auth.validate.required },
                    {
                        validator: (_, value, callback) => {
                            if (validateCaptcha(value) === true) {
                                callback();
                            } else {
                                setFailed(true);
                                setPadding(true);
                                callback(content.auth.validate.invalidCaptcha);
                            }
                        },
                    },
                ]}
            >
                <Input
                    placeholder={content.auth.registrationFields.captcha}
                    className={'spectrumField'}
                    onChange={() => {
                        setPadding(false);
                        form.setFields([{ name: 'captcha', validating: true, errors: [] }]);
                    }}
                />
            </Form.Item>
        </Form>
    );
};

export default observer(Captcha);
